import {
  Accordion, AccordionDetails, AccordionSummary, Grid, styled,
} from '@mui/material';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';
import { Fragment, useContext, useEffect } from 'react';
import { DateTime } from 'luxon';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { Column } from '..';
import { PreviousResponsesInterface } from '../../types';
import ProcedureContext from '../../context/ProcedureContext';
import { parseAnswerCommentJson } from '../../util/answerComments';
import { NormalQuestionType } from '../../enums';
import SmallLoadingSpinner from '../SmallLoadingSpinner';

const StyledAccordion = styled(Accordion)`
  background-color: ${(props) => props.theme.palette.grey[200]};

  &:before {
    background-color: transparent !important;
  }

  ul {
    margin: 0;
    padding-left: 23px;
  }

  li {
    padding-bottom: 5px;

    &::marker {
      color: ${(props) => props.theme.palette.grey[700]};
      font-size: 0.75rem;
    }
  }

  .MuiAccordionSummary-root {
    padding: 0;
    user-select: text;

    &.NotExpandable:hover {
      cursor: text;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0 0 12px 0;

    .VisitGridItem:first-of-type {
      padding-top: 4px;
    }
  }

  .SummaryTitle {
    font-size: 0.875rem;
    color: ${(props) => props.theme.palette.grey[900]};
    font-weight: 400;
  }

  .VisitText {
    color: ${(props) => props.theme.palette.grey[600]};
    font-size: 0.75rem;
    font-weight: 400;
  }

  .AnswerCommentText {
    font-size: 0.75rem;
    font-family: ${(props) => `"Poppins-Light", "Poppins Light", ${props.theme.typography.fontFamily}`};
    color: ${(props) => props.theme.palette.grey[600]};
  }

  .PreviousResponseText {
    font-size: 0.75rem;
    font-family: ${(props) => `"Poppins-Light", "Poppins Light", ${props.theme.typography.fontFamily}`};
    color: ${(props) => props.theme.palette.grey[900]};
  }

  &:not(:last-of-type) {
    border-bottom: 1px dotted ${(props) => props.theme.palette.grey[600]};
    background-color: ${(props) => props.theme.palette.grey[200]};
  }

  .Mui-expanded.Expandable {
    .SummaryTitle {
      font-weight: 500;
    }
    .ExpandIcon {
      transition: 300ms;
      rotate: 180deg;
    }
  }

  .ExpandIcon {
    transition: 300ms;
    color: ${(props) => props.theme.palette.grey[700]};
  }
`;

const StyledDiv = styled('div')`
  font-family: ${(props) => `"Poppins Light Italic", ${props.theme.typography.fontFamily}`};
  font-size: 14px;
  font-style: italic;
  color: ${(props) => props.theme.palette.grey[700]};
  align-self: center;
  margin-top: 40px;
`;

function PreviousResponseComponent({ questionText, responses, type }: PreviousResponsesInterface) {
  const getAnswerListItem = (key: string, answer: string, answerComment?: string) => (
    <li key={key}>
      <Typography className="PreviousResponseText" variant="caption" component="div">{answer}</Typography>
      {answerComment && <Typography className="AnswerCommentText" variant="caption" component="div">{answerComment}</Typography>}
    </li>
  );

  const prevResponseComponentList = responses.map(({
    visit, visitCompletedDate, visitId, answer, answerId, answerComment,
  }) => (
    <Fragment key={visitId}>
      <Grid item xs={12} className="VisitGridItem">
        <Typography variant="caption" className="VisitText" component="div">
          {visit}
        </Typography>
        <Typography variant="caption" className="VisitText" component="div">
          {DateTime.fromMillis(visitCompletedDate).toFormat('dd-MMM-yyyy HH:mm').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ul>
          {type === NormalQuestionType.MULTI_SELECT
            ? answer.split('\n').map((ans, idx) => getAnswerListItem(`${answerId}:${idx}`, ans, parseAnswerCommentJson(answerComment, ans)))
            : getAnswerListItem(answerId, answer, parseAnswerCommentJson(answerComment, answer))}
        </ul>
      </Grid>
    </Fragment>
  )).reverse();

  const expandable = responses.length > 1;

  return (
    <StyledAccordion
      elevation={0}
      disableGutters
      className="PreviousResponses"
    >
      <AccordionSummary className={`${expandable ? 'Expandable' : 'NotExpandable'}`}>
        <Grid container item spacing={1}>
          <Grid item xs={expandable ? 10 : 12}>
            <Typography className="SummaryTitle" align="left">
              {questionText}
            </Typography>
          </Grid>
          {expandable
            && (
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FontAwesomeIcon className="ExpandIcon" icon={faCaretDown as IconProp} />
            </Grid>
            )}
          {prevResponseComponentList[0]}
        </Grid>
      </AccordionSummary>
      {expandable
        && (
        <AccordionDetails>
          <Grid container item spacing={1}>
            {prevResponseComponentList.slice(1)}
          </Grid>
        </AccordionDetails>
        )}
    </StyledAccordion>
  );
}

export default function PreviousResponses() {
  const { prevResponses, retrievePreviousResponses } = useContext(ProcedureContext);
  const { t } = useTranslation();
  useEffect(() => retrievePreviousResponses(), []);

  const getPreviousResponseComponent = () => {
    if (!prevResponses) {
      return <SmallLoadingSpinner />;
    }
    if (prevResponses.length > 0) {
      return prevResponses.map((prevResponse) => <PreviousResponseComponent key={`${prevResponse.questionId}`} {...prevResponse} />);
    }
    return <StyledDiv>{t('Procedure.Previous Responses.No previous responses')}</StyledDiv>;
  };

  return (
    <Column>
      {getPreviousResponseComponent()}
    </Column>
  );
}
