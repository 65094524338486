enum ProcedureStatus {
  PLACEHOLDER = 'PLACEHOLDER',
  COMPLETED = 'COMPLETED',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  SKIPPED = 'SKIPPED',
  NOT_STARTED = 'NOT_STARTED',
  ICF_SKIPPED = 'ICF_SKIPPED',
  PERMANENT_SKIPPED = 'PERMANENT_SKIPPED',
}

export default ProcedureStatus;
