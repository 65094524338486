import styled from 'styled-components';

const BlurOverlayComponent = styled.div`
  position: fixed;
  // MUI Modals have z-index of 1300, so this needs to be really high
  z-index: 3000;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

export default function BlurOverlay() {
  return <BlurOverlayComponent data-testid="blurOverlay" />;
}
