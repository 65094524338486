const getEnv = () => {
  const { host = '' } = window.location;

  // App API URL provided by TIP
  if (process.env.REACT_APP_API_BASE_URL !== '') {
    return process.env.REACT_APP_API_BASE_URL;
    // qa, local development, and github preview links
  } if (host.includes('.local.')) {
    return 'api.dev-esource-useast1.clinicalresearch.io';
  } if (host.includes('.qa.') || host.includes('-qa-')) {
    return 'api.np.clinicalresearch.io';
  }

  return 'api.clinicalresearch.io'; // Prod
};

/**
 * Get the current region
 * @return   {string | null} containing the current region
 */
const getRegion = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('region');
};

/**
 * Get the domain, allowing the region to be overridden but otherwise pulling it from the URL
 * @param overrideRegion  {string | null} containing the region to potentially override with
 * @return                string containing the current domain
 */
const getDomain = (overrideRegion: (string | null) = null) => {
  const region = overrideRegion || getRegion();

  if (!region) {
    throw new Error('Region is missing from url.');
  }

  let prefix = '';
  if (region.toLowerCase() !== 'us') {
    prefix = `${region}.`;
  }

  return `https://${prefix}${getEnv()}/esource/v1`;
};

export default getDomain;
export { getRegion, getDomain };
