import {
  extractVariableNamesFromCondition as extractVariableNames,
  parseSqliteStatement,
  sanitizeCondition,
} from './custom-sqlite-parser';
import { addRule, evaluateFact, initRulesEngine } from './node-rules-engine';
import { Rule } from '../../types';
import { isAnswerPopulated } from './CalculationFunctionUtil';
import { RuleType } from '../../enums';
import VariableNameAnswers from '../../context/types/VariableNameAnswers';

export interface EngineRule extends Rule {
  ruleType: RuleType
  procedureId: string;
  rulePriority?: number;
  alertHandler: Function;
  disableHandler: Function;
  enableHandler: Function;
}

export function extractVariableNamesFromCondition(condition: string) {
  const sqliteObj = parseSqliteStatement(condition);
  return extractVariableNames(sqliteObj);
}

export default function initialize(rules: Array<EngineRule>) {
  const newRulesEngine = initRulesEngine();
  rules.forEach((rule) => {
    const sanitizedCondition = sanitizeCondition(rule.condition).replaceAll('"', '\'');
    // rule priority order: alert, enable, disable
    const rulePriority = rule.disableQuestions ? 10 : 30;
    addRule(
      newRulesEngine,
      sanitizedCondition,
      rule.ruleId,
      rule.procedureId,
      rule.ruleType,
      extractVariableNamesFromCondition(rule.condition),
      rulePriority,
      rule.disableQuestions ? rule.disableHandler : rule.alertHandler,
    );
    if (rule.disableQuestions) {
      // need to add inverse rule
      addRule(
        newRulesEngine,
        `not(${sanitizedCondition})`,
        rule.ruleId,
        rule.procedureId,
        rule.ruleType,
        extractVariableNamesFromCondition(rule.condition),
        20,
        rule.enableHandler,
      );
    }
  });
  return newRulesEngine;
}

/**
 * Turns off the rules where the answer has not been provided yet
 * Fulfills similar function to Calculation's {@link verifyAllQuestionFieldsPopulated}
 * @param rulesEngine
 * @param fact
 */
export function ensureAllQuestionFieldsPopulated(rulesEngine: any, fact: VariableNameAnswers) {
  Object.keys(fact)
    .filter((key) => !isAnswerPopulated(fact[key]))
    .forEach((key) => {
      rulesEngine.turn('OFF', JSON.parse(`{"${key}": "yes"}`));
    });
}

export function turnOnRulesForQuestion(rulesEngine: any, variableName: string) {
  rulesEngine.turn('ON', JSON.parse(`{"${variableName}": "yes"}`));
}

export function turnOffAllRulesForProcedure(rulesEngine: any, procedureId: string) {
  rulesEngine.turn('OFF', JSON.parse(`{"procedureId": "${procedureId}"}`));
}

export function turnOffSelectionAlertRules(rulesEngine:any) {
  rulesEngine.turn('OFF', JSON.parse(`{"ruleType": "${RuleType.SELECTION_ALERT}"}`));
}

export function turnOffAlertRules(rulesEngine: any) {
  rulesEngine.turn('OFF', JSON.parse(`{"ruleType": "${RuleType.SELECTION_ALERT}"}`));
  rulesEngine.turn('OFF', JSON.parse(`{"ruleType": "${RuleType.ALERT}"}`));
}

export function turnOnSelectionAlertRulesForQuestion(rulesEngine: any, variableName: string) {
  // JSON filtering in node-rules-engine filters by ANY match, not ALL match
  // so we need to enable all the rules for the specified question, then turn off all ruleTypes
  // that are not SELECTION_ALERT
  rulesEngine.turn('ON', JSON.parse(`{"${variableName}": "yes"}`));
  rulesEngine.turn('OFF', JSON.parse(`{"ruleType": "${RuleType.ALERT}"}`));
  rulesEngine.turn('OFF', JSON.parse(`{"ruleType": "${RuleType.DISABLE_QUESTIONS}"}`));
}

export function isAnyRulesActive(rulesEngine: any) {
  return (rulesEngine.activeRules || []).length > 0;
}

export function evaluate(ruleEngine: any, fact: VariableNameAnswers, onComplete: Function) {
  return evaluateFact({ ruleEngine, fact, onComplete });
}
