import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export interface DeleteRecordDialogProps {
  isOpen: boolean,
  continueHandler?: Function,
  closeHandler?: Function,
}

const StyledCrioDialog = styled(CrioAlertDialog)`
  max-width: 520px;

  .message {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .optionsContainer {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .cancelButton {
    cursor: pointer;
    padding-left: 20px;
    &:hover {
      color: #C04040;
    }
  }
`;

export default function DeleteRecordDialog({
  isOpen,
  continueHandler = () => {},
  closeHandler = () => {},
}: DeleteRecordDialogProps) {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <StyledCrioDialog
      data-testid="deleteRecordDialog"
      type="Error"
      alertTitle={t('Procedure.Dialog.Delete Record.Header')}
      open={isOpen}
      onClose={() => closeHandler()}
      fullWidth={false}
      disablePortal
      sx={{ width: '50%', margin: 'auto' }}
    >
      <div className="message">
        {t('Procedure.Dialog.Delete Record.Message')}
      </div>
      <div className="optionsContainer">
        <CrioButton
          theme="Alert"
          onClick={() => {
            continueHandler();
            closeHandler();
          }}
        >
          {t('Common.Delete')}
        </CrioButton>
        <div
          role="button"
          tabIndex={0}
          data-qa-id="cancel"
          className="cancelButton"
          onClick={() => {
            closeHandler();
          }}
          onKeyDown={() => {
            closeHandler();
          }}
        >
          {t('Common.Cancel')}
        </div>
      </div>

    </StyledCrioDialog>
  );
}

DeleteRecordDialog.defaultProps = {
  continueHandler: () => {},
  closeHandler: () => {},
};
