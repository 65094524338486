function getCookie(name: string) {
  const cookie: { [index: string]: string } = {};
  document.cookie.split(';').forEach((el) => {
    const [k, v] = el.split('=');
    cookie[k.trim()] = v;
  });
  return cookie[name] || '';
}

export const getTokenFromCookie = () => getCookie('esource-jwt');

export default { getTokenFromCookie };
