import { DataPoint, PreviousResponsesInterface, QuestionInterface } from '../../types';

/**
 * Populates and returns an array of PreviousResponses where each entry corresponds
 * to a question in the question config and contains all the previous responses for said question,
 * which are sourced from the datapoints.
 */
// TODO: Handle synthetic questions based on outcome of DEV-5491
const getPreviousResponsesFromDataPoints = (
  dataPoints: Array<DataPoint>,
  questions: Array<QuestionInterface>,
): Array<PreviousResponsesInterface> => {
  // initialize responses array using questions config so we can get question text from config
  const responses: Array<PreviousResponsesInterface> = questions
    .map(({ questionText, questionId, type }) => ({
      questionText, questionId, type, responses: [],
    }));

  // filter out datapoints that have falsy answers (except for 0) or don't have visit fields
  const filteredDataPoints = dataPoints.filter(({ answer, visit_id }) => (answer === 0 || answer) && visit_id);

  filteredDataPoints.forEach(({
    question_id, visit_number, visit_name, visit_completed_date, visit_id, answer, answer_id, answer_comment,
  }) => {
    const prevResponsesEntry = responses.find(({ questionId }) => questionId === question_id);
    // if we find a corresponding entry in the previous responses array, add the previous response
    if (prevResponsesEntry) {
      prevResponsesEntry.responses.push({
        visit: `Visit ${visit_number} - ${visit_name}`,
        visitCompletedDate: visit_completed_date || 0,
        visitId: `${visit_id}`,
        answer: `${answer}`,
        answerId: `${answer_id}`,
        answerComment: answer_comment,
      });
    }
  });
  return responses.filter(({ responses: responsesArr }) => responsesArr.length > 0);
};

// eslint-disable-next-line import/prefer-default-export
export { getPreviousResponsesFromDataPoints };
