import { DialogProps } from '@mui/material';
import EsourceDialog from './EsourceDialog';

export interface EsourceErrorDialogProps extends DialogProps {
  open: boolean,
  closeHandler: Function,
  alertTitle: string
}

// Component representing a Dialog to pop-up when an error occurs
export default function EsourceErrorDialog({
  open, closeHandler = () => {}, alertTitle, children,
}: EsourceErrorDialogProps) {
  // If the Dialog should not appear, hide it
  if (!open) return null;

  return (
    <EsourceDialog
      testId="errorDialog"
      alertTitle={alertTitle}
      type="Error"
      open={open}
      closeHandler={() => closeHandler()}
    >
      {children}
    </EsourceDialog>
  );
}
