import CalendarPicker from '@crio/crio-react-component/dist/cjs/components/DateTime/CrioCalendarPicker';
import CalendarPickerVariant from '@crio/crio-react-component/dist/cjs/enums/CalendarPickerVariant';
import {
  dateFormat,
  datetimeFormat,
  formatTextField,
  validate,
} from '@crio/crio-react-component/dist/cjs/components/DateTime/CrioCalendarUtils';
import { DateTime } from 'luxon';
import { RecordQuestion } from '../../../types';
import { AnswerType, QuestionFormatType } from '../../../enums';

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/
function DateQuestion(props: RecordQuestion) {
  const {
    variableName, formatType, recordId, handleAnswerChange, dataPoint, questionId, readOnly,
  } = props;
  const { answer } = dataPoint || {};
  if (dataPoint) {
    dataPoint.answer_type = formatType === QuestionFormatType.DATETIME ? AnswerType.DATE_TIME : AnswerType.DATE;
  }

  const variant = () => {
    switch (formatType) {
      case QuestionFormatType.DATE:
        return CalendarPickerVariant.DATE;
      case QuestionFormatType.DATE_ALLOW_ONGOING:
        return CalendarPickerVariant.DATE_ALLOW_ONGOING;
      case QuestionFormatType.DATETIME:
        return CalendarPickerVariant.DATETIME;
      default:
        return CalendarPickerVariant.DATE;
    }
  };

  const formatDate = (value: string | DateTime) => {
    if (value === 'ONGOING') {
      return value;
    }

    if (formatType === QuestionFormatType.DATETIME) {
      return formatTextField(value, datetimeFormat);
    }

    return formatTextField(value, dateFormat);
  };

  return (
    <CalendarPicker
      disabled={readOnly}
      name={variableName || ''}
      variant={variant()}
      selectedDate={`${answer || ''}`}
      popupOkButton
      onDateChange={(value: any) => {
        const dateTime = value && validate(value, variant()) ? value : '';
        const newDataPoint = {
          ...dataPoint,
          answer: formatDate(dateTime),
        };

        handleAnswerChange({
          questionId,
          variableName,
          newDataPoint,
          recordId,
          runRules: newDataPoint?.answer !== dataPoint?.answer,
        });
      }}
    />
  );
}

export default DateQuestion;
