import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { useTranslation } from 'react-i18next';
import { Column, Row } from '../index';
import { ProgressNotes } from '../Procedure';
import Slideout from './Slideout';
import { SlideoutState } from '../../enums';
import { ProgressNoteInterface, SubjectHeaderSiblingStyleProps } from '../../types';

const StyledVisitProgressNotes = styled(Column)<SubjectHeaderSiblingStyleProps>`
  width: 100%;
  max-width: calc(100% - 120px);
  overflow-x: scroll;
  min-height: calc(100vh - ${(props) => props.subjectHeaderHeight}px);
  h3 {
    color: ${(props) => props.theme.palette.h.main};
    margin-top: 0;
    font-size: 1.45em;
    font-weight: 500;
  }
  .NotesHeader {
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.palette.grey[600]};
    border-bottom: 1px dotted ${(props) => props.theme.palette.grey[300]};
    padding-bottom: 10px;
    font-size: .9em;
    .Description {
        font-style: italic;
    }
    .AddNote {
        align-items: center;
        font-size: 1em;
        font-weight: 500;
        .fa-file-plus {
            padding-right: 12px;
            font-size: 1.3em;
        }
    }
  }
`;

export type VisitProgressNotesProps = {
  readOnly: boolean,
  subjectHeaderHeight: number,
  timeZone?: string,
};

export default function VisitProgressNotesPage({ readOnly, subjectHeaderHeight, timeZone }: VisitProgressNotesProps) {
  const { t } = useTranslation();
  const [slideoutState, setSlideoutState] = useState<SlideoutState | undefined>();
  const [progressNoteToEdit, setProgressNoteToEdit] = useState<ProgressNoteInterface | undefined>();
  const closeSlideout = () => {
    setSlideoutState(undefined);
    setProgressNoteToEdit(undefined);
  };
  const handleProgressNoteToEditChange = (progressNote: ProgressNoteInterface, callback?: () => void) => {
    setProgressNoteToEdit(progressNote);
    if (callback) callback();
  };
  return (
    <Row className="CenterPageBackground">
      <StyledVisitProgressNotes className="CenterPagePadding" subjectHeaderHeight={subjectHeaderHeight}>
        <h3>{t('Visit.Progress Notes.Header')}</h3>
        <Row className="NotesHeader">
          <span className="Description">{t('Visit.Progress Notes.Description')}</span>
          {!readOnly
            && (
              <CrioButton
                className="AddNote"
                onClick={() => setSlideoutState(SlideoutState.ADD_PROGRESS_NOTE)}
              >
                <FontAwesomeIcon icon={faFilePlus as IconProp} />
                {t('Visit.Progress Notes.Add Progress Note')}
              </CrioButton>
            )}
        </Row>
        <ProgressNotes
          readOnly={readOnly}
          timeZone={timeZone}
          handleProgressNoteToEditChange={handleProgressNoteToEditChange}
          handleSlideoutStateChange={(slideout: SlideoutState | undefined) => { setSlideoutState(slideout); }}
          isVisitLevel
        />
      </StyledVisitProgressNotes>
      <Slideout
        defaultState={slideoutState}
        isVisitLevel
        closeSlideout={closeSlideout}
        defaultProgressNoteToEdit={progressNoteToEdit}
        subjectHeaderHeight={subjectHeaderHeight}
        timeZone={timeZone}
      />
    </Row>
  );
}

VisitProgressNotesPage.defaultProps = {
  timeZone: 'US/Eastern',
};
