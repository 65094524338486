import { Dialog } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import ChangeReason from '../../enums/ChangeReason';

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 10px;
  }
`;

const DialogContainer = styled('div')`
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.typography.fontFamily};
  width: 400px;
  gap: 8px;
`;

const Title = styled('div')`
  color: ${(props) => props.theme.palette.h.main};
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: 1.5em;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.grey[700]};
  }
`;

const SaveButton = styled(CrioButton)`
    width: auto !important;
`;

const ChangeReasonSelect = styled('select')`
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: 0.875em;
  font-weight: 300;
  color: ${(props) => props.theme.palette.grey[700]};
  border: 1px solid #e6eaf2;
  background-color: #fafbfc;
  padding: 5px;
  cursor: pointer;
`;

const Details = styled(CrioTextField)`
  font-size: 0.75em;
`;

type SubjectDetailsChangeModalProps = {
  open?: boolean;
  label: string;
  onClose: () => void;
  onSave: (changeReason: ChangeReason, changeReasonDetails?: string) => void;
};

export default function SubjectDetailsChangeDialog({
  open = true, label, onClose, onSave,
}: SubjectDetailsChangeModalProps) {
  const { t } = useTranslation();
  const [changeReason, setChangeReason] = useState<ChangeReason>(ChangeReason.CLARIFICATION_NEW_INFO);
  const [changeReasonDetails, setChangeReasonDetails] = useState<string | undefined>();

  const handleSave = () => {
    onSave(changeReason, changeReasonDetails);
    setChangeReason(ChangeReason.CLARIFICATION_NEW_INFO);
    setChangeReasonDetails(undefined);
  };

  const handleClose = () => {
    onClose();
    setChangeReason(ChangeReason.CLARIFICATION_NEW_INFO);
    setChangeReasonDetails(undefined);
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
    >
      <DialogContainer>
        <Title>
          <span>
            {`${t('Visit.Subject Details.Change Reason')}: ${label}`}
          </span>
          <CloseButton icon={faTimes} onClick={handleClose} aria-label="close" />
        </Title>
        <ChangeReasonSelect name="change-reason" onChange={(e) => setChangeReason(e.target.value as ChangeReason)}>
          <option value={ChangeReason.CLARIFICATION_NEW_INFO}>
            {t('Procedure.Dialog.Change Answers.Change Reasons.Clarification')}
          </option>
          <option value={ChangeReason.ERROR}>
            {t('Procedure.Dialog.Change Answers.Change Reasons.Error')}
          </option>
          <option value={ChangeReason.OTHER}>
            {t('Procedure.Dialog.Change Answers.Change Reasons.Other')}
          </option>
        </ChangeReasonSelect>
        <Details placeholder={t('Visit.Subject Details.Details')} multiline rows={2} value={changeReasonDetails} onChange={(e) => setChangeReasonDetails(e.target.value)} />
        <SaveButton onClick={handleSave}>{t('Common.Save')}</SaveButton>
      </DialogContainer>
    </StyledDialog>
  );
}
