import {
  Badge, IconButton, styled, ThemeProvider,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCommentAltLines, faFileAlt, faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useContext, useEffect, useState } from 'react';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Row from '../Row';
import Column from '../Column';
import ProgressNotes from '../Procedure/ProgressNotes';
import PreviousResponses from '../Procedure/PreviousResponses';
import SlideoutProps from './SlideoutProps';
import AddEditProgressNote from '../Procedure/AddEditProgressNote';
import { ProcedureDisplayType, SlideoutState } from '../../enums';
import ProcedureContext from '../../context/ProcedureContext';
import { ProgressNoteInterface, SubjectHeaderSiblingStyleProps } from '../../types';
import ProgressNoteContext from '../../context/ProgressNoteContext';

const collapsedWidth = 45;
const expandedWidth = 350;
const NotificationBadge = styled(Badge)`
  .customBadge {
    background-color: #e4761b;
    color: white;
  }
`;
const StyledSlideout = styled(Row) <SubjectHeaderSiblingStyleProps>`
  // to make the component stick when scrolling
  top: ${(props) => props.subjectHeaderHeight}px;
  height: calc(100vh - ${(props) => props.subjectHeaderHeight}px);
  position: sticky;
  overflow: unset;

  .Drawer {
    &.Open {
      position: sticky;
    }
    &.Collapsed {
      position: absolute;
    }

    .Paper {
      background: ${(props) => props.theme.palette.grey[200]};
      padding-right: 10px;
      box-sizing: border-box;
      height: 100%;
      width: 350px;
      position: absolute;
      overflow-y: auto;
      right: 0;
      padding-left: 15px;
      &.Collapsed {
          transform: translateX(350px);
          visibility: hidden;
      }
      &.Open {
          transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      }
    }
  }

  &.Open {
    z-index: 3;
    .SlideoutHeader {
      padding-bottom: 30px;
      >div {
        align-items: center;
        padding-top: 10px;
        justify-content: space-between;
      }
      h3 {
        color: ${(props) => props.theme.palette.h.main};
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.45em;
        font-weight: 500;
      }
      span {
        font-size: 0.875em;
        color: ${(props) => props.theme.palette.grey[600]};
      }
      button {
        background-color: unset;
        padding-left: 20px;
        color: ${(props) => props.theme.palette.grey[600]};
        &:hover {
          .fa-file-plus {
            color: ${(props) => props.theme.palette.info.dark};
          }
          .fa-times {
            color: ${(props) => props.theme.palette.grey[700]};
          }
        }
      }
    }
  }
  &.Hidden {
    display: initial;
  }
`;

const SlideoutIcon = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.palette.grey[500]}!important;
  background-color: ${(props) => props.theme.palette.grey[50]};
  box-sizing: border-box;
  color: ${(props) => props.theme.palette.grey[600]};
  width: ${collapsedWidth}px;
  height: ${collapsedWidth}px;
  margin-top: 10px;
  margin-left: -22px;

  &.Selected {
    background-color: ${(props) => props.theme.palette.grey[200]};
    border-right: none;
    cursor: default;
  }
  &:not(.Selected):hover {
    background-color: ${(props) => props.theme.palette.info.dark};
    color: ${(props) => props.theme.palette.grey[50]};
  }
  &:first-of-type {
    margin-top: 30px;
  }
`;

const IconButtonGroup = styled(Column)`
  right: 0;
  transition: 225ms;
  width: 30px;
  height: 100%;
  background: ${(props) => props.theme.palette.grey[200]};
  border-left: 1px solid ${(props) => props.theme.palette.grey[500]};

  &.Open {
     position: absolute;
     right: ${expandedWidth}px;
  }
`;

export default function Slideout({
  procedureName, defaultState, isVisitLevel, closeSlideout, defaultProgressNoteToEdit, subjectHeaderHeight, timeZone,
}: SlideoutProps) {
  const { t } = useTranslation();
  const [slideout, setSlideout] = useState<SlideoutState | undefined>(defaultState);
  const [progressNoteToEdit, setProgressNoteToEdit] = useState<ProgressNoteInterface | undefined>(defaultProgressNoteToEdit);
  const { isLoading: progressNotesLoading, progressNotes } = useContext(ProgressNoteContext);
  const { readOnly, displayType } = useContext(ProcedureContext);
  const { visitId } = useParams();
  const progressNoteCount = progressNotes ? progressNotes.length : 0;

  useEffect(() => {
    setProgressNoteToEdit(defaultProgressNoteToEdit);
  }, [defaultProgressNoteToEdit]);
  useEffect(() => {
    setSlideout(defaultState);
  }, [defaultState]);

  const inProgressNoteState = (slideout === SlideoutState.VIEW_PROGRESS_NOTES || slideout === SlideoutState.ADD_PROGRESS_NOTE || slideout === SlideoutState.EDIT_PROGRESS_NOTES);

  const headerText = (() => {
    switch (slideout) {
      case SlideoutState.VIEW_PROGRESS_NOTES:
        return t('Visit.Slideout.Progress Notes');
      case SlideoutState.ADD_PROGRESS_NOTE:
        return t('Visit.Slideout.Add Progress Note');
      case SlideoutState.EDIT_PROGRESS_NOTES:
        return t('Visit.Slideout.Edit Progress Note');
      case SlideoutState.PREVIOUS_RESPONSES:
      default:
        return t('Visit.Slideout.Previous Responses');
    }
  })();

  return (
    <ThemeProvider theme={crioTheme}>
      <StyledSlideout
        className={`${slideout ? 'Open' : 'Collapsed'} ${isVisitLevel && !slideout ? 'Hidden' : ''}`}
        subjectHeaderHeight={subjectHeaderHeight}
      >
        <Column>
          <IconButtonGroup className={slideout ? 'Open' : 'Collapsed'}>
            {!isVisitLevel && displayType !== ProcedureDisplayType.MULTI_RECORD
              && (
                <SlideoutIcon
                  className={slideout === SlideoutState.PREVIOUS_RESPONSES ? 'Selected' : ''}
                  value="prevResponses"
                  data-testid="slideoutPrevResponsesButton"
                  title={t('Visit.Slideout.View Previous Responses')}
                  onClick={() => setSlideout(SlideoutState.PREVIOUS_RESPONSES)}
                >
                  <FontAwesomeIcon icon={faCommentAltLines as IconProp} />
                </SlideoutIcon>
              )}
            {!isVisitLevel && visitId
              && (
                <SlideoutIcon
                  className={inProgressNoteState ? 'Selected' : ''}
                  value="progressNotes"
                  data-testid="slideoutProgressNoteButton"
                  title={t('Visit.Slideout.View Progress Notes')}
                  onClick={() => setSlideout(SlideoutState.VIEW_PROGRESS_NOTES)}
                >
                  <NotificationBadge
                    data-testid="progressNoteCountBadge"
                    classes={{ badge: 'customBadge' }}
                    badgeContent={progressNoteCount}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <FontAwesomeIcon icon={faFileAlt as IconProp} />
                  </NotificationBadge>

                </SlideoutIcon>
              )}
          </IconButtonGroup>
        </Column>
        <div className={`Drawer ${slideout ? 'Open' : 'Collapsed'}`}>
          <Column className={`Paper ${slideout ? 'Open' : 'Collapsed'}`}>
            <div className="SlideoutHeader">
              <Row>
                <h3>
                  {headerText}
                </h3>
                <Row>
                  {inProgressNoteState && !progressNotesLoading && !readOnly && !isVisitLevel && (
                    <IconButton onClick={() => { setProgressNoteToEdit(undefined); setSlideout(SlideoutState.ADD_PROGRESS_NOTE); }}>
                      <FontAwesomeIcon title={t('Visit.Slideout.Add New Progress Note')} icon={faFilePlus as IconProp} />
                    </IconButton>
                  )}
                  {!isVisitLevel && (
                    <IconButton onClick={() => setSlideout(undefined)}>
                      <FontAwesomeIcon title={t('Common.Close')} icon={faTimes as IconProp} />
                    </IconButton>
                  )}
                </Row>
              </Row>
              {!isVisitLevel && <span>{procedureName}</span>}
            </div>
            {slideout === SlideoutState.VIEW_PROGRESS_NOTES
              && (
                <ProgressNotes
                  readOnly={readOnly}
                  handleProgressNoteToEditChange={(progressNote: ProgressNoteInterface) => setProgressNoteToEdit(progressNote)}
                  handleSlideoutStateChange={(slideoutState: SlideoutState) => setSlideout(slideoutState)}
                  isVisitLevel={false}
                  timeZone={timeZone}
                />
              )}
            {slideout === SlideoutState.PREVIOUS_RESPONSES
              && (
                <PreviousResponses />
              )}
            {(slideout === SlideoutState.ADD_PROGRESS_NOTE || slideout === SlideoutState.EDIT_PROGRESS_NOTES)
              && (
                <AddEditProgressNote
                  progressNoteToEdit={progressNoteToEdit}
                  handleSlideoutStateChange={(slideoutState: SlideoutState) => setSlideout(slideoutState)}
                  closeSlideoutOnSave={closeSlideout}
                  timeZone={timeZone}
                />
              )}
          </Column>
        </div>
      </StyledSlideout>
    </ThemeProvider>
  );
}
