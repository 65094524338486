import { useEffect, useState } from 'react';
import { Autocomplete, Chip, styled } from '@mui/material';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import { DataListItemOption } from '../../../types';
import { retrieveDataList, healthCheckDataList } from '../../../util/dataListUtil';
import { isBlank } from '../../../util/stringUtil';
import { DataListErrorDialog } from '../Dialog';

const StyledAutocomplete = styled(Autocomplete)`
  & .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px!important;
    }
  }
  & [class*="MuiAutocomplete-popupIndicator"] {
    transform: none;
  }
  & [class*="MuiAutocomplete-endAdornment"] {
    top: auto;
  }
`;

// Style the text field
// Intentionally add some padding on the righ for the absolute end adornment to have space
const StyledCrioTextField = styled(CrioTextField)`
  min-width: 300px;

  .MuiInputBase-root {
    padding: 0 calc(18px + 9px + 5px) 0 9px!important;
  }
`;

// Manually put the end adornment in the top right corner
const StyledEndAdornment = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
`;

const StyledChip = styled(Chip)`
  border-radius: 3px;
  fill: ${(props) => props.theme.palette.grey[100]};
  outline: 1px solid ${(props) => props.theme.palette.grey[500]};
  color: ${(props) => props.theme.palette.grey[600]};
  font-size: 0.75rem;
  line-height: 1.25rem;
  height: auto;
  margin: 0 4px;

  .MuiChip-label {
    padding: 1px 20px 1px 10px;
    margin: 0;
  }

  .MuiChip-deleteIcon {
    font-size: 0.9375rem;
  }
`;

interface DataListQuestionProps {
  variableName: string,
  studyId: string,
  dataListExternalId?: string,
  restrictedToDataList?: boolean,
  readOnly?: boolean,
  multiline?: boolean,
  fullWidth?: boolean,
  placeholder?: string,
  value: string,
  onBlur: Function,
}

function DataListQuestion(props: DataListQuestionProps) {
  const {
    variableName, studyId, dataListExternalId, restrictedToDataList, readOnly, fullWidth, multiline, value: initialValue, placeholder, onBlur,
  } = props;
  const [dataList, setDataList] = useState<DataListItemOption[]>([]);
  const [dataListInProgressValue, setDataListInProgressValue] = useState<string>('');
  const [dataListRetrievalTimeout, setDataListRetrievalTimeout] = useState<ReturnType<typeof setTimeout>>();
  const [dataListErrorExternalId, setDataListErrorExternalId] = useState<string>('');
  const [hasDataListError, setHasDataListError] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const showDataListError = (dataListErrorExternalId === dataListExternalId);
  const hasDataList: boolean = (dataListExternalId !== undefined && dataListExternalId !== null && dataListExternalId !== '');
  const useStrictMode: boolean = (hasDataList && (restrictedToDataList as boolean) && !hasDataListError);
  const dataListValue = value
    .split(',')
    .filter((v) => v)
    .map((val) => ({ label: val, value: val }));

  /**
   * Update the Data List using the given search term
   * @param searchTerm  string containing the search term
   */
  const updateDataList = (searchTerm: string) => {
    clearTimeout(dataListRetrievalTimeout);
    setDataListRetrievalTimeout(
      setTimeout(async () => {
        const dataListJson: Array<DataListItemOption> = await retrieveDataList(studyId!, dataListExternalId!, searchTerm);
        setDataList(dataListJson);
      }, 500),
    );
  };

  /**
   * // On blur the answer is officially changed -- execute answer change logic
   * @param value The new value to update the context with
   */
  const handleOnBlur = (newValue: any, inProgressValue: any) => {
    let fullValue: string = newValue;
    if (hasDataList && !useStrictMode && !isBlank(inProgressValue)) {
      fullValue = [fullValue, inProgressValue]
        .filter((v) => v?.trim() !== '')
        .join(',');
    }

    setDataListInProgressValue('');
    // If this is a free-text question, we do not want to trigger updating the
    // question in the context until they've deselected the input.
    onBlur(fullValue);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      <StyledAutocomplete
        disabled={readOnly}
        data-testid={`autocomplete-${variableName}`}
        multiple={hasDataList}
        disablePortal={hasDataList}
        disableClearable
        freeSolo={!useStrictMode}
        autoSelect={hasDataList}
        value={hasDataList ? dataListValue : ''}
        // Keep track of the value that the User is currently typing, as the component re-renders when the options change
        inputValue={hasDataList ? dataListInProgressValue : value}
        popupIcon={<StyledEndAdornment icon={faList as IconProp} color={crioTheme.palette.grey[500]} />}
        // If Data Lists are used, add a list icon to the end of the input
        forcePopupIcon={hasDataList}
        onInputChange={(event, val: string) => {
          if (event?.type === 'change') setDataListInProgressValue(val);
        }}
        // Define the options and use the "value" attribute for label
        options={dataList}
        getOptionLabel={(option) => (hasDataList ? (option as DataListItemOption).value : '')}
        // In this Question has a Data List, update the Data Point as the autocomplete values are selected
        onChange={(event, values) => {
          if (!dataListExternalId) return;
          const dataListValues: unknown[] = (values as unknown[]);
          const dataPointValues: string = dataListValues
            ?.map((option) => (option as DataListItemOption)?.label || `${option}`)
            .filter((option) => !isBlank(option))
            .join(', ');

          if (['click', 'change', 'keydown', 'blur'].includes(event.type) && hasDataList) {
            // Trigger the context to update when clicks happen (selecting options, deleting chips)
            if (event.type === 'click') {
              handleOnBlur(dataPointValues, '');
            }
          }
        }}
        // Apply custom styles to the autocomplete chips
        renderTags={(val, getTagProps) => val.map((option, index: number) => (
          <StyledChip
            {...getTagProps({ index })}
            label={(option as DataListItemOption).value as string}
            deleteIcon={<FontAwesomeIcon icon={faTimes as IconProp} />}
          />
        ))}
        onFocus={() => {
          if (hasDataList && !hasDataListError) {
            healthCheckDataList(
              studyId!,
              dataListExternalId!,
              () => {
                setHasDataListError(false);
                setDataListErrorExternalId('');
              },
              () => {
                setHasDataListError(true);
                setDataListErrorExternalId(dataListExternalId!);
              },
            );
          }
        }}
        onBlur={() => handleOnBlur(value, dataListInProgressValue)}
        // Use a CrioTextField for the input
        renderInput={
          (params) => (
            <StyledCrioTextField
              {...params}
              placeholder={(!value && placeholder) || ''}
              disabled={readOnly}
              fullWidth={fullWidth}
              multiline={multiline}
              type="text"
              name={variableName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value: val } = e.target;
                if (hasDataList) {
                  updateDataList(val);
                } else {
                  setValue(val);
                }
              }}
            />
          )
        }
      />
      {showDataListError
        && (
          <DataListErrorDialog
            open={showDataListError}
            closeHandler={() => setDataListErrorExternalId('')}
          />
        )}
    </>
  );
}
export default DataListQuestion;

DataListQuestion.defaultProps = {
  dataListExternalId: null,
  restrictedToDataList: false,
  readOnly: false,
  multiline: false,
  fullWidth: false,
  placeholder: '',
};
