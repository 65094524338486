enum SubjectStatusType {
  INTERESTED = 'INTERESTED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  NOT_QUALIFIED = 'NOT_QUALIFIED',
  PREQUALIFIED = 'PREQUALIFIED',
  SCHEDULED_V1 = 'SCHEDULED_V1',
  SCREENING = 'SCREENING',
  ENROLLED = 'ENROLLED',
  SCREEN_FAIL = 'SCREEN_FAIL',
  DISCONTINUED = 'DISCONTINUED',
  COMPLETED = 'COMPLETED',
}

export default SubjectStatusType;
