import { v4 as uuidv4 } from 'uuid';
import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { DataPoint, ProgressNoteInterface } from '../types';
import ProcedureStatusInterface from '../types/ProcedureStatusInterface';

interface SandboxDB extends DBSchema {
  dataPoint: {
    value: DataPoint;
    key: string;
    indexes: {
      'by-procedure-id': string,
    };
  };
  procedureStatus: {
    value: ProcedureStatusInterface;
    key: string;
  };
  progressNote: {
    value: ProgressNoteInterface;
    key: string;
    indexes: {
      'by-procedure-id': string,
    }
  };
}

class DBConnection {
  static #instance: DBConnection;

  #name: string;

  #db: IDBPDatabase<SandboxDB> | null;

  #openConnection(): Promise<IDBPDatabase<SandboxDB>> {
    // the fallthrough is done on purpose here, so disabling this rule for this function
    /* eslint-disable no-fallthrough */
    const dbConn = openDB<SandboxDB>(this.#name, 3, {
      upgrade(db, oldVersion) {
        switch (oldVersion) {
          // db doesn't exist
          case 0: {
            // add dataPoint store and index
            const dataPointStore = db.createObjectStore('dataPoint', {
              keyPath: 'answer_id',
            });
            dataPointStore.createIndex('by-procedure-id', 'procedure_id');

            // add procedureStatus store
            db.createObjectStore('procedureStatus', {
              keyPath: 'procedureId',
            });
          }
          // upgrade from v1 to v2
          case 1: {
            // add progressNote store and index
            const progressNoteStore = db.createObjectStore('progressNote', {
              keyPath: 'externalId',
            });
            progressNoteStore.createIndex('by-procedure-id', 'procedureId');
          }
          case 2: {
            // Fix casing
            db.deleteObjectStore('progressNote');
            // add progressNote store and index
            const progressNoteStore = db.createObjectStore('progressNote', {
              keyPath: 'external_id',
            });
            progressNoteStore.createIndex('by-procedure-id', 'procedure_id');
          }
          default: { /* empty */
          }
        }
      },
    });
    return dbConn;
    /* eslint-enable no-fallthrough */
  }

  #closeConnection() {
    this.#db!.close();
  }

  async addDataPoints(dataPoints: Array<DataPoint>) {
    const transaction = this.#db?.transaction('dataPoint', 'readwrite');
    const dataPointStore = transaction?.objectStore('dataPoint');

    try {
      dataPoints.forEach(async (dataPoint) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { answer_id } = dataPoint;
        // Adds an answerId
        if (!answer_id) {
          // eslint-disable-next-line no-param-reassign
          dataPoint.answer_id = uuidv4();
        }
        await dataPointStore?.put(dataPoint);
      });
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      throw err;
    }

    return dataPoints;
  }

  async getDataPoints(procedureId: string) {
    const transaction = this.#db?.transaction('dataPoint', 'readonly');
    const dataPointStore = transaction?.objectStore('dataPoint');
    const procedureIdIndex = dataPointStore?.index('by-procedure-id');

    return (await procedureIdIndex?.getAll(procedureId) || []).filter(({ is_active }) => is_active).sort((a, b) => (a.created_date > b.created_date ? 1 : -1));
  }

  async addProcedureStatuses(procedureStatuses: Array<ProcedureStatusInterface>) {
    const transaction = this.#db?.transaction('procedureStatus', 'readwrite');
    const procedureStatusStore = transaction?.objectStore('procedureStatus');

    try {
      procedureStatuses.forEach(async (procedureStatus) => {
        await procedureStatusStore?.put(procedureStatus);
      });
    } catch (err) {
      console.log(err); // eslint-disable-line no-console
    }
  }

  async getProcedureStatuses() {
    const transaction = this.#db?.transaction('procedureStatus', 'readonly');
    const procedureStatusStore = transaction?.objectStore('procedureStatus');

    return procedureStatusStore?.getAll();
  }

  async deleteProgressNote(progressNoteKey: string) {
    const transaction = this.#db?.transaction('progressNote', 'readwrite');
    const progressNoteStore = transaction?.objectStore('progressNote');
    await progressNoteStore?.delete(progressNoteKey);
  }

  async addProgressNote(progressNoteToSave: ProgressNoteInterface) {
    const transaction = this.#db?.transaction('progressNote', 'readwrite');
    const progressNoteStore = transaction?.objectStore('progressNote');
    const currentTime = Date.now();

    const progressNote = { ...progressNoteToSave };
    const { external_id } = progressNote;

    if (!external_id) {
      progressNote.external_id = uuidv4();
      progressNote.date_created = currentTime;
    }
    progressNote.last_updated = currentTime;

    await progressNoteStore?.put(progressNote);
    return progressNote;
  }

  async getProgressNotes(procedureId: string) {
    const transaction = this.#db?.transaction('progressNote', 'readonly');
    const progressNoteStore = transaction?.objectStore('progressNote');

    const procedureIdIndex = progressNoteStore?.index('by-procedure-id');

    // IndexedDB will sort the progress notes by external id by default so have to sort them by
    // date created to match desired behavior
    return (await procedureIdIndex?.getAll(procedureId) || []).sort((a, b) => (a.date_created > b.date_created ? 1 : -1));
  }

  constructor(name: string) {
    this.#db = null;
    this.#name = name;
  }

  async #initialize() {
    this.#db = await this.#openConnection();
    await this.#db.clear('dataPoint');
    await this.#db.clear('procedureStatus');
    await this.#db.clear('progressNote');
  }

  static async getInstance(name: string) {
    if (!this.#instance) {
      this.#instance = new DBConnection(name);
      await this.#instance.#initialize();
    } else if (name !== this.#instance.#name) {
      // We're accessing a different data store
      this.#instance.#closeConnection();
      this.#instance = new DBConnection(name);
      await this.#instance.#initialize();
    }

    return this.#instance;
  }
}

const getIndexedDbConnection = (name: string): Promise<DBConnection> => DBConnection.getInstance(name);

export default getIndexedDbConnection;
