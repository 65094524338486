import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import styled from 'styled-components';

const AlertText = styled.div`
  &:not(:first-of-type) {
    margin-top: 10px;
  }

  &:not(:last-of-type) {
    padding-bottom: 8px;
  }
`;

interface RulesAlertDialogProps {
  alerts: Array<string>,
  setAlerts: (alerts: Array<string>) => void
}

export default function RulesAlertDialog({
  alerts,
  setAlerts,
}: RulesAlertDialogProps) {
  const { length } = alerts;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {length > 0 && (
      <CrioAlertDialog
        data-testid="alert-dialog"
        type="Info"
        open={length > 0}
        onClose={() => {
          setAlerts([]);
        }}
        fullWidth={false}
        disablePortal
        // Without these two props, the alert will de-focus elements on the page, leading to unintentional onBlur triggers
        // Particularly egregious in the procedure alert logic use-case
        disableAutoFocus
        disableEnforceFocus
        sx={{ width: '50%', margin: 'auto' }}
      >
        {
            length === 1
              ? <AlertText data-testid="alert-message">{alerts[0]}</AlertText>
              : (
                <ul style={{ marginTop: '10px', marginLeft: '-10px' }}>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  {alerts.map((alert, index) => <li data-testid="alert-message-item" key={index}><AlertText>{alert}</AlertText></li>)}
                </ul>
              )
          }
      </CrioAlertDialog>
      )}
    </>
  );
}
