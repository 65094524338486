import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CheckboxGroup from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioCheckboxGroup';
import {
  CheckboxOption,
} from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioCheckboxGroup/CrioCheckboxGroup';
import { Answer, RecordQuestion } from '../../../types';
import { parseAnswerCommentJson } from '../../../util/answerComments';
import { AnswerType } from '../../../enums';
import DataListQuestion from './DataListQuestion';

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/
function MultiSelectQuestion(props: RecordQuestion) {
  const {
    variableName, answerOptions, dataPoint, handleAnswerChange, questionId, readOnly, recordId,
  } = props;
  const { t } = useTranslation();
  const { studyId } = useParams();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { answer, answer_comment = '' } = dataPoint || {};
  const answerArray = answer instanceof Array ? answer : (answer as string || '').split('\n');
  if (dataPoint) {
    dataPoint.answer_type = AnswerType.BULLET;
  }

  // Construct CheckboxOptions for all of the Answer Options
  const checkboxOptions = (answerOptions?.map(({ text, commentRequired, answerCode }: Answer) => (
    {
      checked: answerArray.includes(text) || false,
      comment: parseAnswerCommentJson(answer_comment || '{}', text),
      name: text,
      value: text,
      code: answerCode,
      commentRequired: !!commentRequired,
    }
  )) || []);

  const convertCheckboxOptionsToAnswerComments = (options: Array<CheckboxOption>): string | undefined => {
    // Convert list of CheckboxOptions to {option.value: option.comment}
    const comments = options.filter((o) => o.comment)
      .reduce((commentsAggregator, o) => ({ ...commentsAggregator, [o.value]: o.comment }), {});
    const commentsString = JSON.stringify(comments);
    if (commentsString === JSON.stringify({})) {
      return undefined;
    }
    return commentsString;
  };

  const commentsUnchanged = (newAnswer: string | undefined): boolean => newAnswer !== answer;

  /**
   * Handle the updating of an Answer Checkbox
   * This will also update the Data Point and potentially trigger Procedure Logic
   * Thus this should be called any time the new Answer/Answer Comment is "finalized"
   * @param _event             {ChangeEvent<HTMLInputElement> | null}  containing the update event
   * @param options            Array<CheckboxOption> of all possible Checkbox Options
   * @param answerCommentValue CheckboxOption whose update should be handled
   */
  const handleAnswerCheckboxChange = (_event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null, options: Array<CheckboxOption>, changedOption: CheckboxOption) => {
    const { checked: changedOptionChecked, value: changedOptionValue } = changedOption;
    let newAnswers: string | undefined = options.filter((o) => o.checked).map((o) => o.value).join('\n');
    let newCodes: string | undefined = options.filter((o) => o.checked).map((o) => o.code).join('\n');
    // If the new answer is just the newline character, it's empty, set it to undefined
    newAnswers = newAnswers === '\n' ? undefined : newAnswers;
    newCodes = newCodes === '\n' ? undefined : newCodes;
    const newComments = convertCheckboxOptionsToAnswerComments(options);
    const newDataPoint = {
      ...dataPoint,
      answer: newAnswers,
      answer_code: newCodes,
      answer_comment: newComments,
    };

    handleAnswerChange({
      questionId,
      variableName,
      newDataPoint,
      recordId,
      runRules: commentsUnchanged(newAnswers),
      selectionValueChange: changedOptionChecked ? changedOptionValue : '',
    });
  };

  /**
   * Handle the updating of an Answer Comment
   * @param answerValue          string containing the Answer Option that is being updated
   * @param answerCommentValue   string containing the new Comment value
   */
  const handleAnswerCommentChange = (answerValue: string, answerCommentValue: string) => {
    const checkboxOption = checkboxOptions.find((option) => option.value === answerValue)!;
    checkboxOption.comment = answerCommentValue;
    handleAnswerCheckboxChange(null, checkboxOptions, checkboxOption);
  };

  // Get the Data List External ID for the currently selected answer, if any
  const renderComments = new Map(
    answerOptions
      ?.filter((answerOption) => answerArray.includes(answerOption.text))
      .map((answerOption) => [
        answerOption.text,
        (
          <DataListQuestion
            multiline
            variableName={variableName!}
            studyId={studyId!}
            dataListExternalId={answerOption?.dataList?.externalId}
            restrictedToDataList={answerOption?.dataList?.isRestrictedToDataList}
            readOnly={readOnly}
            value={parseAnswerCommentJson(answer_comment, answerOption.text) || ''}
            placeholder={t('Common.Enter Comment')}
            onBlur={(answerComment: string) => handleAnswerCommentChange(answerOption.text, answerComment)}
          />
        ),
      ]),
  );

  return (
    <CheckboxGroup
      disabled={readOnly}
      name={variableName || ''}
      checkboxOptions={checkboxOptions}
      onCheckboxOptionsChange={handleAnswerCheckboxChange}
      renderComments={renderComments}
    />
  );
}

export default MultiSelectQuestion;
