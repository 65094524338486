import firebase from 'firebase/compat/app';
import { setCookie } from '../api/cookies';

/**
 * Sign out of Firebase
 */
const signOutOfFirebase = async () => firebase.auth().signOut();

/**
 * Sign out of the Site-App and redirect to the given URL
 * @param siteAppDomainUrl string containing the Site-App Domain URL
 * @param redirectTo string containing the redirect location
 */
const signOutOfSiteAppAndRedirect = async (siteAppDomainUrl: string, redirectTo: string) => {
  await signOutOfFirebase();
  window.location.href = `${siteAppDomainUrl}/logout?redirectto=${encodeURIComponent(redirectTo)}`;
};

/**
 * Sign out of the Firebase and the Site-App and redirect to the given URL
 * @param siteAppDomainUrl string containing the Site-App Domain URL
 * @param redirectTo string containing the redirect location
 */
const signOutAndRedirect = async (siteAppDomainUrl: string, redirectTo: string) => {
  await signOutOfFirebase();
  signOutOfSiteAppAndRedirect(siteAppDomainUrl, redirectTo);
};

/**
 * Get the current Firebase User token
 * @return 	Promise<string | undefined> containing the current Firebase User token
 */
const getFirebaseUserToken = async (): Promise<string | undefined> => firebase.auth().currentUser?.getIdToken(false);

/**
 * Set the Firebase token as a cookie
 * @param cookieName string containing the cookie name
 * @param string string containing the cookie domain
 */
const setFirebaseToken = async (cookieName: string, domain: string) => {
  const baseToken = await getFirebaseUserToken();
  const expires = new Date().getTime() + (2 * 60 * 1000);
  setCookie(cookieName, baseToken!, expires, domain);
};

export { signOutAndRedirect, getFirebaseUserToken, setFirebaseToken };
