import { useTranslation } from 'react-i18next';
import EsourceErrorDialog from './EsourceErrorDialog';

export interface DataListErrorDialogProps {
  open: boolean,
  closeHandler: Function,
}

// Component representing a Dialog to pop-up when Data Lists can't be retrieved
export default function DataListErrorDialog({
  open,
  closeHandler = () => {},
}: DataListErrorDialogProps) {
  const { t } = useTranslation();

  // If the Dialog should not appear, hide it
  if (!open) return null;

  return (
    <EsourceErrorDialog
      open={open}
      closeHandler={closeHandler}
      alertTitle={t('Procedure.Dialog.Data List Error.Header')}
    >
      {t('Procedure.Dialog.Data List Error.Message')}
    </EsourceErrorDialog>
  );
}
