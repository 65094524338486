enum RoundingMode {
  HALF_UP = 'HALF_UP',
  HALF_DOWN = 'HALF_DOWN',
  UP = 'UP',
  DOWN = 'DOWN',
  CEILING = 'CEILING',
  FLOOR = 'FLOOR',
}

export default RoundingMode;
