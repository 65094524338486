import { ThemeProvider } from 'styled-components';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import {
  Routes, Route,
} from 'react-router-dom';
import DataCollection from './components/DataCollection';
import ErrorPage from './components/ErrorPage';

function App() {
  return (
    <ThemeProvider theme={crioTheme}>
      <Routes>
        {/* Normal Data Collection */}
        <Route path="/collection/study/:studyId/subject/:subjectId/visit/:visitId" element={<DataCollection />} />
        {/* Logs */}
        <Route path="/collection/study/:studyId/subject/:subjectId" element={<DataCollection />} />
        {/* Sandbox */}
        <Route path="/collection/study/:studyId/visit/:visitId" element={<DataCollection />} />
        {/* Sandbox Procedure */}
        <Route path="/collection/study/:studyId/procedure/:procedureId" element={<DataCollection />} />
        {/* Sandbox Procedure Template */}
        <Route path="/collection/study/:studyId/procedureTemplate/:procedureTemplateId" element={<DataCollection />} />
        {/* Not Found */}
        <Route path="/" element={<div>To load data collection, proceed to /collection/study/:studyId/visit/:visitId</div>} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
