import { useEffect, useState } from 'react';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import { DataPoint, RecordQuestion } from '../../../types';
import { AnswerType } from '../../../enums';
import { getFilteredNumber } from '../../../util/numberUtil';

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/
function FreeNumberQuestion(props: RecordQuestion) {
  const {
    variableName, recordId, handleAnswerChange, dataPoint: initialDataPoint, questionId, readOnly, formatType,
  } = props;
  const [dataPoint, setDataPoint] = useState<DataPoint>();
  const { answer } = dataPoint || {};
  if (dataPoint) {
    dataPoint.answer_type = AnswerType.NUMBER;
  }

  useEffect(() => {
    setDataPoint(initialDataPoint);
  }, [initialDataPoint]);

  return (
    <CrioTextField
      disabled={readOnly}
      type="number"
      name={variableName}
      value={(answer === 0) ? 0 : (answer || '')}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setDataPoint((currentDataPoint: DataPoint | undefined) => {
          if (!currentDataPoint) return undefined;
          return {
            ...currentDataPoint,
            answer: getFilteredNumber(e.target.value, formatType),
          };
        });
      }}
      onBlur={() => {
        handleAnswerChange({
          questionId,
          variableName,
          newDataPoint: dataPoint,
          recordId,
          runRules: dataPoint?.answer !== initialDataPoint?.answer,
        });
      }}
      // https://github.com/mui/material-ui/issues/7960#issuecomment-1935717640
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
    />
  );
}

export default FreeNumberQuestion;
