enum SubjectFieldName {
  FIRST_NAME = 'FIRST_NAME',
  MIDDLE_NAME = 'MIDDLE_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  MOBILE_PHONE = 'MOBILE_PHONE',
  BIRTH_DATE = 'BIRTH_DATE',
}

export default SubjectFieldName;
