import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Footer from './Footer';
import Column from './Column';
import Row from './Row';
import errorImage from '../assets/esource_error.png';
import logo from '../assets/crio_logo.png';

const ErrorPageComponent = styled(Column)`

  #dynamic-panel {
    width: 100%;
    height: 72px;
    padding: 8px 2px 2px 2px;
    background-color: #0d2b3a;
    box-sizing: border-box;
    border-bottom: 2px solid #e57e2b;
  }

  .header-container {
    width: 1000px;
    margin: auto;
  }

  .container {
    background-color: ${(props) => props.theme.palette.grey.A50};
    width: 100%;
    padding-top: 60px;
  }

  .Workspace {
    align-items: stretch;
    width: 1000px;
    height: 325px;
    box-sizing: border-box;
    margin: auto;
  }

  .error-message {
    width: 50%;
    font-family: 'Poppins Light', 'Poppins', 'Poppins', sans-serif;
    align: auto;
    padding-left: 20px;
    color: ${(props) => props.theme.palette.grey.A900};
    font-size: 16px;
  }

  .header {
    font-size: 56px;
    margin-bottom: 15px;
  }

  .link {
    text-decoration: none;
    color: ${(props) => props.theme.palette.linkText.main};
    display: contents;
  }

  .error-image {
    width: 50%;
    padding-left: 30px;
  }

  .footer {
    position: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(-37.08448757697341deg, #0d2b3a 52%, #071b26 100%);
  }

`;

function ErrorPage() {
  const { t } = useTranslation();
  return (
    <ErrorPageComponent>
      <Row id="dynamic-panel" data-testid="errorPageHeader">
        <div className="header-container">
          <img src={logo} alt="CRIO" width="112px" height="34px" />
        </div>
      </Row>
      <Row className="container">
        <Row className="Workspace">
          <Column className="error-message">
            <h2 className="header">{t('Error.Oops')}</h2>
            {t('Error.Something went wrong')}
            <br />
            <br />
            {t('Error.Close this tab and try again or contact')}
            <br />
            <a href="support@clinicalresearch.io" className="link">support@clinicalresearch.io</a>
            {' '}
            {t('Error.if the error persists')}
          </Column>
          <Column className="error-image">
            <img src={errorImage} alt="Oops" width="450px" />
          </Column>
        </Row>
      </Row>
      <div className="footer">
        <Footer />
      </div>
    </ErrorPageComponent>
  );
}

export default ErrorPage;
