import {
  Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NotDoneRecordsInterface } from './types';
import MissingAnswersResolutionType from '../enums/MissingAnswersResolutionType';
import { ChangeReason, ProgressNoteStatus } from '../enums';

type SaveAlertsValues = {
  changeReason: ChangeReason,
  changeReasonCompleted: boolean,
  changeReasonDetails: string,
  changeReasonError: string,
  missingAnswersCompleted: boolean | 'ERROR',
  missingAnswersResolutionType: MissingAnswersResolutionType,
  notDoneComment: string,
  notDoneError: string,
  notDoneQueuedChanges: NotDoneRecordsInterface,
  progressNoteContent: string,
  progressNoteError: string,
  progressNoteStatus: ProgressNoteStatus,

  setChangeReason: Dispatch<SetStateAction<ChangeReason>>,
  setChangeReasonCompleted: Dispatch<SetStateAction<boolean>>,
  setChangeReasonDetails: Dispatch<SetStateAction<string>>,
  setChangeReasonError: Dispatch<SetStateAction<string>>,
  setMissingAnswersCompleted: Dispatch<SetStateAction<boolean | 'ERROR'>>,
  setMissingAnswersResolutionType: Dispatch<SetStateAction<MissingAnswersResolutionType>>,
  setNotDoneComment: Dispatch<SetStateAction<string>>,
  setNotDoneError: Dispatch<SetStateAction<string>>,
  setNotDoneQueuedChanges: Dispatch<SetStateAction<NotDoneRecordsInterface>>,
  setProgressNoteContent: Dispatch<SetStateAction<string>>,
  setProgressNoteError: Dispatch<SetStateAction<string>>,
  setProgressNoteStatus: Dispatch<SetStateAction<ProgressNoteStatus>>,
  validateChangeReason: Function,
  validateMissingAnswers: Function,
};

const defaultValue = {

  changeReason: ChangeReason.CLARIFICATION_NEW_INFO,
  changeReasonCompleted: false,
  changeReasonDetails: '',
  changeReasonError: '',
  missingAnswersCompleted: false,
  missingAnswersResolutionType: MissingAnswersResolutionType.SKIPPED,
  notDoneComment: '',
  notDoneError: '',
  notDoneQueuedChanges: {},
  progressNoteContent: '',
  progressNoteError: '',
  progressNoteStatus: ProgressNoteStatus.DRAFT,

  setChangeReason: () => { },
  setChangeReasonCompleted: () => {},
  setChangeReasonDetails: () => { },
  setChangeReasonError: () => { },
  setMissingAnswersCompleted: () => { },
  setMissingAnswersResolutionType: () => { },
  setNotDoneComment: () => { },
  setNotDoneError: () => { },
  setNotDoneQueuedChanges: () => { },
  setProgressNoteContent: () => { },
  setProgressNoteError: () => { },
  setProgressNoteStatus: () => { },
  validateChangeReason: () => {},
  validateMissingAnswers: () => {},
};

interface SaveAlertContextProviderProps {
  children: ReactNode,
}

const SaveAlertContext = createContext<SaveAlertsValues>(defaultValue);
export default SaveAlertContext;

export function SaveAlertContextProvider(props: SaveAlertContextProviderProps) {
  const { children } = props;
  const { t } = useTranslation();

  const [changeReason, setChangeReason] = useState<ChangeReason>(ChangeReason.CLARIFICATION_NEW_INFO);
  const [changeReasonCompleted, setChangeReasonCompleted] = useState<boolean>(false);
  const [changeReasonDetails, setChangeReasonDetails] = useState<string>('');
  const [changeReasonError, setChangeReasonError] = useState<string>('');
  const [notDoneQueuedChanges, setNotDoneQueuedChanges] = useState<NotDoneRecordsInterface>({});
  const [notDoneComment, setNotDoneComment] = useState<string>('');
  const [notDoneError, setNotDoneError] = useState<string>('');
  const [missingAnswersResolutionType, setMissingAnswersResolutionType] = useState<MissingAnswersResolutionType>(MissingAnswersResolutionType.SKIPPED);
  const [missingAnswersCompleted, setMissingAnswersCompleted] = useState<boolean | 'ERROR'>(false);
  const [progressNoteStatus, setProgressNoteStatus] = useState<ProgressNoteStatus>(ProgressNoteStatus.DRAFT);
  const [progressNoteContent, setProgressNoteContent] = useState<string>('');
  const [progressNoteError, setProgressNoteError] = useState<string>('');

  const validateChangeReason = (): boolean => {
    if (!changeReason || !changeReasonDetails) {
      setChangeReasonError(t('Procedure.Dialog.Change Answers.A change reason is required'));
      return false;
    }

    setChangeReasonError('');
    return true;
  };

  const validateMissingAnswers = (): boolean | 'ERROR' => {
    if (missingAnswersResolutionType === MissingAnswersResolutionType.SKIPPED) {
      return false;
    }

    if (missingAnswersResolutionType === MissingAnswersResolutionType.ENTERED_PROGRESS_NOTE && (!progressNoteStatus || !progressNoteContent)) {
      setProgressNoteError(t('Procedure.Progress Note.Please enter a progress note'));
      return 'ERROR';
    }
    setProgressNoteError('');

    if (missingAnswersResolutionType === MissingAnswersResolutionType.MARKED_NOT_DONE && (
      Object.values(notDoneQueuedChanges).flatMap((answers) => Object.values(answers)).some((markedNotDone) => !!markedNotDone) && !notDoneComment
    )) {
      setNotDoneError(t('Procedure.Dialog.Mark Not Done.Error'));
      return 'ERROR';
    }
    setNotDoneError('');

    return true;
  };

  const value = useMemo(
    () => ({
      changeReason,
      changeReasonCompleted,
      changeReasonDetails,
      changeReasonError,
      missingAnswersCompleted,
      missingAnswersResolutionType,
      notDoneComment,
      notDoneError,
      notDoneQueuedChanges,
      progressNoteContent,
      progressNoteError,
      progressNoteStatus,

      setChangeReason,
      setChangeReasonCompleted,
      setChangeReasonDetails,
      setChangeReasonError,
      setMissingAnswersCompleted,
      setMissingAnswersResolutionType,
      setNotDoneComment,
      setNotDoneError,
      setNotDoneQueuedChanges,
      setProgressNoteContent,
      setProgressNoteError,
      setProgressNoteStatus,
      validateChangeReason,
      validateMissingAnswers,
    }),
    [
      changeReason,
      changeReasonCompleted,
      changeReasonDetails,
      changeReasonError,
      missingAnswersCompleted,
      missingAnswersResolutionType,
      notDoneComment,
      notDoneError,
      notDoneQueuedChanges,
      progressNoteContent,
      progressNoteError,
      progressNoteStatus,
    ],
  );

  return (
    <SaveAlertContext.Provider value={value}>
      {children}
    </SaveAlertContext.Provider>
  );
}
