import { QuestionFormatType } from '../enums';

/**
 * Get the filtered number input based on the given Question Type
 * @param numberString  string containing the input value to filter
 * @param mode  { QuestionFormatType | undefined | null } to filter based upon
 * @return  number, filtered using the question format type
 */
// eslint-disable-next-line  import/prefer-default-export
export function getFilteredNumber(numberString: string, mode: QuestionFormatType | undefined | null): number {
  switch (mode) {
    case QuestionFormatType.NUMBER_ALLOW_DECIMALS:
      return parseFloat(numberString.replace(/[^0-9.-]/g, ''));

    case QuestionFormatType.NUMBER:
      // eslint-disable-next-line  no-case-declarations
      const integerString = numberString.split('.')[0];
      return parseInt(integerString.replace(/[^0-9-]/g, ''), 10);

    default:
      return NaN;
  }
}
