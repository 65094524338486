import {
  createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import { useTranslation } from 'react-i18next';
import {
  DataPoint, SubjectData, SubjectStatus, SubjectStatusChangeReason, ProcedureStatusInterface, VisitInterface,
} from '../types';
import {
  ProgressNoteStatus,
  SubjectStatusType,
  VisitModeType,
  VisitStatus,
  ProcedureStatus,
  ProcedureDisplayType,
  CarryForwardType,
  AnswerType,
} from '../enums';
import { saveDataPoints } from '../api/esourceService';
import { getProcedureLevelDataPointFromBase } from '../util/dataPointUtil';
import ProgressNoteContext from './ProgressNoteContext';
import BlurOverlay from '../components/BlurOverlay';
import { getProcedureTypeFromDisplayType } from '../util/procedureUtils';

interface CompleteVisitValues {
  statuses: SubjectStatus[],
  initialSubjectStatus: SubjectStatus | undefined,
  selectedStatus: SubjectStatusType,
  setSelectedStatus: Dispatch<SetStateAction<SubjectStatusType>>,
  selectedChangeReason: SubjectStatusChangeReason | undefined,
  setSelectedChangeReason: Dispatch<SetStateAction<SubjectStatusChangeReason | undefined>>,
  skipProgressNote: boolean,
  setSkipProgressNote: Dispatch<SetStateAction<boolean>>,
  progressNoteType: ProgressNoteStatus,
  setProgressNoteType: Dispatch<SetStateAction<ProgressNoteStatus>>,
  progressNote: string,
  setProgressNote: Dispatch<SetStateAction<string>>,
  getStatusFromType: (type: SubjectStatusType) => SubjectStatus | undefined,
  reasonRequiredStatuses: SubjectStatusType[],
  visitComplete: boolean,
  completeVisit: (incompleteVisit: boolean) => void,
}

export interface CompleteVisitContextProviderProps {
  children: ReactNode,
  statuses: SubjectStatus[],
  subjectData: SubjectData,
  visitConfig: VisitInterface,
  visitMode: VisitModeType,
  skipProcedureStatuses: Array<ProcedureStatusInterface>,
}

const CompleteVisitContext = createContext<CompleteVisitValues | Record<string, never>>({});
export default CompleteVisitContext;

export function CompleteVisitContextProvider(props: CompleteVisitContextProviderProps) {
  const {
    children, statuses, subjectData, visitConfig, visitMode, skipProcedureStatuses,
  } = props;
  const {
    procedures, name: visitName, type: visitType, order: visitOrder, number: visitNumber, versionId, versionName, templateLevel,
  } = visitConfig;
  const {
    patientId, siteId, trialId, armId, armName,
  } = subjectData;
  const { studyId, subjectId, visitId } = useParams();
  const { currentUser } = getAuth();
  const { t } = useTranslation();
  const typeToStatus: { [p: string]: SubjectStatus } = Object.fromEntries(statuses.map((status) => [status.status, status]));
  const getStatusFromType = (type: SubjectStatusType): SubjectStatus | undefined => typeToStatus[type];
  const initialSubjectStatus = getStatusFromType(subjectData.subjectStatus);
  // default status is either SCREENING or PRE_QUALIFIED
  const defaultStatus = SubjectStatusType.SCREENING in typeToStatus ? SubjectStatusType.SCREENING : SubjectStatusType.PREQUALIFIED;
  // if the given subject status is one of the options, use it. otherwise use default status.
  const [selectedStatus, setSelectedStatus] = useState<SubjectStatusType>(initialSubjectStatus ? initialSubjectStatus.status : defaultStatus);
  const [selectedChangeReason, setSelectedChangeReason] = useState<SubjectStatusChangeReason | undefined>();
  const [visitComplete, setVisitComplete] = useState<boolean>(false);
  const [skipProgressNote, setSkipProgressNote] = useState<boolean>(false);
  const [progressNoteType, setProgressNoteType] = useState<ProgressNoteStatus>(ProgressNoteStatus.PUBLISHED);
  const [progressNote, setProgressNote] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>('');
  const { handleCreateProgressNote } = useContext(ProgressNoteContext);
  // Change reason is required for SCREEN_FAIL and DISCONTINUED statuses
  const reasonRequiredStatuses = [SubjectStatusType.SCREEN_FAIL, SubjectStatusType.DISCONTINUED];

  // Validate change reason + change reason comment
  // returns true if validation is successful
  // returns false + opens alert popup if validation is unsuccessful
  const validateChangeReason = (): boolean => {
    // get selected SubjectStatus from SubjectStatusType
    const selectedSubjectStatus = getStatusFromType(selectedStatus);
    // doesn't need change reason if selected status is same as initial status
    const needsChangeReason = selectedSubjectStatus?.status !== initialSubjectStatus?.status;
    if (!needsChangeReason) return true;
    if (selectedSubjectStatus?.type === 'FREE_ENTRY') {
      const reasonRequired = reasonRequiredStatuses.includes(selectedStatus);
      if (reasonRequired && !selectedChangeReason?.changeReason) {
        setAlertText(t('Visit.Complete Visit.Validation.Enter change reason'));
        return false;
      }
    } else if (selectedSubjectStatus?.type === 'SINGLE_SELECT') {
      // if change reasons are single select and none are selected throw an error
      if (!selectedChangeReason?.changeReason) {
        setAlertText(t('Visit.Complete Visit.Validation.Select change reason'));
        return false;
      }
      const selectedReasonOption = selectedSubjectStatus.reasonOptions?.find((option) => option.text === selectedChangeReason.changeReason);
      if (selectedReasonOption?.commentRequired && !selectedChangeReason.changeReasonComment) {
        setAlertText(t('Visit.Complete Visit.Validation.Enter change reason comment'));
        return false;
      }
    }
    return true;
  };

  // Validate progress note
  // returns true if validation is successful
  // returns false + opens alert popup if validation is unsuccessful
  const validateProgressNote = (): boolean => {
    if (!skipProgressNote && !progressNote) {
      setAlertText(t('Visit.Complete Visit.Validation.Enter progress note'));
      return false;
    }
    return true;
  };

  const completeVisit = async (incompleteVisit: boolean) => {
    const visitStatus: VisitStatus = incompleteVisit ? VisitStatus.PARTIALLY_COMPLETED : VisitStatus.COMPLETED;
    const currentTime = new Date().getTime();
    const completedVisitDatapoint = {
      subject_id: subjectId!,
      study_id: studyId!,
      patient_id: patientId,
      subject_status: selectedStatus,
      site_id: siteId,
      visit_status: visitStatus,
      visit_id: visitId!,
      visit_name: visitName,
      visit_type: visitType,
      visit_completed_date: currentTime,
      visit_order: visitOrder,
      visit_number: visitNumber,
      visit_version_id: versionId,
      visit_version_name: versionName,
      visit_template_level: templateLevel,
      answer_completed_date: currentTime,
      answer_user: currentUser!.displayName,
      answer_user_id: currentUser!.uid,
      answer_type: AnswerType.ANCILLARY,
      change_reason_details: selectedChangeReason?.changeReason,
      change_reason_comment: selectedChangeReason?.changeReasonComment,
      trial_id: trialId,
      arm_id: armId,
      arm_name: armName,
    } as DataPoint;

    // Pull those Procedures that were forcefully skipped, but were completed in past Visits
    const requireSkipProcedureDataPoints: Array<DataPoint> = skipProcedureStatuses
      .map((skipProcedureStatus: ProcedureStatusInterface) => {
        const { procedureId: skipProcedureId } = skipProcedureStatus;
        const {
          carryForwardType: skipProcedureCarryForwardType = CarryForwardType.NO,
          displayType: skipProcedureDisplayType = ProcedureDisplayType.NORMAL,
          name: skipProcedureName,
        } = procedures.find(({ procedureId: pId }) => pId === skipProcedureId) || {};

        return {
          ...getProcedureLevelDataPointFromBase(completedVisitDatapoint, skipProcedureStatus),
          procedure_completed_date: currentTime,
          procedure_name: skipProcedureName || '',
          procedure_type: getProcedureTypeFromDisplayType(skipProcedureDisplayType, skipProcedureCarryForwardType),
          // Even if the skip type was a synthetic type, set it to normal SKIPPED here
          // so that it won't appear in the Complted Visit page
          procedure_status: skipProcedureStatus.status === ProcedureStatus.ICF_SKIPPED ? ProcedureStatus.ICF_SKIPPED : ProcedureStatus.SKIPPED,
        };
      });

    // Validation (only validate progress note if incomplete visit)
    if ((incompleteVisit && !validateProgressNote()) || !validateChangeReason()) return;

    // Spam protection
    if (isSaving) return;
    setIsSaving(true);

    try {
      // only save completed visit datapoint if default type visit
      if (visitMode === VisitModeType.DEFAULT) {
        const dataPointsToSave: Array<DataPoint> = [completedVisitDatapoint].concat(requireSkipProcedureDataPoints);
        await saveDataPoints({
          dataPointsToSave,
          studyId: studyId!,
          visitId,
          subjectId,
        });
      }
      if (incompleteVisit && !skipProgressNote) {
        await handleCreateProgressNote(progressNoteType!, progressNote, false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsSaving(false);
    }

    setVisitComplete(true);
  };

  const value = useMemo(
    () => ({
      statuses,
      initialSubjectStatus,
      selectedStatus,
      setSelectedStatus,
      selectedChangeReason,
      setSelectedChangeReason,
      skipProgressNote,
      setSkipProgressNote,
      progressNoteType,
      setProgressNoteType,
      progressNote,
      setProgressNote,
      getStatusFromType,
      reasonRequiredStatuses,
      visitComplete,
      completeVisit,
    }),
    [
      selectedStatus,
      selectedChangeReason,
      skipProgressNote,
      progressNoteType,
      progressNote,
      visitComplete,
      isSaving,
    ],
  );

  return (
    <CompleteVisitContext.Provider value={value}>
      {children}
      {isSaving && <BlurOverlay />}
      {alertText && (
        <CrioAlertDialog
          type="Error"
          open={!!alertText}
          onClose={() => setAlertText('')}
          fullWidth={false}
          disablePortal
          sx={{ margin: 'auto', '.MuiAlert-message': { paddingRight: '10px' } }}
        >
          {alertText}
        </CrioAlertDialog>
      )}
    </CompleteVisitContext.Provider>
  );
}
