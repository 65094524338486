import RadioGroup from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup';
import {
  Accordion, AccordionDetails, AccordionSummary, List, ListItem, ThemeProvider,
} from '@mui/material';
import { LabeledCheckbox } from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioCheckboxGroup';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandMore } from '@mui/icons-material';
import { faCheckCircle as checkCircleSolid, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle as checkCircleRegular } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import { RadioOption } from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup/RadioGroup';
import { CrioDivider } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { useTranslation } from 'react-i18next';
import Confetti from 'react-dom-confetti';
import { Column, Row } from '../index';
import { SubjectStatusChangeReason } from '../../types';
import InfoText from '../InfoText';
import { ProgressNoteStatus, SubjectStatusType, SubjectHeaderHeight } from '../../enums';
import CompleteVisitContext from '../../context/CompleteVisitContext';

const IncompleteVisitComponent = styled(InfoText)`
  .PageHeading {
    align-items: center;
    padding-bottom: 2px;
  }

  h3 {
    color: ${(props) => props.theme.palette.h.main};
    margin: 0;
    font-size: 1.45em;
    font-weight: 500;
  }

  .fa-circle-exclamation {
    padding-right: 20px;
    color: ${(props) => props.theme.palette.info.dark};
    font-size: 2.5em;
  }

  .IncompleteDetails {
    padding: 0 30px 0 50px;
    ul {
      margin-left: 30px;
    }
    li {
      font-size: 0.9em;
      padding: 5px 0;
    }
    .ProcedureNote {
      padding: 23px 0 10px 0;
      font-size: 0.9em;
      border-bottom: 1px dotted #cccccc;
      margin-bottom: 12px;
    }
    strong {
      font-weight: 600;
    }
    .MuiTypography-root.MuiFormControlLabel-label {
      font-size: 1em;
    }
    .EnterProgressNote {
      padding-top: 20px;
      .MuiFormGroup-row {
        padding-top: 10px;
      }
      label:first-of-type {
        margin-right: 35px;
      }
    }
  }
`;

const CompleteVisitComponent = styled.div`
  .Incomplete {
    margin-bottom: 40px;
  }
  .FinishDivider {
    justify-content: space-between;
    color: ${(props) => props.theme.palette.grey[500]};
    padding-bottom: 25px;
    hr {
      width: 50%;
      border-top: 2px dotted;
    }
    .fa-circle-check {
      padding: 0 10px;
    }
  }
  button {
    font-weight: 500;
  }
`;

const StyledSelectStatusComponent = styled.div`
  .RadioGroupLabel {
    font-size: 1.1em;
    font-weight: 400;
    min-width: 50%;
    padding-bottom: 20px;
  }
  .MuiTypography-root.MuiFormControlLabel-label {
    font-size: 1em;
    color: ${(props) => props.theme.palette.grey[700]};
  }
  .MuiButtonBase-root {
    margin-right: 15px;
  }
  .RadioGroup {
    padding-bottom: 20px;
  }
  >div:nth-child(2) {
    .RadioGroupLabel {
      padding-top: 15px;
      border-top: 1px dotted ${(props) => props.theme.palette.grey[500]};
    }
    .RadioGroup {
      width: fit-content;
    }
    >.MuiTextField-root {
      padding-bottom: 20px;
      width: 300px;
    }
  }
`;

const StyledVisitCompletedPage = styled(Column)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: calc(100vh - ${SubjectHeaderHeight.UNSTUCK}px);
  padding-top: 65px;
  >div {
    justify-content: center;
    align-items: center;
  }
  .PartiallyCompleted {
    color: #dcc546;
  }
  .FullyCompleted {
    color: ${(props) => props.theme.palette.success.dark};
  }
  .VisitCompleted {
    padding-bottom: 23px;
    span {
      font-size: 1.3em;
      font-weight: 500;
    }
    .fa-circle-check {
      font-size: 2.2em;
      padding-right: 15px;
    }
  }
  .Description {
    color: ${(props) => props.theme.palette.grey[900]};
  }
`;

const StyledAccordion = styled(Accordion)`
  background-color: #f3f2e9 !important;
  margin: 8px 0;

  .IncompleteLogsHeader {
    font-weight: 400;
  }

  .IncompleteLogsSubheader {
    font-style: italic;
    color: ${(props) => props.theme.palette.grey[600]};
  }

  .Summary {
    font-size: 0.85em;
  }
`;

export type CompleteVisitProps = {
  incompleteVisitProcedureNames?: string[],
  incompleteSubjectProcedureNames?: string[],
  readOnly: boolean,
};

type VisitCompletedProps = {
  partiallyCompleted: boolean,
};

interface IncompleteSubjectProceduresProps {
  incompleteSubjectProcedureNames: string[],
}
function IncompleteSubjectProcedures({ incompleteSubjectProcedureNames }: IncompleteSubjectProceduresProps) {
  if (incompleteSubjectProcedureNames.length === 0) {
    return null;
  }

  return (
    <StyledAccordion
      data-testid="IncompleteSubjectProceduresAccordion"
      elevation={0}
      disableGutters
      className="PreviousResponses"
    >
      <AccordionSummary className="Summary" expandIcon={<ExpandMore />}>
        <div>
          <div className="IncompleteLogsHeader">Reminder Subject Logs are incomplete</div>
          <div className="IncompleteLogsSubheader">The state of completion of the records will not affect the procedure status or visit status</div>
        </div>
      </AccordionSummary>

      <AccordionDetails>
        <List sx={{ listStyle: 'disc' }}>
          {incompleteSubjectProcedureNames.map((procedureName, index) => (
            <ListItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={{ display: 'list-item' }}
            >
              {procedureName}
            </ListItem>
          ))}
        </List>
      </AccordionDetails>

    </StyledAccordion>
  );
}

function VisitCompletedPage({ partiallyCompleted }: VisitCompletedProps) {
  const { t } = useTranslation();
  const [confettiActive, setConfettiActive] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => setConfettiActive(true), 450);
  }, []);

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 2000,
    stagger: 3,
    width: '10px',
    height: '10px',
    perspective: '500px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
  };

  return (
    <StyledVisitCompletedPage>
      <Row className={`VisitCompleted ${partiallyCompleted ? 'PartiallyCompleted' : 'FullyCompleted'}`}>
        <FontAwesomeIcon icon={checkCircleSolid} />
        <span>{t(`Visit.Completed Visit.${partiallyCompleted ? 'Partially' : 'Fully'} completed header`)}</span>
      </Row>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Confetti config={confettiConfig} active={confettiActive} />
      </div>
      <Row>
        <span className="Description">
          {t(`Visit.Completed Visit.${partiallyCompleted ? 'Partially' : 'Fully'} completed`)}
          {' '}
        </span>
      </Row>
    </StyledVisitCompletedPage>
  );
}

const getLabelledRadioButtonGroup = (
  label: string,
  buttonGroupName: string,
  radioOptions: any,
  onRadioOptionChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: Array<RadioOption>) => void,
  readOnly: boolean,
) => (
  <Column>
    <span className="RadioGroupLabel">{label}</span>
    <RadioGroup
      disabled={readOnly}
      name={buttonGroupName}
      radioOptions={radioOptions}
      onRadioOptionsChange={onRadioOptionChange}
      className="RadioGroup"
    />
  </Column>
);

function SelectStatusComponent({
  readOnly,
}: CompleteVisitProps) {
  const { t } = useTranslation();
  const {
    statuses, selectedStatus, initialSubjectStatus: subjectStatus, setSelectedStatus, selectedChangeReason, setSelectedChangeReason, reasonRequiredStatuses, getStatusFromType,
  } = useContext(CompleteVisitContext);

  const statusTextNames = statuses.map((status) => ({
    name: status.statusText,
    value: status.status,
    checked: selectedStatus === status.status,
  }));

  const handleSubjectStatusChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const selectedOption = event.target.value;
    if (selectedOption) {
      const statusToSet = getStatusFromType(selectedOption as SubjectStatusType);
      if (statusToSet) {
        setSelectedStatus((previousStatus) => {
          if (previousStatus === selectedOption) return previousStatus;
          setSelectedChangeReason(undefined);
          return statusToSet.status;
        });
      }
    }
  };
  const handleChangeReasonSelectChange = (_event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, options: Array<RadioOption>) => {
    const { value, comment } = options.find((o) => o.checked) || {};
    let statusChangeReason: SubjectStatusChangeReason | undefined;
    const isReasonChanging = selectedChangeReason?.changeReason !== value;
    if (value) {
      statusChangeReason = { changeReason: value };
      if (!isReasonChanging && comment) {
        statusChangeReason = { ...statusChangeReason, changeReasonComment: comment };
      }
    }
    setSelectedChangeReason(statusChangeReason);
  };
  let changeReason;

  // selectedSubjectStatus is of type SubjectStatus; able to access type, reasonOptions
  const selectedSubjectStatus = getStatusFromType(selectedStatus);
  // don't show change reason when selected status is the same as current status
  const showChangeReason = selectedSubjectStatus?.status !== subjectStatus?.status;
  if (showChangeReason && selectedSubjectStatus?.type === 'FREE_ENTRY') {
    const reasonRequired = reasonRequiredStatuses.includes(selectedStatus);
    changeReason = (
      <Column className="ChangeReason">
        <span className="RadioGroupLabel">{t('Visit.Complete Visit.Change Reason')}</span>
        <CrioTextField
          disabled={readOnly}
          label={`${t('Visit.Complete Visit.Enter Change Reason')} ${reasonRequired ? t('Visit.Complete Visit.Reason Required') : ''}`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedChangeReason({ changeReason: e.target.value });
          }}
          value={selectedChangeReason?.changeReason || ''}
        />
      </Column>
    );
  } else if (showChangeReason && selectedSubjectStatus?.type === 'SINGLE_SELECT') {
    const radioOptions = selectedSubjectStatus.reasonOptions?.map(
      (reasonOption) => (
        {
          name: reasonOption.text,
          value: reasonOption.text,
          commentRequired: reasonOption.commentRequired,
          checked: selectedChangeReason?.changeReason === reasonOption.text,
          comment: selectedChangeReason?.changeReasonComment || '',
        }
      ),
    );
    changeReason = radioOptions && getLabelledRadioButtonGroup(t('Visit.Complete Visit.Change Reason'), 'change reason', radioOptions, handleChangeReasonSelectChange, readOnly);
  }

  return (
    <StyledSelectStatusComponent>
      {getLabelledRadioButtonGroup(t('Visit.Complete Visit.Subject Status'), 'subject status', statusTextNames, handleSubjectStatusChange, readOnly)}
      {selectedStatus && changeReason}
    </StyledSelectStatusComponent>
  );
}

export default function CompleteVisitPage({
  incompleteVisitProcedureNames = [],
  incompleteSubjectProcedureNames = [],
  readOnly,
}: CompleteVisitProps) {
  const { t } = useTranslation();
  const {
    visitComplete, completeVisit, skipProgressNote, setSkipProgressNote, progressNoteType, setProgressNoteType, progressNote, setProgressNote,
  } = useContext(CompleteVisitContext);
  const incompleteVisit = incompleteVisitProcedureNames.length > 0;

  return (
    <ThemeProvider theme={crioTheme}>
      {visitComplete
        ? <VisitCompletedPage partiallyCompleted={incompleteVisit} />
        : (
          <CompleteVisitComponent className="CenterPageBackground CenterPagePadding">
            {(incompleteVisit || incompleteSubjectProcedureNames.length > 0) && (
              <IncompleteVisitComponent className="Incomplete">
                <Row className="PageHeading">
                  <FontAwesomeIcon icon={faExclamationCircle as IconProp} size="lg" />
                  <h3>{t('Visit.Complete Visit.Incomplete procedures')}</h3>
                </Row>
                <Column className="IncompleteDetails">
                  <List sx={{ listStyle: 'disc' }} data-testid="incompleteProcedures">
                    {incompleteVisitProcedureNames.map((procedureName, index) => (
                      <ListItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        sx={{ display: 'list-item' }}
                      >
                        {procedureName}
                      </ListItem>
                    ))}
                  </List>

                  <IncompleteSubjectProcedures incompleteSubjectProcedureNames={incompleteSubjectProcedureNames || []} />
                  {incompleteVisit && (
                    <>
                      <div data-testid="ProcedureNote" className="ProcedureNote">
                        {t('Visit.Complete Visit.Procedure note')}
                        {' '}
                        <strong>{t('Visit.Complete Visit.Incomplete visit')}</strong>
                        :
                      </div>
                      <div>
                        <LabeledCheckbox
                          disabled={readOnly}
                          label={t('Visit.Complete Visit.Skip Progress Note')}
                          name="skip progress note"
                          onChange={() => {
                            setSkipProgressNote(!skipProgressNote);
                          }}
                        />
                      </div>
                      {!skipProgressNote && (
                        <Column className="EnterProgressNote">
                          <CrioTextField
                            disabled={readOnly}
                            placeholder={t('Visit.Complete Visit.Enter Progress Note')}
                            multiline
                            value={progressNote}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setProgressNote(e.target.value);
                            }}
                            minRows={2}
                          />
                          <RadioGroup
                            onRadioOptionsChange={(_event, options: Array<RadioOption>) => {
                              const { value } = options.find((o) => o.checked) || {};
                              if (value === ProgressNoteStatus.DRAFT || value === ProgressNoteStatus.PUBLISHED) {
                                setProgressNoteType(value);
                              }
                            }}
                            name="progress note type"
                            disabled={readOnly}
                            radioOptions={[
                              {
                                name: `${t('Procedure.Progress Note.Draft')}`,
                                value: ProgressNoteStatus.DRAFT,
                                checked: progressNoteType === ProgressNoteStatus.DRAFT,
                              },
                              {
                                name: `${t('Procedure.Progress Note.Published')}`,
                                value: ProgressNoteStatus.PUBLISHED,
                                checked: progressNoteType === ProgressNoteStatus.PUBLISHED,
                              }]}
                            row
                          />
                        </Column>
                      )}
                    </>
                  )}
                </Column>
              </IncompleteVisitComponent>
            )}

            <SelectStatusComponent
              readOnly={readOnly}
            />
            <Row className="FinishDivider">
              <CrioDivider />
              <FontAwesomeIcon icon={checkCircleRegular} size="xl" />
              <CrioDivider />
            </Row>
            {!readOnly
              && (
                <CrioButton onClick={() => completeVisit(incompleteVisit)}>
                  {t('Visit.Complete Visit.Header')}
                </CrioButton>
              )}
          </CompleteVisitComponent>
        )}
    </ThemeProvider>
  );
}

SelectStatusComponent.defaultProps = {
  incompleteVisitProcedureNames: [],
  incompleteSubjectProcedureNames: [],
};

CompleteVisitPage.defaultProps = {
  incompleteVisitProcedureNames: [],
  incompleteSubjectProcedureNames: [],
};
