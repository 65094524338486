import { useTranslation } from 'react-i18next';
import SaveErrorDialog, { SaveErrorDialogProps } from './SaveErrorDialog';

export interface SaveProgressNoteErrorDialogProps extends SaveErrorDialogProps {
  progressNote: string
}

// Component representing a Dialog to pop-up when a Progress Note fails to save
export default function SaveProgressNoteErrorDialog(saveProgessNoteErrorDialogProps: SaveProgressNoteErrorDialogProps) {
  const { progressNote } = saveProgessNoteErrorDialogProps;
  const { t } = useTranslation();

  return (
    <SaveErrorDialog {... saveProgessNoteErrorDialogProps}>
      {
        // If a Progress Note was given, print it out with a label
        progressNote
          && <p>{`${t('Procedure.Progress Note.Progress Note')}: ${progressNote}`}</p>
      }
    </SaveErrorDialog>
  );
}
