import styled from 'styled-components';
import {
  Fragment, useContext, useEffect, useMemo,
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import ProgressNote from './ProgressNote';
import MarkNotDone from './MarkNotDone';
import MissingAnswersResolutionType from '../../../../enums/MissingAnswersResolutionType';
import SaveAlertContext from '../../../../context/SaveAlertContext';
import { SimplifiedRecords } from '../../../../context/types';
import { SyntheticQuestionType } from '../../../../enums';

export interface MissingAnswersProps {
  missingAnswers: SimplifiedRecords,
  closeHandler?: Function,
  completeHandler?: Function,
  multipleAlerts: boolean,
  nothingEverAnswered: boolean,
}

const StyledMissingAnswers = styled.div`
font-family: 'Poppins Light', 'Poppins', 'Poppins', sans-serif;
width: 600px;

.skipButton {
  margin-left: 15px;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.palette.linkText.main};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.selectActionDropdown {
  border-radius: 0;
  border-color: #e6eaf2;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  width: 200px;
  padding: 0 !important;
  margin-bottom: 0 !important;
  & div {
    color: #999;
    padding: 8px;
  }
}

.darken {
  background-color: #fafbfc;
}

.form {
  display: block;
}

.copy {
  font-weight: 300;
  font-size: 14px;
  color: #333333;
  padding-bottom: 5px;
}

.missingQuestionsList {
  list-style-position: inside;
  .missingQuestionText {
    padding: 0;
    margin-bottom: 0;
    line-height: 30px;
  }
}

.recordHeading {
  margin-top: 15px;
  font-weight: 400;
}
`;

function MissingAnswers({
  missingAnswers = [],
  closeHandler = () => { },
  completeHandler = () => { },
  multipleAlerts = false,
  nothingEverAnswered,
}: MissingAnswersProps) {
  const { t } = useTranslation();
  const { missingAnswersResolutionType, setMissingAnswersResolutionType } = useContext(SaveAlertContext);

  const ResolutionTypeText = useMemo(() => ({
    [MissingAnswersResolutionType.ENTERED_PROGRESS_NOTE]: t('Procedure.Dialog.Missing Answers.Resolutions.Enter Progress Note'),
    [MissingAnswersResolutionType.MARKED_NOT_DONE]: t('Procedure.Dialog.Missing Answers.Resolutions.Mark Not Done'),
    [MissingAnswersResolutionType.SKIPPED]: t('Procedure.Dialog.Missing Answers.Resolutions.Select Action'),
  }), []);

  const handleSelectResolutionType = (event: SelectChangeEvent<MissingAnswersResolutionType>) => {
    const {
      target: { value },
    } = event;
    setMissingAnswersResolutionType(value as MissingAnswersResolutionType);
  };

  const handleSkip = () => {
    // The resolution type only gets set on the next render, so provide "skip" to the completion handler too
    completeHandler({
      resolved: false,
      skipMissingAnswers: true,
    });
  };

  useEffect(() => {
    if (nothingEverAnswered) {
      setMissingAnswersResolutionType(MissingAnswersResolutionType.MARKED_NOT_DONE);
    }
  }, [nothingEverAnswered]);

  return (
    <StyledMissingAnswers>
      {!nothingEverAnswered && (
        <>
          <div className="copy">
            {t('Procedure.Dialog.Missing Answers.Copy')}
          </div>
          {/* Print out the list of missing questions for each record */}
          {
            missingAnswers.map(({ recordId, recordIndex, questions = [] }) => (
              <Fragment key={`${recordId}:${recordIndex}`}>
                {!!recordIndex && (<div className="recordHeading">{`Record ${recordIndex}`}</div>)}
                <ul className="missingQuestionsList">
                  {questions.map(({ type, questionText, questionId }) => {
                    // We now display no_entry questions as "Are there records?" regardless of the custom text
                    const text = type === SyntheticQuestionType.NO_ENTRY ? t('Procedure.MultiRecord.Are there records') : questionText;
                    return (<li className="copy missingQuestionText" key={`${recordId}-${questionId}`}>{text}</li>);
                  })}
                </ul>
              </Fragment>
            ))
          }
        </>
      )}

      {nothingEverAnswered && (
        <div className="copy" style={{ paddingBottom: 0, marginBottom: 0 }}>
          {t('Procedure.Dialog.Missing Answers.Nothing Ever Answered Suggestion')}
          <b>{t('Procedure.Dialog.Mark Not Done.NOT DONE')}</b>
        </div>
      )}

      {!nothingEverAnswered && (
        <>
          <div className="copy">
            {t('Procedure.Dialog.Missing Answers.Suggestion')}
          </div>
          <FormControl className="form">
            <Select
              value={missingAnswersResolutionType || ''}
              onChange={handleSelectResolutionType}
              className={`copy selectActionDropdown ${!multipleAlerts ? 'darken' : ''}`}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>{t('Procedure.Dialog.Missing Answers.Resolutions.Select Action')}</em>;
                }

                return ResolutionTypeText[selected];
              }}

            >
              <MenuItem disabled value="" style={{ fontSize: '14px' }}>
                <em>{ResolutionTypeText[MissingAnswersResolutionType.SKIPPED]}</em>
              </MenuItem>
              {[MissingAnswersResolutionType.MARKED_NOT_DONE, MissingAnswersResolutionType.ENTERED_PROGRESS_NOTE].map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                  style={{ fontSize: '14px' }}
                >
                  {ResolutionTypeText[type]}
                </MenuItem>
              ))}
            </Select>
            <span
              className="skipButton"
              role="button"
              tabIndex={0}
              onKeyDown={handleSkip}
              onClick={handleSkip}
            >
              {multipleAlerts ? 'Skip' : 'Skip & Continue'}
            </span>
          </FormControl>
        </>
      )}

      {(missingAnswersResolutionType === MissingAnswersResolutionType.ENTERED_PROGRESS_NOTE) && (
        <ProgressNote
          completeHandler={completeHandler}
          closeHandler={closeHandler}
          multipleAlerts={multipleAlerts}
        />
      )}

      {(missingAnswersResolutionType === MissingAnswersResolutionType.MARKED_NOT_DONE) && (
        <MarkNotDone
          missingAnswers={missingAnswers}
          completeHandler={completeHandler}
          closeHandler={closeHandler}
          multipleAlerts={multipleAlerts}
          nothingEverAnswered={nothingEverAnswered}
        />
      )}

    </StyledMissingAnswers>
  );
}

MissingAnswers.defaultProps = {
  closeHandler: () => { },
  completeHandler: () => { },
};

export default MissingAnswers;
