import { DialogProps } from '@mui/material';
import EsourceDialog from './EsourceDialog';

export interface EsourceInfoDialogProps extends DialogProps {
  open: boolean,
  closeHandler: Function,
  alertTitle: string
}

// Component representing a Dialog to pop-up when some information needs to be relayed in big, scary letters
export default function EsourceInfoDialog({
  open, closeHandler = () => {}, alertTitle, children,
}: EsourceInfoDialogProps) {
  // If the Dialog should not appear, hide it
  if (!open) return null;

  return (
    <EsourceDialog
      testId="infoDialog"
      alertTitle={alertTitle}
      type="Info"
      open={open}
      closeHandler={() => closeHandler()}
    >
      {children}
    </EsourceDialog>
  );
}
