import { CircularProgress, styled } from '@mui/material';

const StyledSpinner = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    margin-top: 40px;
    align-self: center;
    color: ${(props) => props.theme.palette.grey[500]};
  }
`;
export default function SmallLoadingSpinner() {
  return <StyledSpinner data-testid="smallLoadingSpinner" />;
}
