import { AnswerType, CarryForwardType, ProcedureStatus } from '../enums';
import { DataPoint, QuestionAnswer } from '../types';

interface isVisitDataPointProps {
  carryForwardType: CarryForwardType,
  dataPoint: DataPoint,
  visitId?: string,
}
const isVisitDataPoint = ({ carryForwardType, dataPoint, visitId }: isVisitDataPointProps) => ((carryForwardType !== CarryForwardType.PERMANENT) || dataPoint.answer_type === AnswerType.HAS_CHANGES) && visitId;

const isDataPointAnswered = (dataPoint: DataPoint): boolean => {
  const { answer } = dataPoint || {};
  return !(Number.isNaN(answer) || (!answer && answer !== 0));
};
// Does this DataPoint have a procedure status of skipped?
// in other words, does this datapoint correspond to a skipped procedure?
const isDataPointSkipped = ({ procedure_status }: DataPoint): boolean => procedure_status === ProcedureStatus.SKIPPED;

const isDataPointDisabled = ({ is_disabled }: DataPoint): boolean => is_disabled || false;
const isAnswerUnanswered = (answer: QuestionAnswer): boolean => answer === null
  || answer === ''
  || answer === undefined
  || (answer instanceof Array && answer.length === 0);

export {
  isVisitDataPoint,
  isDataPointAnswered,
  isDataPointSkipped,
  isDataPointDisabled,
  isAnswerUnanswered,
};
