import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import firebase from 'firebase/compat/app';
import { useState } from 'react';
import Row from '../Row';
import { Column } from '../index';
import { SubjectData, SubjectHeaderSiblingStyleProps } from '../../types';
import InfoText from '../InfoText';
import SubjectDetailsField from './SubjectDetailsField';
import SubjectFieldName from '../../enums/SubjectFieldName';
import { updateSubject } from '../../api/esourceService';
import { ChangeReason } from '../../enums';

const StyledSubjectDetails = styled(Column)<SubjectHeaderSiblingStyleProps>`
  width: 100%;
  max-width: calc(100% - 120px);
  overflow-x: scroll;
  min-height: calc(100vh - ${(props) => props.subjectHeaderHeight}px);
`;

const SectionHeader = styled('div')`
  color: ${(props) => props.theme.palette.grey[900]};
  font-size: 1.125em;
  font-weight: 400;
  margin: 20px 0;
`;

const Fields = styled(Column)`
  gap: 10px;
  font-size: 0.9em;
  color: ${(props) => props.theme.palette.grey[700]};
  margin-top: 10px;
`;

export type SubjectDetailsPageProps = {
  subjectHeaderHeight: number,
  subjectData: SubjectData,
  studyId: string;
  visitId?: string;
};

export default function SubjectDetailsPage({
  subjectHeaderHeight, subjectData, studyId, visitId,
}: SubjectDetailsPageProps) {
  const { t } = useTranslation();
  const [subjectDataState, setSubjectDataState] = useState(subjectData);

  const handleSave = (fieldName: SubjectFieldName, fieldString?: string | null, fieldLong?: number | null, changeReason?: ChangeReason, changeReasonDetails?: string) => {
    const userId = firebase.auth().currentUser?.uid;
    if (subjectData?.subjectId && userId) {
      const request = {
        subjectId: subjectData.subjectId,
        userId,
        fieldName,
        fieldString,
        fieldLong,
        changeReason,
        changeReasonDetails,
      };
      return updateSubject(studyId, subjectData.subjectId, request, visitId).then((res) => setSubjectDataState(res));
    }
    return Promise.resolve();
  };

  return (
    <Row className="CenterPageBackground">
      <StyledSubjectDetails className="CenterPagePadding" subjectHeaderHeight={subjectHeaderHeight}>
        <InfoText>{t('Visit.Subject Details.Info Text')}</InfoText>
        <SectionHeader>{t('Visit.Subject Details.Subject Information')}</SectionHeader>
        <Fields>
          <SubjectDetailsField fieldName={SubjectFieldName.FIRST_NAME} fieldString={subjectDataState.firstName} requiresChangeReason onSave={handleSave} />
          <SubjectDetailsField fieldName={SubjectFieldName.MIDDLE_NAME} fieldString={subjectDataState.middleName} requiresChangeReason onSave={handleSave} />
          <SubjectDetailsField fieldName={SubjectFieldName.LAST_NAME} fieldString={subjectDataState.lastName} requiresChangeReason onSave={handleSave} />
          {/* <SubjectDetailsField fieldName={SubjectFieldName.BIRTH_DATE} fieldLong={subjectDataState.birthDate} requiresChangeReason onSave={handleSave} /> */}
          <SubjectDetailsField fieldName={SubjectFieldName.EMAIL} fieldString={subjectDataState.email} onSave={handleSave} />
          <SubjectDetailsField fieldName={SubjectFieldName.MOBILE_PHONE} fieldString={subjectDataState.mobilePhoneNumber} onSave={handleSave} />
        </Fields>
      </StyledSubjectDetails>
    </Row>
  );
}
