import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import spinner from '../assets/spinner.gif';

const LoadingSpinnerComponent = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  .modal-content {
    border-radius: 10px;
    border: 2px solid #e4e4e4;
    background-color: ${(props) => props.theme.palette.primary.contrastText};
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    color: ${(props) => props.theme.palette.text.primary};
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
  }

  p {
    margin: 10px 18px 10px 18px;
  }
`;

export default function LoadingSpinner() {
  const { t } = useTranslation();
  return (
    <LoadingSpinnerComponent>
      <div className="modal-content">
        <img alt="Loading..." src={spinner} height="42px" width="42px" />
        <p>{t('Loading.Message')}</p>
      </div>
    </LoadingSpinnerComponent>
  );
}
