import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Row, Column } from '../..';
import { RecordQuestion } from '../../../types';
import { parseAnswerCommentJson } from '../../../util/answerComments';

const NotDoneQuestionComponent = styled(Row)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 1rem;

  .NotDoneUndo {
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.linkText.main};
    cursor: pointer;
    padding-left: 14px;
    &:hover {
      color: ${(props) => props.theme.palette.linkText.dark};
      text-decoration: underline;
    }
  }
`;

/**
 * Component for a "not done" Question
 * @param dataPoint        DataPoint to be used to populate the Question
 * @param onClickFunction  Function to be called on click (to swap out the "not done" Question w/ a normal one when undo is clicked)
 */
export default function NotDoneQuestion(props: RecordQuestion) {
  const { t } = useTranslation();
  const {
    variableName, questionId, dataPoint, recordId, handleAnswerChange, readOnly,
  } = props;

  /**
   * Handle the undo (i.e.: change the answer to blank)
   */
  const handleUndo = () => {
    const newDataPoint = { ...dataPoint, answer: undefined, answer_comment: undefined };
    handleAnswerChange({
      variableName, questionId, newDataPoint, recordId,
    });
  };

  return (
    <NotDoneQuestionComponent>
      <Column>
        <Typography variant="body2">
          {t('Enum.QuestionFixedAnswerType.NOT_DONE')}
        </Typography>
        <Typography variant="body2">
          <i>{parseAnswerCommentJson(dataPoint?.answer_comment || '{}', dataPoint?.answer)}</i>
        </Typography>
      </Column>
      {!readOnly
        && (
        <Column>
          <div
            role="button"
            tabIndex={0}
            className="NotDoneUndo"
            onClick={handleUndo}
            onKeyDown={handleUndo}
          >
            {t('Common.Undo')}
          </div>
        </Column>
        )}
    </NotDoneQuestionComponent>
  );
}
