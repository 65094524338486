enum AnswerType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BULLET = 'BULLET',
  NO_ENTRY = 'NO_ENTRY',
  HAS_CHANGES = 'HAS_CHANGES',
  DATE_TIME = 'DATE_TIME',
  ICF_NOT_REQUIRED = 'ICF_NOT_REQUIRED',
  PROCEDURE_SKIPPED = 'PROCEDURE_SKIPPED',
  ANCILLARY = 'ANCILLARY',
}

const isSyntheticAnswerType = (type: any): boolean => !!type && (
  type === AnswerType.NO_ENTRY
    || type === AnswerType.HAS_CHANGES
    || type === AnswerType.ANCILLARY
);

export { AnswerType, isSyntheticAnswerType };
