import { DateTime, Interval } from 'luxon';
import { extractDateTime } from '@crio/crio-react-component/dist/cjs/components/DateTime/CrioCalendarUtils';
import {
  convertSqliteForNodeRules, extractVariableNamesFromCondition, functions, parseSqliteStatement,
} from './custom-sqlite-parser';
import { Calculation, QuestionAnswer } from '../../types';
import { QuestionFixedAnswerType, RoundingMode } from '../../enums';
import VariableNameAnswers from '../../context/types/VariableNameAnswers';

/**
 * Rounds without a library according to the js-only specification here: http://jsfiddle.net/warby_/puk4z5sf/
 * @param value the original number to round
 * @param decimalPlaces the number of decimal places to consider
 * @param mode the rounding mode
 */
export function roundValue(value: number, decimalPlaces: number, mode: RoundingMode): number | null {
  if (value === null) return null;
  switch (mode) {
    case RoundingMode.FLOOR:
      return parseInt(String(value * 10 ** decimalPlaces), 10) === value * 10 ** decimalPlaces
        ? value
        : Math.round(value * 10 ** decimalPlaces - 0.5) / 10 ** decimalPlaces;
    case RoundingMode.CEILING:
      return parseInt(String(value * 10 ** decimalPlaces), 10) === value * 10 ** decimalPlaces
        ? value
        : Math.floor(value * 10 ** decimalPlaces + 1) / 10 ** decimalPlaces;
    case RoundingMode.DOWN:
      // round-towards-zero
      return (Math.trunc(value * 10 ** decimalPlaces) / 10 ** decimalPlaces) + 0; // (+ 0 avoids the -0 result)
    case RoundingMode.UP:
      // round-away-from-zero
      return parseInt(String(value * 10 ** decimalPlaces), 10) !== value * 10 ** decimalPlaces && value * 10 ** decimalPlaces > 0
        ? Math.floor(value * 10 ** decimalPlaces + 1) / 10 ** decimalPlaces
        : Math.floor(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
    case RoundingMode.HALF_DOWN:
      // rounding-half-down-asymmetric
      return (value * 10 ** decimalPlaces) % 0.5 === 0
        ? Math.floor(value * 10 ** decimalPlaces) / 10 ** decimalPlaces
        : Math.round(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
    case RoundingMode.HALF_UP:
    default:
      // rounding-half-up-asymmetric
      return Math.round(value * 10 ** decimalPlaces) / 10 ** decimalPlaces;
  }
}

/**
 * Convert a value to a number if possible
 * @param value
 * @returns
 */
export function _sanitizeNumber(value: any): any {
  return Number.isNaN(Number(value)) ? value : Number(value);
}

/**
 * If the fact contains numbers as string, force those back to numbers
 * @param fact The fact to sanitize
 * @returns A deep copy of the original fact, with strings replaced as numbers if possible
 */
export function _sanitizeNumbersInFact(fact: { [index: string]: QuestionAnswer }): { [index: string]: QuestionAnswer } {
  const sanitizedFact = { ...fact };
  Object.keys(sanitizedFact).forEach((key: string) => {
    const value = sanitizedFact[key];
    sanitizedFact[key] = _sanitizeNumber(value);
  });
  return sanitizedFact;
}

/**
 * Calculation functions migrated from site-app
 */
export const calculateFunctions: { [index: string]: Function } = {
  Sum: (fields: Array<number>): number => {
    if (!fields) return 0;
    // eslint-disable-next-line arrow-body-style
    return fields.reduce((sum: number, currentField) => {
      // Protect against strings getting in as the currentField or else js will coerce to string
      return sum + _sanitizeNumber(currentField);
    }, 0);
  },
  Subtract: (fields: Array<number>): number => {
    if (!fields || fields.length < 1) return 0;
    return fields.reduce((total, currentField) => total - currentField);
  },
  Divide: (fields: Array<number>): number => {
    if (!fields || fields.length < 2) return 0;
    return fields[0] / fields[1];
  },
  Average: (fields: Array<number>): number => {
    if (fields.length === 0) return 0;
    return calculateFunctions.Divide([calculateFunctions.Sum(fields), fields.length]);
  },
  YearsBetweenDates: (fields: Array<DateTime | 'ONGOING'>): number => {
    if (!fields || fields.length !== 2) return 0;
    const updatedFields: Array<DateTime> = [];
    fields.forEach((field) => {
      if (typeof field === 'string') updatedFields.push(extractDateTime(field));
      else updatedFields.push(field);
    });
    // Luxon doesn't do negative intervals, so this is a workaround: https://github.com/moment/luxon/issues/746
    const offset = Interval.fromDateTimes(updatedFields[0], updatedFields[1]).length('year');
    return Number.isNaN(offset) ? -Interval.fromDateTimes(updatedFields[1], updatedFields[0]).length('year') : offset;
  },
  Age: (fields: Array<DateTime>): number => {
    if (!fields || fields.length !== 1) return 0;
    return Math.floor(calculateFunctions.YearsBetweenDates([fields[0], DateTime.now()]));
  },
  // FIXME: do not change this typo -> it's persisting in the database like this so it needs to match
  BMICentimetersKillograms: (fields: Array<number>): number => {
    if (!fields || fields.length !== 2) return 0;
    const height = fields[0];
    const weight = fields[1];
    const denom = (calculateFunctions.Divide([height, 100])) ** 2;
    if (denom === 0) return 0;
    return weight / denom;
  },
  BMIInchesPounds: (fields: Array<number>): number => {
    if (!fields || fields.length !== 2) return 0;
    const height = fields[0];
    const weight = fields[1];
    const denom = height ** 2;
    if (denom === 0) return 0;
    return (weight / denom) * 703.06957964;
  },
  InchesToCentimeters: (fields: Array<number>): number => {
    if (!fields || fields.length !== 1) return 0;
    return fields[0] * 2.54;
  },
  TempFahrenheitToCelsius: (fields: Array<number>): number => {
    if (!fields || fields.length !== 1) return 0;
    return (fields[0] - 32) * (5 / 9);
  },
  // FIXME: do not change this typo -> it's persisting in the database like this so it needs to match
  WeightPoundsToKillograms: (fields: Array<number>): number => {
    if (!fields || fields.length !== 1) return 0;
    return fields[0] * 0.45359237;
  },
  Formula: (fields: Array<string>, fact: { [index: string]: QuestionAnswer }): number => {
    if (!fields || fields.length !== 1) return 0;
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    return Function('fact', 'functions', `return ${convertSqliteForNodeRules(fields[0])}`)(_sanitizeNumbersInFact(fact), functions);
  },
};

/**
 *
 * @param answer Determines if an answer is truly answered
 * @returns boolean
 */
export function isAnswerPopulated(answer: QuestionAnswer) {
  return answer !== null && answer !== undefined && answer !== QuestionFixedAnswerType.NOT_DONE;
}

/**
 * Checks to see that for a given fact, if all of the variable names required are present (i.e. not null)
 * Fulfills similar function to ProcedureLogic's {@link ensureAllQuestionFieldsPopulated}
 * @param requiredVariableNames
 * @param fact
 */
function verifyAllQuestionFieldsPopulated(requiredVariableNames: Array<string>, fact: { [index: string]: QuestionAnswer }) {
  return requiredVariableNames
    .every((variableName) => isAnswerPopulated(fact[variableName]));
}

/**
 * Calculates a value according to the provided props, using the provided reference answers.
 * @param calculationRule the string rule. Matches a format like Formula(param list)
 * @param roundingMode the rounding mode (i.e. RoundingMode.DOWN)
 * @param numberOfDecimalPlaces the number of decimal places allowed
 * @param fact the available answers to use as part of the calculation
 */
export function calculateValue(
  { calculationRule, roundingMode, numberOfDecimalPlaces }: Calculation,
  fact: VariableNameAnswers,
): QuestionAnswer {
  for (const functionName of Object.keys(calculateFunctions)) {
    const regexMatch = new RegExp(`${functionName}\\((.*)\\)`, 'g');
    const params = (regexMatch.exec(calculationRule) || [null, null])[1];
    // eslint-disable-next-line no-continue
    if (!params) continue;

    if (functionName === 'Formula') {
      const variableNameList = extractVariableNamesFromCondition(parseSqliteStatement(params));
      if (!verifyAllQuestionFieldsPopulated(variableNameList, fact)) return null;
      return roundValue(calculateFunctions.Formula([params], fact), numberOfDecimalPlaces, roundingMode);
    }
    const calculationFunction: Function = calculateFunctions[functionName];
    if (calculationFunction) {
      const variableNameList = params.split(/\s*,\s*/g);
      if (!verifyAllQuestionFieldsPopulated(variableNameList, fact)) return null;
      return roundValue(
        calculationFunction(
          variableNameList.map((param) => fact[param]),
          fact,
        ),
        numberOfDecimalPlaces,
        roundingMode,
      );
    }
  }
  return null;
}
