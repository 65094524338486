import { Box } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import CrioSwitch from './CrioSwitch';

const CrioToggleComponent = styled('div')`
    display: flex;
    align-items: center;
`;

const ToggleDescription = styled(Box)`
  font-size: 0.75rem;
  color: ${(props) => props.theme.palette.text.secondary};
  margin-left: 10px;
`;

interface StrongTextProps {
  disabled?: boolean,
}

const StrongText = styled(Box) <StrongTextProps>`
    font-family: "Poppins-Regular", "Poppins", sans-serif;
    font-weight: 400;
    font-size:.875rem;
    margin-right: 10px;
    color: ${(props) => (props.disabled ? props.theme.palette.grey[700] : props.theme.palette.grey[900])};
`;

type CrioToggleProps = {
  onToggle: (arg: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  description?: string;
};

function CrioToggle({
  onToggle, checked, label = '', description = '', disabled,
}: CrioToggleProps) {
  return (
    <CrioToggleComponent>
      <ThemeProvider theme={crioTheme}>
        <StrongText disabled={disabled}>{`${(label)}:`}</StrongText>
        <CrioSwitch onToggle={onToggle} checked={checked} disabled={disabled} />
        <ToggleDescription>{description}</ToggleDescription>
      </ThemeProvider>
    </CrioToggleComponent>
  );
}

CrioToggle.defaultProps = {
  disabled: false,
  label: '',
  description: '',
};

export default CrioToggle;
