import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Accordion, Dialog, DialogActions, DialogTitle, IconButton, AccordionDetails, AccordionSummary, DialogContent, ThemeProvider,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useContext, useMemo, useState } from 'react';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import MissingAnswers from './MissingAnswers';
import MissingAnswersResolutionType from '../../../../enums/MissingAnswersResolutionType';
import SaveAlertContext from '../../../../context/SaveAlertContext';
import ProgressNoteContext from '../../../../context/ProgressNoteContext';
import ChangeReason from './ChangedAnswers';
import { HandleSaveAndContinueProps } from './types';
import { SimplifiedRecords } from '../../../../context/types';
import ProcedureContext from '../../../../context/ProcedureContext';

export interface SaveProcedureAlertsDialogProps {
  successCallback?: Function,
  closeHandler?: () => void,
  isOpen: boolean,
  deletingRecord: boolean,
  missingAnswers: SimplifiedRecords,
  changedAnswers: SimplifiedRecords,
  nothingEverAnswered: boolean,
}

const StyledCrioDialog = styled(Dialog)`
  font-family: 'Poppins Light', 'Poppins', 'Poppins', sans-serif;

  .paddingContainer {
    padding: 20px;
  }

  .completedIcon {
    color: green !important;
  }

  .accordion {
    width: calc(100% - 4px);
    margin-left:2px;
    &:hover {
      background-color: #eee;
    }
  }

  .accordionIcon {
    margin-top: 8px;
    margin-right: 5px;
  }

  .expandedAccordionHeader {
    font-weight: 400;
  }

  .expandedAccordion {
    background-color: #f8f8f8;
    &:hover {
      background-color: #f8f8f8;
    }
  }

  #missingAnswersIcon {
    color: ${(props) => props.theme.palette.linkText.light};
  }

  #changeReasonIcon {
    color: ${(props) => props.theme.palette.error.main};
  }

  .accordionSummary {
    height: 48px;
    line-height:48px;
  }

  .accordionDetails {
    margin-left: 30px;
  }

  .content {
    padding: 0;
    padding-bottom: 5px;
  }

  .header {
    font-family: 'Poppins-Medium', 'Poppins Medium', 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #0D2B3A;
    margin-bottom: 25px;
  }

  .optionalHeader {
    font-size: 14px;
    font-weight:200;
    color: #888;
    margin-left: 15px;
    vertical-align: middle;
  }

  .optionalAccordionHeader {
    margin-left: auto;
    padding-right: 15px;
    color: #aaa;
  }

  .copy {
    font-family: "Poppins Light", "Poppins", sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    margin: 15px 10px 0 10px;
    .continueButton {

      cursor: pointer;
      margin-right: 10px;
      &:hover {
        color: #C04040;
      }
    }
  }

  .dialogActions {
    padding: 0 20px 20px 20px;
    background-color: #f2f2f2;
    border-top: 2px solid #e4e4e4;
    justify-content: flex-start;
  }
`;

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0, paddingBottom: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.defaultProps = {
  children: <div />,
};

function AlertsDialog({
  successCallback = () => { },
  closeHandler = () => { },
  isOpen,
  deletingRecord,
  missingAnswers,
  changedAnswers,
  nothingEverAnswered,
}: SaveProcedureAlertsDialogProps) {
  const { t } = useTranslation();
  const multipleAlerts = missingAnswers.length > 0 && changedAnswers.length > 0;
  const header = useMemo(() => {
    if (multipleAlerts) {
      return (
        <div className="header">
          {t('Procedure.Dialog.Save Alerts.Attention Required')}
        </div>
      );
    }

    if (missingAnswers.length > 0) {
      return (
        <div className="header">
          {t('Procedure.Dialog.Missing Answers.Incomplete Questions')}
          {nothingEverAnswered && (<span>{t('Procedure.Dialog.Missing Answers.Incomplete Questions Mark Not Done Suffix')}</span>)}
          {!nothingEverAnswered && (<span className="optionalHeader">optional</span>)}
        </div>
      );
    }

    if (changedAnswers.length > 0) {
      return (
        <div className="header">
          {t('Procedure.Dialog.Change Answers.Answers Were Modified')}
        </div>
      );
    }

    return <div />;
  }, [missingAnswers, changedAnswers]);

  const { saveProcedure, isSaving } = useContext(ProcedureContext);
  const { handleCreateProgressNote } = useContext(ProgressNoteContext);
  const {
    changeReason,
    changeReasonCompleted,
    changeReasonDetails,
    missingAnswersCompleted,
    missingAnswersResolutionType,
    notDoneComment,
    notDoneQueuedChanges,
    progressNoteContent,
    progressNoteStatus,
    setChangeReasonCompleted,
    setMissingAnswersCompleted,
    setMissingAnswersResolutionType,
    validateChangeReason,
    validateMissingAnswers,
  } = useContext(SaveAlertContext);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleExpand = (panel: string) => {
    setExpanded((currentlyExpanded) => {
      const collapsingPanel = currentlyExpanded === panel;

      if (currentlyExpanded === 'missingAnswersPanel') {
        if (missingAnswersResolutionType === MissingAnswersResolutionType.SKIPPED) {
          setMissingAnswersCompleted(false);
        } else {
          setMissingAnswersCompleted(validateMissingAnswers());
        }
      }
      if (currentlyExpanded === 'changedAnswersPanel') {
        setChangeReasonCompleted(validateChangeReason());
      }

      return collapsingPanel ? false : panel;
    });
  };

  const handleMissingAnswersClose = () => {
    setExpanded(false);
  };

  const handleChangedAnswersClose = () => {
    setExpanded(false);
  };

  const handleCompleteChangedAnswers = () => {
    const valid = validateChangeReason();
    setChangeReasonCompleted(valid);
    if (!valid) {
      return;
    }

    setExpanded(false);
  };

  const handleCompleteMissingAnswers = ({ skipMissingAnswers }: HandleSaveAndContinueProps = {}) => {
    const valid = skipMissingAnswers || validateMissingAnswers();
    setMissingAnswersCompleted(!skipMissingAnswers && valid);
    if (!valid || valid === 'ERROR') {
      return;
    }

    if (skipMissingAnswers) {
      setMissingAnswersResolutionType(MissingAnswersResolutionType.SKIPPED);
    }

    setExpanded(false);
  };

  const handleConfirmAllChanges = async ({ skipMissingAnswers }: HandleSaveAndContinueProps = {}) => {
    let saveProgressNote: Function | undefined;

    const changeReasonValid = validateChangeReason();
    const missingAnswersValid = validateMissingAnswers();

    setChangeReasonCompleted(changeReasonValid);
    setMissingAnswersCompleted(missingAnswersValid);

    if (changedAnswers.length > 0 && !changeReasonValid) {
      setExpanded('changedAnswersPanel');
      return;
    }

    if (missingAnswers.length > 0 && !skipMissingAnswers && missingAnswersValid === 'ERROR') {
      setExpanded('missingAnswersPanel');
      return;
    }

    if (isSaving) return;

    if (!skipMissingAnswers && missingAnswersResolutionType === MissingAnswersResolutionType.ENTERED_PROGRESS_NOTE) {
      saveProgressNote = async () => handleCreateProgressNote(progressNoteStatus!, progressNoteContent, false);
    }

    await saveProcedure({
      changeReason,
      changeReasonDetails,
      changedAnswers,
      notDoneComment: !skipMissingAnswers && missingAnswersResolutionType === MissingAnswersResolutionType.MARKED_NOT_DONE ? notDoneComment : '',
      notDoneQueuedChanges: !skipMissingAnswers && missingAnswersResolutionType === MissingAnswersResolutionType.MARKED_NOT_DONE ? notDoneQueuedChanges : {},
      deletingRecord,
      skipMissingQuestionsCheck: true,
      skipChangedAnswersCheck: true,
    });

    if (saveProgressNote) {
      await saveProgressNote();
    }

    closeHandler();

    await successCallback();
  };

  return (
    <ThemeProvider theme={crioTheme}>
      <StyledCrioDialog
        maxWidth="lg"
        open={isOpen}
        onClose={() => {
          closeHandler();
          setExpanded(false);
        }}
        fullWidth={false}
        disablePortal
      >
        <div className="paddingContainer">
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closeHandler}>
            {header}
          </BootstrapDialogTitle>

          {multipleAlerts && (
            <DialogContent className="content">
              <div className="copy">
                {t('Procedure.Dialog.Save Alerts.There were some issues we found below')}
              </div>

              <>
                <Accordion className={`accordion ${expanded === 'changedAnswersPanel' ? 'expandedAccordion' : ''}`} expanded={expanded === 'changedAnswersPanel'}>
                  <AccordionSummary
                    className="accordionSummary"
                    onClick={() => handleExpand('changedAnswersPanel')}
                    expandIcon={<ExpandMore />}
                  >
                    {changeReasonCompleted && (
                      <CheckCircleIcon className="accordionIcon completedIcon" id="changeReasonIcon" />
                    )}
                    {!changeReasonCompleted && (
                      <WarningIcon className="accordionIcon" id="changeReasonIcon" />
                    )}

                    <span className={expanded === 'changedAnswersPanel' ? 'expandedAccordionHeader' : ''}>
                      {t('Procedure.Dialog.Change Answers.Answers Were Modified')}
                    </span>
                  </AccordionSummary>
                  <AccordionDetails className="accordionDetails">
                    <ChangeReason
                      changedAnswers={changedAnswers}
                      completeHandler={handleCompleteChangedAnswers}
                      closeHandler={handleChangedAnswersClose}
                      deletingRecord={deletingRecord}
                      multipleAlerts={multipleAlerts}
                    />
                  </AccordionDetails>
                </Accordion>

                <Accordion className={`accordion ${expanded === 'missingAnswersPanel' ? 'expandedAccordion' : ''}`} expanded={expanded === 'missingAnswersPanel'}>
                  <AccordionSummary
                    onClick={() => handleExpand('missingAnswersPanel')}
                    className="accordionSummary"
                    expandIcon={<ExpandMore />}
                  >

                    {missingAnswersCompleted === 'ERROR' && (
                      <WarningIcon className="accordionIcon" id="changeReasonIcon" />
                    )}

                    {missingAnswersCompleted === true && (
                      <CheckCircleIcon className="accordionIcon completedIcon" id="missingAnswersIcon" />
                    )}
                    {!missingAnswersCompleted && (
                      <InfoIcon className="accordionIcon" id="missingAnswersIcon" />
                    )}

                    <span className={expanded === 'missingAnswersPanel' ? 'expandedAccordionHeader' : ''}>
                      {t('Procedure.Dialog.Missing Answers.Incomplete Questions')}
                    </span>

                    <div className="optionalAccordionHeader">
                      {t('Procedure.Dialog.Save Alerts.optional')}
                    </div>

                  </AccordionSummary>
                  <AccordionDetails className="accordionDetails">
                    <MissingAnswers
                      missingAnswers={missingAnswers}
                      completeHandler={handleCompleteMissingAnswers}
                      closeHandler={handleMissingAnswersClose}
                      multipleAlerts={multipleAlerts}
                      nothingEverAnswered={nothingEverAnswered}
                    />
                  </AccordionDetails>
                </Accordion>
              </>
            </DialogContent>
          )}

          {(!multipleAlerts && missingAnswers.length > 0) && (
            <MissingAnswers
              missingAnswers={missingAnswers}
              completeHandler={handleConfirmAllChanges}
              closeHandler={closeHandler}
              multipleAlerts={multipleAlerts}
              nothingEverAnswered={nothingEverAnswered}
            />
          )}

          {(!multipleAlerts && changedAnswers.length > 0) && (
            <ChangeReason
              changedAnswers={changedAnswers}
              completeHandler={handleConfirmAllChanges}
              closeHandler={closeHandler}
              deletingRecord={deletingRecord}
              multipleAlerts={multipleAlerts}
            />
          )}
        </div>

        {multipleAlerts && (
          <DialogActions className="dialogActions">
            <div className="buttonsContainer">
              <div data-qa-id="continue" className="continueButton">
                <CrioButton
                  theme="Primary"
                  onClick={handleConfirmAllChanges}
                >
                  Save & Continue
                </CrioButton>
              </div>
              <div data-qa-id="cancel">
                <CrioButton
                  theme="Secondary"
                  onClick={() => {
                    setMissingAnswersResolutionType(MissingAnswersResolutionType.SKIPPED);
                    closeHandler();
                  }}
                >
                  {t('Common.Cancel')}
                </CrioButton>
              </div>
            </div>
          </DialogActions>
        )}

      </StyledCrioDialog>
    </ThemeProvider>
  );
}

AlertsDialog.defaultProps = {
  closeHandler: () => { },
  successCallback: () => { },
};

export default AlertsDialog;
