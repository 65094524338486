/* eslint-disable no-param-reassign */
import { getAuth } from 'firebase/auth';
import {
  CarryForwardType, ChangeReason, ProcedureDisplayType, ProcedureStatus, QuestionFixedAnswerType, SyntheticQuestionType, VisitStatus,
} from '../../enums';
import {
  DataPoint, ProcedureInterface, ProcedureStatusInterface, SubjectData, VisitInterface,
} from '../../types';
import {
  UnresolvedAutoQuery,
  NotDoneRecordsInterface, ProcedureRecord, ProcedureRecords, SimplifiedRecords,
} from '../types';
import {
  CONFIGURATION_RECORD_ID, calculateStatusFromMissingQuestions, getDataPointsFromProcedureRecords, getDataPointsVisitStatus,
} from './recordUtils';
import {
  isDataPointAnswered,
  isDataPointDisabled,
  isDataPointSkipped,
} from '../../util/esourcePredicateUtil';
import AutoQuery from '../../types/AutoQuery';
import { applySubjectDataToDataPoint, applyVisitConfigToDataPoint } from '../../util/dataPointUtil';
import { getProcedureTypeFromDisplayType } from '../../util/procedureUtils';

/**
 * Helper function to apply given logic to a set of ProcedureRecords' Data Points
 * @param records   ProcedureRecords to apply the given logic to
 * @param applyFunction    Function to apply to the Data Points (a single argument of type DataPoint should be passed in)
 */
const applyToProcedureRecordsDataPoints = (records: ProcedureRecords, applyFunction: Function) => {
  // Iterate through the records
  Object.keys(records).forEach((recordId: string) => {
    const procedureRecord: ProcedureRecord = records[recordId].record;
    // Iterate through the questions
    Object.values(procedureRecord).forEach((dataPoint: DataPoint) => applyFunction(dataPoint));
  });
};

/**
 * We need to add the default "configuration" record. This configuration record is required to be saved
 * in the database for all non-permanent multirecords because once any subject_visit_procedure_question
 * data is saved, the backend will stop looking at the study_procedure_question for configuration. If we
 * didn' have this configuration record and the user didn't supply a record in the initial save (e.g. they
 * click "no_entry"), then if they ever go back to edit it and add a record for the first time, none of the
 * questions would show up in the record because those questions wouldn't exist in the svpq table.
 * @param records
 * @param displayType
 * @param carryForwardType
 * @param initialProcedureStatus
 * @param getDefaultedRecord
 * @returns
 */
const applyDefaultRecordIfNecessary = (records: ProcedureRecords, displayType: ProcedureDisplayType, carryForwardType: CarryForwardType, initialProcedureStatus: ProcedureStatus, getDefaultedRecord: Function) => {
  if (displayType !== ProcedureDisplayType.MULTI_RECORD) {
    return;
  }

  // This is our failsafe to not add the configuration record questions multiple times. Although
  // it would not be disasterous if we did because the backend would dedupe them since the pathing
  // to the question would be the same (record_id is always null for these types of record questions)
  if (initialProcedureStatus !== ProcedureStatus.NOT_STARTED) {
    return;
  }

  // The configuration record is NOT added for permanent multirecords because those ALWAYS look to
  // the study_procedure_questions for configuration
  if (carryForwardType === CarryForwardType.NO) {
    const defaultRecordId = CONFIGURATION_RECORD_ID;
    const defaultRecord = getDefaultedRecord(defaultRecordId);
    applyToProcedureRecordsDataPoints(
      { [defaultRecordId]: { record: defaultRecord, rulesEngine: {} } },
      // The hallmark of a configuration record is a null record_id
      (dataPoint: DataPoint) => { dataPoint.record_id = undefined; },
    );

    records[defaultRecordId] = { rulesEngine: {}, record: defaultRecord };
  }
};

/**
 * Apply the supplied change reason to all the records that require change reason
 * @param records The records, of which a subset will be mutated with a change reason
 * @param changedAnswersRecordQuestionList  The list of record questions that require a change reason
 * @param changeReason The change reason to apply
 * @param comment The change reason comment to apply
 * @returns Nothing, records are mutated by this function
 */
const applyChangeReason = (records: ProcedureRecords, changedAnswersRecordQuestionList?: SimplifiedRecords, changeReason?: ChangeReason, comment?: string): void => {
  if (!changedAnswersRecordQuestionList || !changeReason || !comment) {
    return;
  }

  changedAnswersRecordQuestionList.forEach(({ recordId, questions }) => {
    questions.forEach(({ questionId }) => {
      const existingQuestion = records[recordId]?.record[questionId];
      if (existingQuestion) {
        existingQuestion.change_reason = changeReason;
        existingQuestion.change_reason_details = comment;
      } else {
        // eslint-disable-next-line no-console
        console.error(`Somehow trying to apply a change reason on a record question we don't know about. RecordId: ${recordId}, QuestionId: ${questionId}`);
      }
    });
  });
};

/**
 * Apply the supplied not done reason to all the records that have been queued up to be marked not done
 * @param records The records, of which a subset will be mutated to have an answer of NOT_DONE and a comment explaining why
 * @param recordsToSet The list of record questions that will need to be marked not done
 * @param comment  The reason for marking them not done
 * @returns Nothing, records are mutated by this function
 */
const applyNotDone = (records: ProcedureRecords, recordsToSet?: NotDoneRecordsInterface, comment?: string): void => {
  if (!recordsToSet || !comment) {
    return;
  }

  Object.keys(recordsToSet).forEach((recordId: string) => {
    Object.keys(recordsToSet[recordId]).forEach((questionId) => {
      const markedNotDone = !!recordsToSet[recordId][questionId];
      if (markedNotDone) {
        const existingQuestion = records[recordId]?.record[questionId];
        if (existingQuestion) {
          existingQuestion.answer = QuestionFixedAnswerType.NOT_DONE;
          existingQuestion.answer_comment = comment;
        } else {
          // eslint-disable-next-line no-console
          console.error(`Somehow trying to mark not done on a record question we don't know about. RecordId: ${recordId}, QuestionId: ${questionId}`);
        }
      }
    });
  });
};

/**
 * Calculate, then apply the visit status to all records
 * @param records The records that will be mutated to have the most up to date visit status
 * @param procedureStatus  The new procedure status that has been calculated from the most recent snapshot of the records
 * @returns Nothing, records are mutated by this function
 */
const applyVisitStatus = (records: ProcedureRecords, { status: visitProcedureStatus }: ProcedureStatusInterface): void => {
  // Get the current Visit Status
  const currentVisitStatus: VisitStatus = getDataPointsVisitStatus(getDataPointsFromProcedureRecords(records));
  const visitProcedureIsComplete: boolean = visitProcedureStatus === ProcedureStatus.SKIPPED || visitProcedureStatus === ProcedureStatus.COMPLETED;
  let newVisitStatus: VisitStatus | undefined = currentVisitStatus;

  if (currentVisitStatus === VisitStatus.COMPLETED && visitProcedureIsComplete) {
    // If the Visit is completed and all Questions are still answered, it is completed
    newVisitStatus = VisitStatus.COMPLETED;
  } else if ((currentVisitStatus === VisitStatus.COMPLETED || currentVisitStatus === VisitStatus.PARTIALLY_COMPLETED) && !visitProcedureIsComplete) {
    // If the Visit is already Completed or Partially Completed or there are answered Questions, consider it Partially Completed
    newVisitStatus = VisitStatus.PARTIALLY_COMPLETED;
  } else if (!newVisitStatus) {
    // ... Or if it is not set, set it to paused TODO: can this be moved out as a fail safe?
    newVisitStatus = VisitStatus.PAUSED;
  }

  applyToProcedureRecordsDataPoints(
    records,
    (dataPoint: DataPoint) => { dataPoint.visit_status = newVisitStatus; },
  );
};

/**
 * Calculate, then apply the procedure status to all records
 * @param records The records that will be mutated to have the most up to date procedure status
 * @param missingRecordQuestionsList The list of missing record questions for this procedure that we're focused on
 * @param skipProcedure Whether we're skipping this procedure
 * @param procedureId The procedure id of the procedure we calculating the status of
 * @param carryForwardType The carry forward type of this procedure (NO | YES | PERMANENT)
 * @returns Nothing records are mutated by this function
 */
const applyProcedureStatus = (records: ProcedureRecords, missingRecordQuestionsList: SimplifiedRecords, skipProcedure: boolean, procedureId: string, carryForwardType: CarryForwardType): ProcedureStatusInterface => {
  const isPerm = carryForwardType === CarryForwardType.PERMANENT;
  // Perm record status can never be "skipped"
  const newProcedureStatus = calculateStatusFromMissingQuestions(missingRecordQuestionsList, isPerm ? false : skipProcedure);
  let visitLevelProcedureStatus: ProcedureStatus | undefined;

  applyToProcedureRecordsDataPoints(
    records,
    (dataPoint: DataPoint) => { dataPoint.procedure_status = newProcedureStatus; },
  );

  // If this is a permanent procedure, there could be a difference between the visit procedure status and the
  // status of the permanent records themselves. Visit procedure status is always a subset of the subjectProcedureStatus
  // in this scenario (typically it is always in a "COMPLETE" state wheras the permanent records themselves could
  // be PARTIALLY_COMPLETE).
  if (isPerm && records[procedureId]) {
    // The calculation of the visit-level status omits everything but the has_changes question
    const visitLevelMissingRecordQuestionsList = missingRecordQuestionsList.filter(({ recordIndex, questions }) => !recordIndex && questions && questions.find(({ type }) => type === SyntheticQuestionType.HAS_CHANGES));
    visitLevelProcedureStatus = calculateStatusFromMissingQuestions(visitLevelMissingRecordQuestionsList, skipProcedure);
    applyToProcedureRecordsDataPoints(
      { [procedureId]: records[procedureId] },
      (dataPoint: DataPoint) => { dataPoint.procedure_status = visitLevelProcedureStatus!; },
    );
  }

  return {
    procedureId,
    // Try to use the visit level one (in case of perm procedure), but fallback to the status of everything overall
    status: visitLevelProcedureStatus || newProcedureStatus,
    // If it's a perm procedure it's the status of everything overall. Doesn't exist otherwise
    subjectProcedureStatus: isPerm ? newProcedureStatus : undefined,
  };
};

const applyMiscellaneous = (records: ProcedureRecords, subjectData: SubjectData, visitConfig: VisitInterface, carryForwardType: CarryForwardType) => {
  const { currentUser } = getAuth();
  const { displayName, uid } = currentUser || {};

  applyToProcedureRecordsDataPoints(
    records,
    (dataPoint: DataPoint) => {
      const {
        answer,
        answer_user_id: existingAnswerUserId, is_disabled,
      } = dataPoint;

      const isDataPointUserAttributable = existingAnswerUserId
        || isDataPointAnswered(dataPoint)
        || isDataPointSkipped(dataPoint)
        || isDataPointDisabled(dataPoint);

      dataPoint.answer = !is_disabled ? answer : '';
      dataPoint.answer_user = isDataPointUserAttributable ? (displayName || '') : undefined;
      dataPoint.answer_user_id = isDataPointUserAttributable ? uid : undefined;
      dataPoint.answer_completed_date = new Date().getTime();
      dataPoint.is_disabled = !!is_disabled; // For the backend this needs to be either true or false, not undefined
      dataPoint.procedure_completed_date = new Date().getTime();

      applySubjectDataToDataPoint(dataPoint, subjectData);
      applyVisitConfigToDataPoint(dataPoint, visitConfig, carryForwardType);
    },
  );
};

const applyDeletion = (records: ProcedureRecords, recordId: string) => {
  applyToProcedureRecordsDataPoints(
    { [recordId]: records[recordId] },
    (dataPoint: DataPoint) => {
      dataPoint.is_active = false;
    },
  );
};

const pruneRecordsIfNecessary = (records: ProcedureRecords, initialRecords: ProcedureRecords, procedureId: string, noEntryDataPoint?: DataPoint, hasChangesDataPoint?: DataPoint) => {
  // Don't save anything but the has_changes question if it's not set to '1'
  if (hasChangesDataPoint && hasChangesDataPoint.answer !== '1') {
    Object.keys(records)
      .filter((recordId) => recordId !== procedureId)
      .forEach((recordId) => delete records[recordId]);
    Object.keys(records[procedureId].record)
      .filter((questionId) => questionId !== SyntheticQuestionType.HAS_CHANGES)
      .forEach((questionId) => delete records[procedureId].record[questionId]);

    Object.keys(initialRecords)
      .filter((recordId) => recordId !== procedureId)
      .forEach((recordId) => delete initialRecords[recordId]);
    Object.keys(initialRecords[procedureId].record)
      .filter((questionId) => questionId !== SyntheticQuestionType.HAS_CHANGES)
      .forEach((questionId) => delete initialRecords[procedureId].record[questionId]);
    return;
  }

  // Don't save in progress work for multirecord records if they've selected "no_entry"
  if (noEntryDataPoint && noEntryDataPoint.answer !== '1') {
    Object.keys(records)
      .filter((recordId) => recordId !== procedureId)
      .filter((recordId) => recordId !== procedureId)
      .forEach((recordId) => delete records[recordId]);

    Object.keys(initialRecords)
      .filter((recordId) => recordId !== procedureId)
      .forEach((recordId) => delete initialRecords[recordId]);
  }
};

/**
 * Transform the AutoQueries object to an array of AutoQuery in preparation for sending to the server
 * @param autoQueries the AutoQueries object to transform
 * @param subjectData some SubjectData to enrich the AutoQuery objects
 * @param visitConfig some VisitConfig data to enrich the AutoQuery objects
 * @param procedureConfig some ProcedureConfig data to enrich the AutoQuery objects
 * @param procedureOrRecordId the procedure_id or record_id depending on the context
 */
const enrichAutoQueries = (
  autoQueries: Array<UnresolvedAutoQuery>,
  { trialId, siteId, subjectId }: SubjectData,
  { visitId, name }: VisitInterface,
  procedureConfig: ProcedureInterface,
): Array<AutoQuery> => {
  const { currentUser } = getAuth();
  const { displayName, uid } = currentUser || {};
  const {
    procedureId,
    name: procedureName,
    displayType,
    carryForwardType,
    questions,
  } = procedureConfig;

  return autoQueries.map(({ autoQueryText, variableNames, procedureOrRecordId }) => {
    // If variableNames has only one value, then we can associate this autoquery with the question
    const { questionText, questionId } = (variableNames && variableNames.length === 1
      && questions.find((question) => question.variableName?.toLowerCase() === variableNames[0].toLowerCase()))
      || { questionText: undefined, questionId: undefined };
    return {
      trial_id: trialId,
      site_id: siteId,
      subject_id: subjectId,
      visit_id: visitId,
      visit_name: name,
      procedure_id: procedureId,
      procedure_name: procedureName,
      procedure_type: getProcedureTypeFromDisplayType(displayType, carryForwardType),
      record_id: procedureId !== procedureOrRecordId ? procedureOrRecordId : undefined,
      question_id: questionId,
      question_name: questionText,
      user_id: uid,
      user_name: displayName || '',
      comment: autoQueryText,
      created_date: new Date().getTime(),
    };
  });
};

export {
  applyChangeReason,
  applyDefaultRecordIfNecessary,
  applyMiscellaneous,
  applyNotDone,
  applyProcedureStatus,
  applyVisitStatus,
  applyDeletion,
  pruneRecordsIfNecessary,
  enrichAutoQueries,
};
