function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
  );
}

/**
 * Utility function for checking if the given string is empty (null or undefined)
 * @param value string to check
 * @return boolean stating whether or not the given input is empty
 */
const isEmpty = (value: string | null | undefined): boolean => (value === null || value === undefined);

/**
 * Utility function for checking if the given string is blank
 * @param value string to check
 * @return boolean stating whether or not the given input is blank
 */
const isBlank = (value: string | null | undefined): boolean => (isEmpty(value) || value?.trim() === '');

/**
 * Take any string and remove the single and double quote characters. Useful for avoiding issues with the
 * Sqlite parser.
 * @param value - string to be stripped of quotes
 */
const removeQuotes = (value: string) => value.replace(/["'“‘]/g, '');

export {
  toTitleCase, isEmpty, isBlank, removeQuotes,
};
