import styled from 'styled-components';
import { faBan, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Rule } from '../../types';
import { RuleType } from '../../enums';

interface RulesPreviewProps {
  rules?: Array<Rule>,
}

const RulesContainer = styled('div')`
  padding: 10px 20px 20px 30px;
  font-family: "Poppins-Medium", "Poppins Medium", "Poppins", sans-serif;
  margin-top: 20px;
  background-color: ${(props) => props.theme.palette.grey[300]};
  h1 {
    font-weight: 400;
    font-size: 18px;
  }

  .rule {
    display: flex;
    .icon {
      margin: 5px;
      margin-right: 12px;
      color: ${(props) => props.theme.palette.grey[400]};
      font-size: 20px;
    }
  }
`;

export default function RulesPreview(props: RulesPreviewProps) {
  const { rules } = props;
  const { t } = useTranslation();

  if (!rules || rules.length === 0) {
    return null;
  }

  return (
    <RulesContainer>
      <h1>{t('Procedure.Rules Preview.Procedure Logic')}</h1>
      {rules?.map(({
        alertText, condition, disableQuestions, ruleId, ruleType,
      }) => {
        const icon = ruleType === RuleType.DISABLE_QUESTIONS ? faBan : faCircleExclamation;
        const verb = ruleType === RuleType.DISABLE_QUESTIONS ? t('Procedure.Rules Preview.disable') : t('Procedure.Rules Preview.alert');
        const noun = ruleType === RuleType.DISABLE_QUESTIONS
          ? disableQuestions?.map((variableName) => `"${variableName}"`).join(', ')
          : `"${alertText}"`;

        return (
          <span data-testid={`rule-${ruleId}`} key={`${ruleId}${condition}`} className="rule">
            <FontAwesomeIcon className="icon" icon={icon} />
            {t('Procedure.Rules Preview.Statement', { condition, verb, noun })}
          </span>
        );
      })}
    </RulesContainer>
  );
}

RulesPreview.defaultProps = {
  rules: [],
};
