import { DataListItemOption } from '../types';
import { getIsOnline } from './offline';
import { fetchDataList } from '../api/esourceService';

/**
 * Utility function to load the Data List (if this Question has one)
 * Every time the input value is changed, wait .5 seconds so as to not query immediately on every keystroke
 * @param searchTerm  string containing the search term
 */
const retrieveDataList = async (
  studyId: string,
  dataListExternalId: string,
  searchTerm: string,
): Promise<Array<DataListItemOption>> => {
  try {
    if (studyId && dataListExternalId && getIsOnline()) {
      const json = await fetchDataList(studyId!, dataListExternalId!, searchTerm!);
      return await Promise.resolve(json);
    }
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }

  return Promise.reject(`Could not retrieve Data List: ${dataListExternalId}`);
};

/**
 * Make a Data List request to verify that they are working correctly
 * If not, trigger the error Dialog
 */
const healthCheckDataList = (studyId: string, dataListExternalId: string, onSuccess: Function, onError: Function) => {
  // If the data list could not be retrieved, show an error
  retrieveDataList(studyId, dataListExternalId, '')
    .then(() => onSuccess())
    .catch(() => onError());
};

// eslint-disable-next-line import/prefer-default-export
export { retrieveDataList, healthCheckDataList };
