enum EconsentTooltipDisplayType {
  COMPLETE = 'COMPLETE',
  NO_CONTACT_METHODS = 'NO_CONTACT_METHODS',
  WAIT_TURN = 'WAIT_TURN',
  SUBJECT_SIGN = 'SUBJECT_SIGN',
  INVESTIGATOR_COORDINATOR_SIGN = 'INVESTIGATOR_COORDINATOR_SIGN',
  SIGNING_OUTSIDE = 'SIGNING_OUTSIDE',
}

export default EconsentTooltipDisplayType;
