enum VisitModeType {
  DEFAULT = 'DEFAULT',
  PROCEDURE_PREVIEW = 'PROCEDURE_PREVIEW',
  PROCEDURE_TEMPLATE = 'PROCEDURE_TEMPLATE',
  READ_ONLY = 'READ_ONLY',
  SANDBOX = 'SANDBOX',
  SANDBOX_READ_ONLY = 'SANDBOX_READ_ONLY',
  SUBJECT = 'SUBJECT',
}

const isAnySandboxMode = (visitMode: VisitModeType | undefined): boolean => !visitMode
  || visitMode === VisitModeType.SANDBOX
  || visitMode === VisitModeType.SANDBOX_READ_ONLY
  || visitMode === VisitModeType.PROCEDURE_PREVIEW
  || visitMode === VisitModeType.PROCEDURE_TEMPLATE;

const isAnyProcedureMode = (visitMode: VisitModeType | undefined): boolean => visitMode === VisitModeType.PROCEDURE_PREVIEW
  || visitMode === VisitModeType.PROCEDURE_TEMPLATE;

const isAnyReadOnlyMode = (visitMode: VisitModeType | undefined): boolean => visitMode === VisitModeType.READ_ONLY
  || visitMode === VisitModeType.SANDBOX_READ_ONLY;

export {
  VisitModeType, isAnySandboxMode, isAnyProcedureMode, isAnyReadOnlyMode,
};
