import { DialogProps } from '@mui/material';
import { CrioAlertType } from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog/CrioAlertDialog';
import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import styled from 'styled-components';

export interface EsourceDialogProps extends DialogProps {
  open: boolean,
  closeHandler: Function,
  alertTitle: string,
  type: CrioAlertType,
  testId: string
}

const StyledCrioDialog = styled(CrioAlertDialog)`
  max-width: 520px;
`;

// Component representing a Dialog to pop-up when something happens
export default function EsourceDialog({
  open,
  closeHandler = () => {},
  alertTitle,
  children,
  type,
  testId,
}: EsourceDialogProps) {
  // If the Dialog should not appear, hide it
  if (!open) return null;

  return (
    <StyledCrioDialog
      data-testid={testId}
      alertTitle={alertTitle}
      type={type}
      open={open}
      onClose={() => closeHandler()}
      fullWidth={false}
      disablePortal
      sx={{ width: '50%', margin: 'auto' }}
    >
      {children}
    </StyledCrioDialog>
  );
}
