import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CrioToggle from './CrioToggle';
import ChangeReason from '../../enums/ChangeReason';
import SubjectDetailsChangeDialog from '../Visit/SubjectDetailsChangeDialog';
import ProcedureContext from '../../context/ProcedureContext';
import { isAnySandboxMode } from '../../enums';

export type RequiresTranscriptionToggleProps = {
  isDisabled: boolean,
};

// FIXME: change to pull CrioToggle from crio-react-component (https://clinical-research-io.atlassian.net/browse/EDC-2529)
export default function RequiresTranscriptionToggle({ isDisabled }: RequiresTranscriptionToggleProps) {
  const { saveRequiresTranscriptionChange, requiresTranscription, visitMode } = useContext(ProcedureContext);
  const [isChangeReasonModalOpen, setIsChangeReasonModalOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(requiresTranscription);
  const { t } = useTranslation();

  useEffect(() => {
    setIsChecked(requiresTranscription);
  }, [requiresTranscription]);
  const openChangeReasonModal = (toggleState: boolean) => {
    setIsChecked(toggleState);
    setIsChangeReasonModalOpen(true);
  };

  const handleCloseRequiresTranscriptionChangeReason = () => {
    setIsChecked((prev) => !prev);
    setIsChangeReasonModalOpen((prev) => !prev);
  };

  const handleSaveRequiresTranscriptionChangeReason = async (changeReason?: ChangeReason, changeReasonDetails?: string) => {
    if (!isAnySandboxMode(visitMode)) {
      await saveRequiresTranscriptionChange({ isChecked, changeReason, changeReasonDetails });
    }
    setIsChangeReasonModalOpen(false);
  };

  return (
    <>
      {isChangeReasonModalOpen && (
      // FIXME: see if you can pull from crio-react-component instead
        <SubjectDetailsChangeDialog
          label={t('Procedure.Secondary Entry.Requires Transcription')}
          onClose={handleCloseRequiresTranscriptionChangeReason}
          onSave={handleSaveRequiresTranscriptionChangeReason}
        />
      )}
      <CrioToggle label={t('Procedure.Secondary Entry.Requires Transcription')} onToggle={openChangeReasonModal} checked={isChecked} disabled={isDisabled} />
    </>
  );
}
