import { ProcedureInterface, ProcedureStatusInterface } from '../types';
import { ProcedureStatus } from '../enums';
import { getSkipProcedureStatusTypes } from './procedureStatusUtil';

/**
 * Get the next Procedure to open after this one
 * i.e.: the next incomplete one
 * OR if this is a Subject User who is completing the final Procedure, loop back to the first one
 * @param procedures          Array<ProcedureInterface of all Procedures
 * @param procedureStatuses   Array<ProcedureStatusInterface> of all statuses
 * @param currentProcedureId  {string | null} containing the current Procedure ID
 * @return                    { ProcedureInterface | null } for the next Procedure
 */
const getNextProcedure = (procedures: Array<ProcedureInterface>, procedureStatuses: Array<ProcedureStatusInterface>, currentProcedureId: string | null): ProcedureInterface | undefined => procedures
// Find the next Incomplete Procedure
  .find(({ procedureId, order }) => {
    const currentProcedure = procedures.find(({ procedureId: pId }) => pId === currentProcedureId);
    if (!currentProcedure) return null;
    const { status } = procedureStatuses.find(({ procedureId: id }) => procedureId === id) || {};
    return order > currentProcedure.order && status !== ProcedureStatus.COMPLETED && !(status && getSkipProcedureStatusTypes().includes(status));
  });

// eslint-disable-next-line import/prefer-default-export
export { getNextProcedure };
