import { Answer, DataList, QuestionAnswer } from '../types';

/**
 * Utility function to parse answer comments JSON
 * if the argument is valid JSON. Otherwise, return the value passed in
 * @param json string | undefined to parse
 * @param key  QuestionAnswer containing the key to retrieve
 * @return     string | undefined containing the parsed JSON
 */
export function parseAnswerCommentJson(json: string | undefined, key: QuestionAnswer) {
  if (!json) {
    return '';
  }

  try {
    return JSON.parse(json)[`${key}`];
  } catch (_err) {
    return json;
  }
}

export function getAnswerCommentsJson(newComments: string | undefined, key: QuestionAnswer) {
  if (!newComments || newComments === '') {
    return undefined;
  }

  return JSON.stringify({ [`${key}`]: newComments });
}

export function addToAnswerCommentsJson(json: string, newComments: string, key: QuestionAnswer) {
  const parsedJson = JSON.parse(json);
  parsedJson[`${key}`] = newComments;
  // if comment is empty string, remove it from the object
  if (parsedJson[`${key}`] === '') {
    delete parsedJson[`${key}`];
  }

  return JSON.stringify(parsedJson);
}

/**
 * Util function to determine if comments are necessary to proceed. Check three things:
 * - If the answer option has a comment required
 * - If the user's chosen answers include that answer option with required comment
 * - If the user has NOT left a comment for that answer option
 * @param answerOptions - Answer[] options for a given question.
 * @param answer - Question answer containing the user's chosen answers.
 * @param answerComment - a string containing the user's comments (in form of JSON for multiselect)
 */
export function determineIfCommentNeeded(answerOptions: Answer[] | undefined, answer: QuestionAnswer, answerComment: string | undefined) {
  if (!answerOptions || !answer) return false;
  return answerOptions.map((option) => option.commentRequired
      && answer?.toString().split('\n').filter((s) => s!).includes(option.text)
      && !parseAnswerCommentJson(answerComment, option.text)).includes(true);
}

/**
 * Utility function to get the Data List for the given Answer
 * @param answerOptions   {Answer[] | undefined} to potentially pull the Data List for
 * @param answer          QuestionAnswer to potentially pull the Data List for
 * @return                {DataList | undefined} for the given Answer
 */
export function getAnswerOptionsDataList(answerOptions: Answer[] | undefined, answer: QuestionAnswer): DataList | undefined {
  return answerOptions
    ?.find((answerOption) => answerOption.text === answer)
    ?.dataList;
}
