enum QuestionFormatType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DATE_ALLOW_ONGOING = 'DATE_ALLOW_ONGOING',
  DATETIME = 'DATETIME',
  DROPDOWN = 'DROPDOWN',
  NUMBER = 'NUMBER',
  NUMBER_ALLOW_DECIMALS = 'NUMBER_ALLOW_DECIMALS',
}

export default QuestionFormatType;
