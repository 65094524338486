/**
 * Utility function for adding a class to a sticky element that becomes stuck
 * @param baseElementQuerySelector  string containing the query selector
 * @param stuckClass                string containing the class to add on stick
 */
const initializeStickyObserver = (
  baseElementQuerySelector: string,
  stuckClass: string,
  onStuckCallback: Function = () => { },
  onUnstuckCallback: Function = () => { },
) => {
  const observer = new IntersectionObserver(
    ([e]) => {
      const isStuck = (e.intersectionRatio < 1);
      e.target.classList.toggle(stuckClass, isStuck);
      (isStuck) ? onStuckCallback() : onUnstuckCallback();
    },
    { threshold: [1] },
  );

  observer.observe(document.querySelector(baseElementQuerySelector)!);
};

// eslint-disable-next-line import/prefer-default-export
export { initializeStickyObserver };
