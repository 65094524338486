import { ThemeProvider } from 'styled-components';
import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { Visit } from './Visit';
import { fetchSubjectVisitConfig, verifyVisitConfig } from '../api/esourceService';
import { VisitInterface } from '../types';
import 'firebase/compat/auth';
import { getTokenFromCookie } from '../util/storage';
import User = firebase.User;
import LoadingSpinner from './LoadingSpinner';
import { VisitModeType } from '../enums';

const _getVisitMode = (subjectId: string | undefined, visitId: string | undefined, procedureTemplateId: string | undefined, visitReadOnly: boolean): VisitModeType => {
  const params = new URLSearchParams(window.location.search);
  const isSubjectUser = params.get('isSubjectUser');
  if (isSubjectUser) {
    return VisitModeType.SUBJECT;
  }

  if (procedureTemplateId) {
    return VisitModeType.PROCEDURE_TEMPLATE;
  }

  if (visitReadOnly) {
    return subjectId ? VisitModeType.READ_ONLY : VisitModeType.SANDBOX_READ_ONLY;
  }

  if (subjectId) {
    return VisitModeType.DEFAULT;
  }

  if (!visitId) {
    return VisitModeType.PROCEDURE_PREVIEW;
  }

  return VisitModeType.SANDBOX;
};

function DataCollection() {
  const [visitConfig, setVisitConfig] = useState<VisitInterface>();
  const [isLoggedIn, setIsLoggedIn] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const {
    studyId, subjectId, visitId, procedureId, procedureTemplateId,
  } = useParams();
  const navigate = useNavigate();
  const { readOnly: visitReadOnly = false } = visitConfig || {};
  const visitMode = _getVisitMode(subjectId, visitId, procedureTemplateId, visitReadOnly);

  useEffect(() => {
    const initializeSession = async () => {
      const cookieJwt = getTokenFromCookie();
      await firebase.auth().setPersistence(window.top !== window.self ? 'none' : 'session')
        .then(() => {
          firebase.auth().onAuthStateChanged(setIsLoggedIn);
          if (cookieJwt && !firebase.auth().currentUser) {
            firebase.auth().signInWithCustomToken(cookieJwt);
          }
        })
        .catch((err) => {
          console.log(err); // eslint-disable-line no-console
        });
    };

    const retrieveVisitConfig = async () => {
      try {
        const json = await fetchSubjectVisitConfig(visitMode, studyId!, subjectId, visitId, procedureId, procedureTemplateId);
        verifyVisitConfig(json);
        setVisitConfig(json);
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
        navigate('/error');
      }
    };

    initializeSession().then(() => {
      if (isLoggedIn) {
        retrieveVisitConfig();
        setIsLoading(false);
      }
    });
  }, [isLoggedIn]);

  return (
    <ThemeProvider theme={crioTheme}>
      {isLoading && <LoadingSpinner />}
      {!isLoading && visitConfig && <Visit visitMode={visitMode} visitConfig={visitConfig} setVisitConfig={setVisitConfig} />}
    </ThemeProvider>
  );
}

export default DataCollection;
