import { useParams } from 'react-router-dom';
import { DataPoint, RecordQuestion } from '../../../types';
import { AnswerType } from '../../../enums';
import DataListQuestion from './DataListQuestion';

/* WARNING
  We definitely do not want to get anything from the context directly at the question-level.
  Context value, when updated, triggers a blind rerender of EVERYTHING that makes use of it
  via useContext. Instead, things like handleAnswerChange and the dataPoints nested inside
  records are provided as props, therefore dodging the expensive context rerenders.
*/

function FreeTextQuestion(props: RecordQuestion) {
  const {
    variableName, recordId, handleAnswerChange, dataPoint, questionId, dataListExternalId, restrictedToDataList, readOnly,
  } = props;
  const { studyId } = useParams();
  const { answer } = dataPoint || {};
  if (dataPoint) {
    dataPoint.answer_type = AnswerType.TEXT;
  }

  /**
   * Get the updated Data Point using the new value (answer or answer_comment, depending on what this component is used for)
   * @param newFieldValue string containing the new value for the data point
   * @return              DataPoint | undefined updated using the given value
   */
  const getUpdatedDataPoint = (newFieldValue: string): DataPoint | undefined => {
    const updatedDataPoint = { ...dataPoint, answer: newFieldValue };
    return (updatedDataPoint as DataPoint);
  };

  return (
    <DataListQuestion
      multiline
      variableName={variableName!}
      studyId={studyId!}
      dataListExternalId={dataListExternalId}
      restrictedToDataList={restrictedToDataList}
      readOnly={readOnly}
      value={(answer as string) || ''}
      onBlur={(newFinishedAnswer: string) => {
        handleAnswerChange({
          questionId,
          variableName,
          newDataPoint: getUpdatedDataPoint(newFinishedAnswer),
          recordId,
          runRules: newFinishedAnswer !== dataPoint?.answer,
        });
      }}
    />
  );
}
export default FreeTextQuestion;
