import { TFunction, useTranslation } from 'react-i18next';
import { i18n } from 'i18next';
import { DateTime } from 'luxon';

/**
 * Formats epoch time into AM/PM (US English) or 24-hour time (non-US English).
 * Current non-English languages are Spanish and Portuguese.
 * Handles undefined timeZone - defaults to user's device tz if undefined
 * @param epochMS epoch time in milliseconds
 * @param languageCode from i18n to detect which language is in use
 * @param timeZone string representing timeZone
 */
export function formatTimestamp(epochMS: number, languageCode: string, timeZone: string | undefined): string {
  return new Date(epochMS).toLocaleTimeString(languageCode, { timeZone, timeStyle: 'short' });
}

export function getShortTimeZone(epochMS: number, timeZone: string | undefined): string {
  return new Date(epochMS).toLocaleDateString(undefined, { timeZone, timeZoneName: 'short' }).split(', ')[1];
}

/** Get the month names for clinical date format using the given translation function
 * @param {TFunction} t The translation function to use
 * @returns {string[]} An array of translated month names in clinical date format
 */
export function getMonthNamesWithTranslation(t: TFunction) {
  return [t('Date.Month.JAN'), t('Date.Month.FEB'), t('Date.Month.MAR'), t('Date.Month.APR'), t('Date.Month.MAY'), t('Date.Month.JUN'), t('Date.Month.JUL'), t('Date.Month.AUG'), t('Date.Month.SEP'), t('Date.Month.OCT'), t('Date.Month.NOV'), t('Date.Month.DEC')];
}

export function formatDateTimeWithTranslation(epochMS: number, { t, i18n: providedI18n }: { t: TFunction, i18n: i18n }, timeZone: string | undefined): string {
  const monthNames = getMonthNamesWithTranslation(t);
  const date = new Date(epochMS);
  return (`${date.getDate().toString().padStart(2, '0')}-${monthNames[date.getMonth()]}-${date.getFullYear()} ${formatTimestamp(epochMS, providedI18n.language, timeZone)} ${getShortTimeZone(epochMS, timeZone)}`);
}

/**
 * Format the given epoch (in ms) using the given translation function into the format 'dd-MMM-yyyy'
 * @param {number} epochMS The epoch date in milliseconds
 * @param {TFunction} t The translation function to use
 * @param {string} timeZone Time zone of the epoch date. If undefined, the date will be created with the user's local
 * time zone
 * @returns {string} The formatted date as a string
 */
export function formatDateWithTranslation(epochMS: number, t: TFunction, timeZone?: string): string {
  const monthNames = getMonthNamesWithTranslation(t);
  const date = DateTime.fromMillis(epochMS).setZone(timeZone);
  return (`${date.get('day').toString().padStart(2, '0')}-${monthNames[date.get('month') - 1]}-${date.get('year')}`);
}

export function formatDateTime(epochMS: number, timeZone: string | undefined): string {
  return formatDateTimeWithTranslation(epochMS, useTranslation(), timeZone);
}
