import Link from '@mui/material/Link';
import { DialogProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EsourceErrorDialog from './EsourceErrorDialog';

export interface SaveErrorDialogProps extends DialogProps {
  open: boolean,
  closeHandler: Function,
}

// Component representing a Dialog to pop-up when something fails to save
export default function SaveErrorDialog({ open, closeHandler = () => {}, children }: SaveErrorDialogProps) {
  const { t } = useTranslation();

  // If the Dialog should not appear, hide it
  if (!open) return null;

  return (
    <EsourceErrorDialog
      alertTitle={t('Error.Save Error Title')}
      open={open}
      closeHandler={() => closeHandler()}
    >
      <p>
        {t('Error.Save Error')}
      </p>
      {children}
      <p>
        {t('Error.Try Again Or Contact')}
        <Link href="mailto:support@clinicalresearch.io" color="inherit">
          support@clinicalresearch.io
        </Link>
      </p>
    </EsourceErrorDialog>
  );
}
