const uuid = require('uuid');
const RuleEngine = require('node-rules');
const parser = require('./custom-sqlite-parser');
const { removeQuotes } = require('../stringUtil');

/**
 * Initialize a new rules engine object.
 * @returns a new instance of a RuleEngine
 */
function initRulesEngine() {
  return new RuleEngine();
}
exports.initRulesEngine = initRulesEngine;

/**
 * Adds the given rule condition + consequence to a rule engine
 * @param engine
 * @param {string} ruleCondition
 * @param ruleId
 * @param procedureId
 * @param ruleType
 * @param tags
 * @param rulePriority
 * @param {function} callback
 */
function addRule(
  engine,
  ruleCondition,
  ruleId,
  procedureId,
  ruleType,
  tags = [],
  rulePriority = 1,
  callback = () => {},
) {
  const rule = {
    priority: rulePriority,
    name: ruleId || uuid.v4(),
    ruleType,
    on: false,
    procedureId,
    condition(options) {
      // console.log(parser.convertSqliteForNodeRules(ruleCondition));
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      options.when(Function('fact', 'functions', `return ${parser.convertSqliteForNodeRules(ruleCondition)}`)(this, parser.functions));
    },
    consequence: (R) => { callback(); this.result = false; R.next(); },
  };
  tags.forEach((tag) => {
    rule[tag] = 'yes';
  });
  engine.register(rule);
  return rule;
}
exports.addRule = addRule;

/**
 * Evaluate a json fact against this rule engine,
 * passing the result to the onskip or ontrigger handlers
 * @param {RuleEngine} ruleEngine
 * @param {json} fact
 * @param {function} onSkip
 * @param {function} onTrigger
 */
function evaluateFact({
  ruleEngine,
  fact,
  onSkip = () => {},
  onTrigger = () => {},
  onComplete = () => {},
}) {
  const sanitizedFact = {};
  Object.keys(fact).forEach((f) => {
    sanitizedFact[f] = typeof fact[f] === 'string' ? removeQuotes(fact[f]) : fact[f];
  });
  ruleEngine.execute(sanitizedFact, (data) => {
    if (data.result) {
      onSkip(data);
    } else {
      onTrigger(data);
    }
    onComplete();
  });
}
exports.evaluateFact = evaluateFact;
