import crioTheme from '@crio/crio-react-component/dist/cjs/config/crioTheme';
import { useTranslation } from 'react-i18next';
import styled, { ThemeProvider } from 'styled-components';
import Column from '../Column';
import { SubjectHeaderHeight } from '../../enums';
import partyImage from '../../assets/party.jpg';

const StyledSubjectVisitCompletedPage = styled(Column)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  min-height: calc(100vh - ${SubjectHeaderHeight.UNSTUCK}px);
  padding-top: 65px;
  text-align: center;

  .MainMessage {
    font-size: 18px;
    font-weight: 400;
  }
  .SubText {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.theme.palette.grey[600]};
  }
  .PartyImage {
    margin-left: auto;
    margin-right: auto;
  }
`;

export default function SubjectCompleteVisitPage() {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={crioTheme}>
      <StyledSubjectVisitCompletedPage>
        <img className="PartyImage" src={partyImage} alt="Hooray!" width="230px" />
        <h3 className="MainMessage">{t('Visit.Subject Complete Visit.Hooray! You\'ve reached the end of your tasks.')}</h3>
        <span className="SubText">{t('Visit.Subject Complete Visit.You may exit this tab.')}</span>
      </StyledSubjectVisitCompletedPage>
    </ThemeProvider>
  );
}
