import styled from 'styled-components';

const LinkText = styled.span`
  cursor: pointer;
  padding: 0;
  color: ${(props) => props.theme.palette.linkText.main};
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9em;
  
  &:hover {
    text-decoration: underline;
  }
`;

export default LinkText;
