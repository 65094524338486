import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const Container = styled('div')`
    display: flex;
    position: relative;
`;

const SwitchOption = styled('div')<{ $isChecked: boolean }>`
    position: absolute;
    z-index: 2;
    top: 6px;
    font-size: 0.875rem;
    font-weight: 600;
`;

const On = styled(SwitchOption)<{ $disabled?: boolean }>`
    left: 11px;
    color: ${(props) => (props.$disabled ? props.theme.palette.text.secondary : props.theme.palette.common.white)};
    visibility: ${(props) => (props.$isChecked ? 'visible' : 'hidden')};
`;

const Off = styled(SwitchOption)<{ $disabled?: boolean }>`
    left: 46px;
    color: ${(props) => (props.$disabled ? props.theme.palette.text.secondary : props.theme.palette.common.white)};
    visibility: ${(props) => (props.$isChecked ? 'hidden' : 'visible')};
`;

const StyledSwitch = styled(Switch)<{ $thumbDisabled?: boolean; $isChecked: boolean }>`
    width: 80px;
    height: 30px;
    padding: 0;
    margin: 0;
    overflow: visible;
    & .MuiSwitch-switchBase {
        padding: 1px;
    }
    & .Mui-checked {
        transform: translateX(40px) !important;
        color: ${(props) => props.theme.palette.common.white};
    }
    & .MuiSwitch-thumb {
        width: 40px;
        height: 30px;
        border-radius: 10px;
        color: ${(props) => (props.$thumbDisabled ? '#f7f7f7' : props.theme.palette.common.white)};
        border: 1px solid ${(props) => props.theme.palette.grey[500]};
        box-shadow: none;
        margin-top: -1px;
        margin-left: -1px;
    }
    & .MuiSwitch-track {
        border-radius: 10px;
        border: 1px solid ${(props) => props.theme.palette.grey[500]};
        background-color: ${(props) => (props.$isChecked ? props.theme.palette.base.main : props.theme.palette.error.main)};
        opacity: 1;
        transition: ${(props) => props.theme.transitions.create(['background-color', 'border'])};
    }
    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        opacity: 1;
        background-color: ${(props) => props.theme.palette.h.main};
    }
    & .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track {
        opacity: 1;
        border: 1px solid ${(props) => props.theme.palette.common.white};
    }
`;

type CrioSwitchProps = {
  onToggle: (arg: boolean) => void;
  checked: boolean;
  disabled?: boolean;
};

function CrioSwitch({ onToggle, checked, disabled }: CrioSwitchProps) {
  const [isChecked, setIsChecked] = useState(checked);
  const { t } = useTranslation();

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = (value: boolean) => {
    // since we have use effect in place, is this setIsChecked even needed anymore?
    setIsChecked((prev) => !prev);
    onToggle(value);
  };

  return (
    <Container>
      <On $isChecked={isChecked} $disabled={disabled}>{t('Procedure.Toggle.ON')}</On>
      <Off $isChecked={isChecked} $disabled={disabled}>
        {t('Procedure.Toggle.OFF')}
      </Off>
      <StyledSwitch
        checked={isChecked}
        onChange={() => handleChange(!isChecked)}
        name="on-off-switch-base"
        disableRipple
        $thumbDisabled={disabled}
        disabled={disabled}
        $isChecked={isChecked}
      />
    </Container>
  );
}

CrioSwitch.defaultProps = {
  disabled: false,
};

export default CrioSwitch;
