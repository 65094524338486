import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle /* , faUserLock */ } from '@fortawesome/pro-solid-svg-icons';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Column, Row } from '../index';

const LegendComponent = styled(Row)`
  padding: 15px 0;
  
  button {
    border: 1px solid ${(props) => props.theme.palette.grey[300]};
    max-height: 40px;
    background-color: #04141c !important;
    
    span {
      font-weight: 300;
      color: #C8D3DF;
      padding-left: 5px;
    }
    svg {
      margin-right: 7px;
      :not(&.fa-circle) {
        height: 0.9em;
      }
      &.fa-circle {
        border: 1px solid #ffd4d4;
        border-radius: 50%;
      }
    }
  }

  .MuiTooltip-popper {
    margin-top: 5px !important;
  }

  .MuiTooltip-tooltip {
    border: 1px solid #E4E4E4;
    background-color: #04141c;
    margin-top: 10px;
    margin-right: 20px;
    border-radius: 10px;
    padding: 15px;

    .Column {
      margin-right: 20px;
    }
  }

  .MuiTooltip-arrow {
    font-size: 30px;
    margin-left: 50px;
    color: #04141c;
    &::before {
      border: 1px solid #E4E4E4;
    }
  }

  .LegendTooltip {
    span {
      font-weight: 300;
      color: white;
      margin-left: 5px;
    }
    .LegendTooltipMiddleColumn {
      margin-left: 25px;
      //margin-right: 25px;
    }
  }
`;
export default function Legend() {
  const { t } = useTranslation();
  return (
    <LegendComponent title={t('Visit.Legend.Click to View Legend')} className="Legend">
      <CrioTooltip
        title={(
          <Row className="LegendTooltip">
            <Column>
              <Row>
                <FontAwesomeIcon icon={faCheck as IconProp} color="#00ff66" size="xl" />
                <span>{t('Visit.Legend.Complete')}</span>
              </Row>
            </Column>
            <Column className="LegendTooltipMiddleColumn">
              <Row>
                <FontAwesomeIcon icon={faExclamationCircle as IconProp} color="#ffffae" size="xl" />
                <span>{t('Visit.Legend.Incomplete')}</span>
              </Row>
            </Column>
            {/* <Column> */}
            {/*  <Row> */}
            {/*    <FontAwesomeIcon icon={faUserLock as IconProp} color="#f44d4d" size="lg" /> */}
            {/*    <span>{t('Visit.Legend.Question Locked')}</span> */}
            {/*  </Row> */}
            {/* </Column> */}
          </Row>
        )}
        arrow
        type="CLICK"
      >
        <CrioButton>
          <FontAwesomeIcon icon={faCheck as IconProp} color="#00ff66" size="lg" />
          <FontAwesomeIcon icon={faExclamationCircle as IconProp} color="#ffffae" size="lg" />
          <span>{t('Visit.Legend.View Legend')}</span>
        </CrioButton>
      </CrioTooltip>
    </LegendComponent>
  );
}
