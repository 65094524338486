import SaveErrorDialog, { SaveErrorDialogProps } from './SaveErrorDialog';

export interface SaveDataPointErrorDialogProps extends SaveErrorDialogProps {
  questionNames: string[]
}

// Component representing a Dialog to pop-up when a Data Point fails to save
export default function SaveDataPointErrorDialog(saveDataPointErrorDialogProps: SaveDataPointErrorDialogProps) {
  const { questionNames } = saveDataPointErrorDialogProps;

  return (
    <SaveErrorDialog {... saveDataPointErrorDialogProps}>
      {
        // If Question Names were given, list them as bullet points
        questionNames.length > 0
          && (
          <ul>
            {questionNames.map((questionName, index) => <li key={index}>{questionName}</li>)}
          </ul>
          )
      }
    </SaveErrorDialog>
  );
}
