import { DialogProps } from '@mui/material';
import EsourceDialog from './EsourceDialog';

export interface EsourceSuccessDialogProps extends DialogProps {
  open: boolean,
  closeHandler: Function,
  alertTitle: string
}

// Component representing a Dialog to pop-up when some information needs to be relayed in big, scary letters
export default function EsourceSuccessDialog({
  open, closeHandler = () => {}, alertTitle, children,
}: EsourceSuccessDialogProps) {
  // If the Dialog should not appear, hide it
  if (!open) return null;

  return (
    <EsourceDialog
      testId="successDialog"
      alertTitle={alertTitle}
      type="Success"
      open={open}
      closeHandler={() => closeHandler()}
    >
      {children}
    </EsourceDialog>
  );
}
