import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Interweave } from 'interweave';
import LinkText from './LinkText';

const MultilineEllipsis = styled(Typography)<{ numlines?: number }>`
  display: -webkit-box;
  ${(props) => (props.numlines ? `-webkit-line-clamp: ${props.numlines};` : '')}
  -webkit-box-orient: vertical;
  white-space: unset;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9em;
`;

const StyledLinkText = styled(LinkText)`
  float: right;
  padding: 0;
`;

export interface CollapsibleTextProps {
  text: string,
  numLines?: number,
  'data-testid'?: string,
}

export default function CollapsibleText({ text, numLines, 'data-testid': dataTestId }: CollapsibleTextProps) {
  const textElementRef = useRef<HTMLSpanElement>(null);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [maxLines, setMaxLines] = useState<number | undefined>(numLines);

  const compareSize = () => {
    const compare = textElementRef.current && textElementRef.current.scrollHeight > textElementRef.current.clientHeight;
    setHasMore(compare || false);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(() => () => {
    window.removeEventListener('resize', compareSize);
  }, []);

  return (
    <>
      {hasMore && (
        <StyledLinkText
          onClick={() => { setHasMore(false); setMaxLines(undefined); }}
        >
          more
        </StyledLinkText>
      )}
      <MultilineEllipsis
        noWrap
        numlines={maxLines}
        ref={textElementRef}
        data-testid={dataTestId}
      >
        <Interweave content={text} />
      </MultilineEllipsis>
    </>
  );
}

CollapsibleText.defaultProps = {
  numLines: 0,
  'data-testid': 'collapsible',
};
