import styled from 'styled-components';

const InfoText = styled.div`
  padding: 15px 20px;
  background-color: ${(props) => props.theme.palette.grey.A100};
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  color: ${(props) => props.theme.palette.grey[700]};
  height: auto;
  line-height: 1.5em;
  font-size: 0.95em;
`;
export default InfoText;
