import CrioAlertDialog from '@crio/crio-react-component/dist/cjs/components/Feedback/CrioAlertDialog';
import styled from 'styled-components';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { useTranslation } from 'react-i18next';

export interface SkipProcedureDialogProps {
  isOpen: boolean,
  continueHandler?: Function,
  closeHandler?: Function,
}

const StyledCrioDialog = styled(CrioAlertDialog)`
  max-width: 520px;

  .optionsContainer {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .SkipButton {
    cursor: pointer;
    margin-left: 20px;
    color: ${(props) => props.theme.palette.linkText.main};
  }
`;

export default function SkipProcedureDialog({
  continueHandler = () => {},
  closeHandler = () => {},
  isOpen,
}: SkipProcedureDialogProps) {
  const { t } = useTranslation();
  return (
    <StyledCrioDialog
      type="Info"
      open={isOpen}
      alertTitle={t('Procedure.Dialog.SkipProcedure.Are you sure')}
      onClose={() => closeHandler()}
      fullWidth={false}
      disablePortal
      sx={{ width: '50%', margin: 'auto' }}
    >
      <p className="Message">
        { t('Procedure.Dialog.SkipProcedure.Message') }
      </p>
      <p className="Tip">
        { t('Procedure.Dialog.SkipProcedure.Tip') }
      </p>
      <div className="optionsContainer">
        <div data-qa-id="cancelSkip"><CrioButton theme="Primary" onClick={() => closeHandler()}>{ t('Procedure.Dialog.SkipProcedure.Cancel Skip') }</CrioButton></div>
        <div
          data-qa-id="YesSkip"
          role="button"
          tabIndex={0}
          className="SkipButton"
          onClick={() => continueHandler()}
          onKeyDown={() => continueHandler()}
        >
          { t('Procedure.Dialog.SkipProcedure.Yes Skip Procedure') }
        </div>
      </div>

    </StyledCrioDialog>
  );
}

SkipProcedureDialog.defaultProps = {
  continueHandler: () => {},
  closeHandler: () => {},
};
