import styled from 'styled-components';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import CrioTextField from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioTextField';
import { useTranslation } from 'react-i18next';
import CalendarPicker from '@crio/crio-react-component/dist/cjs/components/DateTime/CrioCalendarPicker';
import CalendarPickerVariant from '@crio/crio-react-component/dist/cjs/enums/CalendarPickerVariant';
import {
  CrioDateValue,
  extractDateTime,
} from '@crio/crio-react-component/dist/cjs/components/DateTime/CrioCalendarUtils';
import { ClickAwayListener } from '@mui/material';
import SubjectDetailsChangeDialog from './SubjectDetailsChangeDialog';
import Row from '../Row';
import SubjectFieldName from '../../enums/SubjectFieldName';
import { ChangeReason } from '../../enums';
import { formatDateWithTranslation } from '../../util/dateTimeUtil';

const Label = styled('div')`
  width: 120px;
  font-weight: 400;
  padding-top: 10px;
`;

const ReadMode = styled('div')`
  border: 1px solid ${(props) => props.theme.palette.grey[50]};
  padding: 5px;
  margin: 3px 9px;
  &:hover {
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.palette.grey[300]};
    border-radius: 5px;
    background-color: ${(props) => props.theme.palette.grey[200]};
  }
  .editPen {
    margin-left: 5px;
    color: #999;
    display: none;
  }
  &:hover .editPen {
    display: inline;
  }
`;

const StyledTextField = styled(CrioTextField)`
  border-width: 1px;
  width: 300px;
  & div.Mui-error {
    border-radius: 0;
    border-bottom: 2px solid ${(props) => props.theme.palette.error.main};
  }
`;

type SubjectDetailsFieldProps = {
  fieldName: SubjectFieldName;
  fieldString?: string;
  fieldLong?: number;
  requiresChangeReason?: boolean;
  onSave: (fieldName: SubjectFieldName, fieldString?: string | null, fieldLong?: number | null, changeReason?: ChangeReason, changeReasonDetails?: string) => Promise<any>;
};

export default function SubjectDetailsField({
  fieldName, fieldString, fieldLong, requiresChangeReason = false, onSave,
}: SubjectDetailsFieldProps) {
  const [inputString, setInputString] = useState<string | undefined | null>(fieldString);
  const [inputLong, setInputLong] = useState<number | undefined | null>(fieldLong);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslation();

  const handleCloseDialog = () => {
    setIsModalOpen(false);
    // If the dialog is closed without saving, reset the field back to the original value
    setInputString(fieldString);
    setInputLong(fieldLong);
    setIsEditMode(false);
  };

  const handleSave = (changReason?: ChangeReason, changeReasonDetails?: string) => {
    onSave(fieldName, inputString, inputLong, changReason, changeReasonDetails)
      .then(() => setIsEditMode(false))
      .catch((e) => setError(e.message))
      .finally(() => setIsModalOpen(false));
  };

  const handleBlur = (newValue?: string | number | null) => {
    // Only trigger the change reason modal/save workflow if the value has changed
    if (newValue !== fieldString && newValue !== fieldLong) {
      if (requiresChangeReason) {
        setIsModalOpen(true);
      } else {
        handleSave();
      }
    } else {
      setIsEditMode(false);
    }
  };

  const handleDateChange = (newDate: CrioDateValue) => {
    // Get the epoch value of the date or null if the user is trying to null out the field
    const newDateEpoch = newDate ? extractDateTime(newDate).toMillis() : null;
    setInputLong(newDateEpoch);
  };

  const handleTextFieldChange = (value: string) => {
    setInputString(value);
    setError(undefined);
  };

  // We need to use the US/Eastern time zone because that's what the site app data is stored as. The epoch timestamps
  // do not have time zone information so when we attempt to format the date we need to create it with the correct zone
  const getDateDisplay = (value?: number | null) => (value ? formatDateWithTranslation(new Date(value).getTime(), t, 'US/Eastern') : '');

  const input = fieldName === SubjectFieldName.BIRTH_DATE
    ? (
      // Clickawaylistener is required because the calendar component doesn't fire the change function if the value
      // is unchanged which means we can't trigger the onblur functionality
      <ClickAwayListener onClickAway={() => handleBlur(inputLong)}>
        <div>
          <CalendarPicker
            name={t(`Visit.Subject Details.${fieldName}`)}
            variant={CalendarPickerVariant.DATE}
            selectedDate={getDateDisplay(inputLong)}
            popupOkButton
            onDateChange={handleDateChange}
            customToolbarButtons={[]}
          />
        </div>
      </ClickAwayListener>
    )
    : (
      <StyledTextField
        value={inputString || ''}
        onChange={(e) => handleTextFieldChange(e.target.value)}
        onBlur={() => handleBlur(inputString)}
        autoFocus
        error={!!error}
        helperText={error}
      />
    );

  return (
    <div data-testid="subject-details-field">
      <Row>
        <Label>{t(`Visit.Subject Details.${fieldName}`)}</Label>
        {isEditMode
          ? input : (
            <ReadMode onClick={() => setIsEditMode(true)}>
              {inputString || getDateDisplay(inputLong) || '-'}
              <FontAwesomeIcon icon={faPen} className="editPen" />
            </ReadMode>
          )}
      </Row>
      {requiresChangeReason && (
        <SubjectDetailsChangeDialog
          open={isModalOpen}
          label={t(`Visit.Subject Details.${fieldName}`)}
          onClose={handleCloseDialog}
          onSave={handleSave}
          data-testid="subject-details-change-dialog"
        />
      )}
    </div>
  );
}
