import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import RadioGroup from '@crio/crio-react-component/dist/cjs/components/Inputs/RadioGroup';
import CrioButton from '@crio/crio-react-component/dist/cjs/components/Inputs/CrioButton';
import { CrioTooltip } from '@crio/crio-react-component/dist/cjs/components/DataDisplay';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import ProcedureContext from '../../context/ProcedureContext';
import {
  fetchInternalEconsentLink,
  saveDataPoints,
  sendPinData,
  useFetchEconsentDataQuery,
} from '../../api/esourceService';
import spinner from '../../assets/spinner.gif';
import { Row } from '../index';
import { DataPoint, SubjectData, VisitInterface } from '../../types';
import { SaveDataPointsResponse } from '../../api/types';
import StudyRoleType from '../../enums/StudyRoleType';
import {
  CarryForwardType,
  EconsentContactType,
  EconsentTooltipDisplayType,
  EconsentType,
  ICFWorkflow,
  ProcedureOverrideStatus,
} from '../../enums';
import { setFirebaseToken, signOutAndRedirect } from '../../util/authUtil';
import { getAncillaryDataPoint } from '../../util/dataPointUtil';
import { EsourceSuccessDialog, SaveErrorDialog } from './Dialog';

const EconsentTooltipWrap = styled.div`
  .MuiTooltip-popper {
    z-index: 1200!important;
  }

  div.disabled {
    color: ${(props) => props.theme.palette.grey[600]};
  }
`;

const StyledEconsentTooltip = styled.div`
  .EconsentTooltip {
    max-width: 430px;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
    color: ${(props) => props.theme.palette.grey[700]};
    .Header {
      color: ${(props) => props.theme.palette.grey[900]};
      padding-bottom: 10px;
    }
    strong {
      font-weight: 500;
    }
    .MuiFormControlLabel-label {
      font-size: .85rem;
      color: ${(props) => props.theme.palette.grey[900]};
    }
    p {
      margin: 10px;
    }
    .StartSigning {
      justify-content: space-between;
      align-items: center;
      .Signee {
        padding: 0;
      }
      button {
        font-size: 1em;
      }
    }
    .StartSigningButton {
      margin-left: 10px;
    }
    .Signee {
      padding: 10px 0 20px 0;
      span {
        font-weight: ${(props) => props.theme.typography.fontWeightRegular};
      }
    }
    .ContactButton {
      box-shadow: 0 0 5px rgb(0 0 0 / 25%);
      border-left: 5px solid;
      cursor: pointer;
      padding: 15px 15px 10px 15px;
      margin: 5px 0 10px 0;
      &:hover {
        box-shadow: 0 0 15px rgb(0 0 0 / 25%);
      }
    }
    .Phone {
      border-left-color: ${(props) => props.theme.palette.secondary.main};
    }
    .Email {
      border-left-color: ${(props) => props.theme.palette.info.dark};
    }
    .Footer {
      padding-top: 15px;
      margin-top: 20px;
      border-top: 1px dotted #d6d6d6;
    }
    a {
      text-decoration: none;
    }
    .Alert, &.Alert {
      svg {
        padding-right: 15px;
      }
      .fa-exclamation-circle {
        color: ${(props) => props.theme.palette.error.main};
      }
      .fa-circle-check {
        color: ${(props) => props.theme.palette.success.dark};
      }
    }
  }
`;

type LaunchSignatureTooltipProps = {
  subjectData: SubjectData;
  disabled: boolean;
  visitConfig: VisitInterface;
};

export default function EconsentTooltip({ disabled, subjectData, visitConfig }: LaunchSignatureTooltipProps) {
  const {
    procedureId, getICFHardcopyOverride, setICFHardcopyOverride, readOnly,
  } = useContext(ProcedureContext);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [pinSentIsOpen, setPinSentIsOpen] = useState<boolean>(false);
  const [pinSentTo, setPinSentTo] = useState<string>('');
  const [isTooltipLoading, setIsTooltipLoading] = useState<boolean>(false);
  const [econsentType, setEconsentType] = useState<EconsentType>(EconsentType.IN_PERSON);
  const [tooltipDisplayType, setTooltipDisplayType] = useState<EconsentTooltipDisplayType>();
  const [saveError, setSaveError] = useState<string | null>(null);
  const { currentUser } = getAuth();
  const { studyId, subjectId, visitId } = useParams();
  const { email, mobilePhoneNumber } = subjectData;

  const { t } = useTranslation();

  const { econsentData, isLoading } = useFetchEconsentDataQuery(studyId!, subjectId!, visitId!, procedureId, isOpen);

  const showLoadingTooltip: boolean = isLoading || isTooltipLoading;
  const canToggleHardcopyOverride: boolean = econsentData?.can_toggle_hardcopy_override && !readOnly;
  const role = econsentData?.next_signer?.role?.toUpperCase();
  const currentUserRole = econsentData?.current_user_role?.toUpperCase();

  const updateTooltipState = () => {
    if (getICFHardcopyOverride && getICFHardcopyOverride()) {
      setTooltipDisplayType(EconsentTooltipDisplayType.SIGNING_OUTSIDE);
    } else if (role === StudyRoleType.INVESTIGATOR || role === StudyRoleType.COORDINATOR) {
      setTooltipDisplayType(currentUserRole === role
        ? EconsentTooltipDisplayType.INVESTIGATOR_COORDINATOR_SIGN
        : EconsentTooltipDisplayType.WAIT_TURN);
    } else if (role === StudyRoleType.SUBJECT) {
      // TODO: add actual email and phone number verification here
      if (email || mobilePhoneNumber) {
        setTooltipDisplayType(EconsentTooltipDisplayType.SUBJECT_SIGN);
      } else {
        setTooltipDisplayType(EconsentTooltipDisplayType.NO_CONTACT_METHODS);
      }
    } else if (!role) {
      setTooltipDisplayType(EconsentTooltipDisplayType.COMPLETE);
    }
  };

  useEffect(() => {
    if (showLoadingTooltip || !isOpen) {
      return;
    }

    updateTooltipState();
  }, [isLoading, isTooltipLoading, isOpen, econsentData, subjectData]);

  const getRoleText = () => {
    switch (role) {
      case StudyRoleType.COORDINATOR:
        return t('Procedure.Econsent.Coordinator');
      case StudyRoleType.INVESTIGATOR:
        return t('Procedure.Econsent.Investigator');
      case StudyRoleType.SUBJECT:
        return t('Procedure.Econsent.Subject');
      case StudyRoleType.UNKNOWN:
        return t('Procedure.Econsent.Unknown');
      default:
        return '';
    }
  };

  const renderTooltipContent = () => {
    const launchSignatureHeader = <div className="Header"><strong>{t('Procedure.Econsent.Launch Signature')}</strong></div>;

    /**
     * Handle an ICF workflow update
     * @param icfWorkflow   ICFWorkflow to update to
     */
    const updateICFHardcopyOverride = async (icfWorkflow: ICFWorkflow, keepLoadingAfter: boolean = false) => {
      try {
        setIsTooltipLoading(true);

        // Create a synthetic Data Point
        const icfHardcopyOverride: boolean = (icfWorkflow === ICFWorkflow.HARDCOPY);
        // FIXME: currently default to carry forward no for this synthetic dp
        const syntheticDataPoint: DataPoint = getAncillaryDataPoint(studyId!, procedureId, currentUser, subjectData, visitConfig, CarryForwardType.NO);
        if (icfHardcopyOverride) syntheticDataPoint.procedure_override_status = ProcedureOverrideStatus.ICF_HARDCOPY;

        // Save the Data Point--if it was successfully ssaved update the UI accordingly
        const updatedDataPoints: SaveDataPointsResponse = await saveDataPoints({
          dataPointsToSave: [syntheticDataPoint],
          studyId: studyId!,
          visitId,
          subjectId,
          procedureId,
        });
        if (updatedDataPoints.success.length > 0) {
          setICFHardcopyOverride(icfHardcopyOverride);
          icfHardcopyOverride
            ? setTooltipDisplayType(EconsentTooltipDisplayType.SIGNING_OUTSIDE)
            : updateTooltipState();
          return;
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (!keepLoadingAfter) {
          setIsTooltipLoading(false);
        }
      }

      // If the function didn't return on the success, give an error message
      setSaveError(t('Procedure.Econsent.Error.ICF Hardcopy Override'));
    };

    /**
    * Handle Sign Outside workflow update
    */
    const handleSignOutsideClick = () => updateICFHardcopyOverride(ICFWorkflow.HARDCOPY);

    /**
     * Handle Sign as eConsent workflow update
     */
    const handleSignUsingEconsentClick = () => updateICFHardcopyOverride(ICFWorkflow.ECONSENT);

    const signOutsideFooter = (
      <>
        {canToggleHardcopyOverride && (
          <Row className="Footer">
            {t('Procedure.Econsent.Not using eConsent')}
            <div
              className="Link"
              role="button"
              tabIndex={0}
              onClick={handleSignOutsideClick}
              onKeyDown={handleSignOutsideClick}
            >
              &nbsp;
              {t('Procedure.Econsent.Sign Outside')}
            </div>
          </Row>
        )}
      </>
    );
    const currentSignee = (
      <div className="Signee">
        <strong>
          {t('Procedure.Econsent.Current Signee')}
          :
        </strong>
        &nbsp;
        <span>{getRoleText()}</span>
      </div>
    );
    const getAlertIcon = (alertType: 'success' | 'error') => <FontAwesomeIcon icon={alertType === 'error' ? faExclamationCircle : faCheckCircle} size="2x" />;
    const handleContactButtonClick = async (contactType: EconsentContactType) => {
      setIsTooltipLoading(true);

      // Initialize the Subject Visit + Procedure
      const currentICFWorkflow: ICFWorkflow = getICFHardcopyOverride() ? ICFWorkflow.HARDCOPY : ICFWorkflow.ECONSENT;
      await updateICFHardcopyOverride(currentICFWorkflow, true);

      // Send out pin data to the subject
      const pinData = await sendPinData(
        studyId!,
        subjectId!,
        visitId!,
        procedureId!,
        {
          sendSignedLink: econsentType === EconsentType.REMOTE,
          contactType,
        },
      );

      setIsOpen(false);
      setPinSentTo(contactType === EconsentContactType.EMAIL ? email! : mobilePhoneNumber!);
      setPinSentIsOpen(true);

      // If signing in person, sign out and go to the given link
      if (econsentType === EconsentType.IN_PERSON) {
        const { pinLink: { link }, siteAppDomainUrl } = pinData;
        signOutAndRedirect(siteAppDomainUrl, link);
      }

      // Make sure the full tooltip doesn't flash by pushing it to the next render cycle
      setTimeout(() => setIsTooltipLoading(false), 100);
    };

    /**
     * Launch eConsent for an internal User
     */
    const launchEconsentForInternalUser = async () => {
      const currentICFWorkflow: ICFWorkflow = getICFHardcopyOverride() ? ICFWorkflow.HARDCOPY : ICFWorkflow.ECONSENT;
      await updateICFHardcopyOverride(currentICFWorkflow, true);

      const linkData = await fetchInternalEconsentLink(studyId!, subjectId!, visitId!, procedureId!);
      const { pinLink: { link }, crossDomainHost } = linkData;
      setFirebaseToken('econsent-jwt', crossDomainHost);
      window.open(link, '_blank');
      setIsTooltipLoading(false);
      handleClose();
    };

    switch (tooltipDisplayType) {
      case EconsentTooltipDisplayType.COMPLETE:
        return (
          <Row className="EconsentTooltip Alert">
            {getAlertIcon('success')}
            {t('Procedure.Econsent.Completed message')}
          </Row>
        );
      case EconsentTooltipDisplayType.WAIT_TURN:
        return (
          <div className="EconsentTooltip">
            <Row className="EconsentTooltip Alert">
              {getAlertIcon('error')}
              {t('Procedure.Econsent.Wait turn')}
            </Row>
            {signOutsideFooter}
          </div>
        );
      case EconsentTooltipDisplayType.NO_CONTACT_METHODS:
        return (
          <div className="EconsentTooltip">
            <Row className="Alert">
              {getAlertIcon('error')}
              {t('Procedure.Econsent.Add contact')}
            </Row>
            {signOutsideFooter}
          </div>
        );
      case EconsentTooltipDisplayType.SUBJECT_SIGN:
        return (
          <div className="EconsentTooltip">
            {launchSignatureHeader}
            <RadioGroup
              onRadioOptionsChange={(e) => {
                const { value } = e.target;
                if (value === EconsentType.IN_PERSON || value === EconsentType.REMOTE) {
                  setEconsentType(value);
                }
              }}
              name="progress note type"
              disabled={false}
              radioOptions={[
                {
                  name: t('Procedure.Econsent.In Person'),
                  value: EconsentType.IN_PERSON,
                  checked: econsentType === EconsentType.IN_PERSON,
                },
                {
                  name: t('Procedure.Econsent.Remote'),
                  value: EconsentType.REMOTE,
                  checked: econsentType === EconsentType.REMOTE,
                }]}
              row
            />
            <p>
              {econsentType === EconsentType.IN_PERSON ? t('Procedure.Econsent.In person description')
                : t('Procedure.Econsent.Remote description')}
            </p>
            {currentSignee}
            <div>
              {econsentType === EconsentType.IN_PERSON ? t('Procedure.Econsent.Send pin') : t('Procedure.Econsent.Send pin and URL')}
              &nbsp;:
            </div>
            {mobilePhoneNumber && (
              <div
                className="ContactButton Phone"
                role="button"
                tabIndex={0}
                onClick={() => handleContactButtonClick(EconsentContactType.SMS)}
                onKeyDown={() => handleContactButtonClick(EconsentContactType.SMS)}
              >
                <strong>
                  {t('Procedure.Econsent.Phone')}
                  {': '}
                  {mobilePhoneNumber}
                </strong>
              </div>
            )}
            {email && (
              <div
                className="ContactButton Email"
                role="button"
                tabIndex={0}
                onClick={() => handleContactButtonClick(EconsentContactType.EMAIL)}
                onKeyDown={() => handleContactButtonClick(EconsentContactType.EMAIL)}
              >
                <strong>
                  {t('Procedure.Econsent.Email')}
                  {': '}
                  {email}
                </strong>
              </div>
            )}
            {signOutsideFooter}
          </div>
        );
      case EconsentTooltipDisplayType.INVESTIGATOR_COORDINATOR_SIGN:
        return (
          <div className="EconsentTooltip">
            {launchSignatureHeader}
            <Row className="StartSigning">
              {currentSignee}
              <CrioButton className="StartSigningButton" onClick={launchEconsentForInternalUser}>{t('Procedure.Econsent.Start Signing')}</CrioButton>
            </Row>
            {signOutsideFooter}
          </div>
        );
      case EconsentTooltipDisplayType.SIGNING_OUTSIDE:
        return (
          <div className="EconsentTooltip">
            <Row className="Alert">
              {getAlertIcon('success')}
              {t('Procedure.Econsent.Signing outside')}
            </Row>
            {canToggleHardcopyOverride && (
              <>
                <div className="Footer">
                  {t('Procedure.Econsent.Or use')}
                  <span className="Link" role="button" tabIndex={0} onClick={handleSignUsingEconsentClick} onKeyDown={handleSignUsingEconsentClick}>&nbsp;eConsent</span>
                </div>
                <div>{t('Procedure.Econsent.Cannot change')}</div>
              </>
            )}

          </div>
        );
      default:
        return undefined;
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClose}>
        <EconsentTooltipWrap>
          <CrioTooltip
            open={isOpen}
            type="CLICK"
            onClose={handleClose}
            arrow
            disabled={disabled}
            title={
              showLoadingTooltip
                ? <img src={spinner} alt="Loading..." height="20px" width="20px" data-testid="econsentTooltip" />
                : <StyledEconsentTooltip data-testid="econsentTooltip">{renderTooltipContent()}</StyledEconsentTooltip>
            }
          >
            <div className={`Link ${disabled ? 'disabled' : ''}`} role="button" onClick={handleOpen} onKeyDown={handleOpen} tabIndex={0}>{t('Procedure.Econsent.Launch Signature')}</div>
          </CrioTooltip>
        </EconsentTooltipWrap>
      </ClickAwayListener>
      {saveError && (
        <SaveErrorDialog
          open={saveError !== null}
          closeHandler={() => setSaveError(null)}
        >
          {saveError}
        </SaveErrorDialog>
      )}
      {pinSentIsOpen && econsentType === EconsentType.REMOTE
        && (
          <EsourceSuccessDialog
            open={pinSentIsOpen}
            closeHandler={() => setPinSentIsOpen(false)}
            alertTitle={t('Procedure.Econsent.Success')}
          >
            <p>{t('Procedure.Econsent.PinAndDocumentSentTo', { contact: pinSentTo })}</p>
          </EsourceSuccessDialog>
        )}
    </>
  );
}
