const PROCEDURE_LOCK_PREFIX = 'procedure_lock';

/**
 * Set a cookie
 * @param name            string containing the cookie name
 * @param value           string containing the cookie value
 * @param expireMillis    number containing the expiration time
 * @param domain          {string | null} to specify the cookie domain
 */
export function setCookie(name: string, value: string, expireMillis: number, domain: string | null = '') {
  const expireDate = new Date();
  expireDate.setTime(expireMillis);
  const expires = `; expires=${expireDate.toUTCString()}`;
  const domainString = domain ? `domain=${domain};` : '';
  document.cookie = `${name}=${value || ''}${expires}; ${domainString} path=/`;
}

export function getCookie(name: string) {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

const eraseCookie = (name: string) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

interface LockCookie {
  locksHeld: number,
  expiration: number,
}

export function addProcedureLockCookie(lockUrl: string, lockExpiration: number): number {
  // The cookie expiration matches that of the one from the server or 2 hours from now
  const expires = lockExpiration || new Date().getTime() + (2 * 60 * 60 * 1000);
  const cookieKey = PROCEDURE_LOCK_PREFIX + lockUrl;

  const existingLockString = getCookie(cookieKey) || JSON.stringify({});
  const { locksHeld = 0 }: LockCookie = JSON.parse(existingLockString);
  const newLocksHeld = locksHeld + 1;

  setCookie(cookieKey, JSON.stringify({
    locksHeld: newLocksHeld,
    expiration: expires,
  }), expires);

  return newLocksHeld;
}

export function removeProcedureLockCookie(lockUrl: string): number {
  const cookieKey = PROCEDURE_LOCK_PREFIX + lockUrl;

  const existingLockString = getCookie(cookieKey) || JSON.stringify({});
  const { locksHeld = 0, expiration }: LockCookie = JSON.parse(existingLockString);
  const newLocksHeld = locksHeld - 1;
  if (locksHeld > 0) {
    setCookie(cookieKey, JSON.stringify({
      locksHeld: newLocksHeld,
      expiration,
    }), expiration);
  }

  if (newLocksHeld <= 0) {
    eraseCookie(cookieKey);
  }

  return newLocksHeld;
}
