import { CarryForwardType, ProcedureDisplayType, ProcedureType } from '../enums';

const getProcedureTypeFromDisplayType = (displayType: ProcedureDisplayType, carryForwardType: CarryForwardType) => {
  switch (displayType) {
    case (ProcedureDisplayType.GRID): {
      return ProcedureType.GRID;
    }

    case (ProcedureDisplayType.NORMAL): {
      return ProcedureType.NORMAL;
    }

    case (ProcedureDisplayType.MULTI_RECORD): {
      return carryForwardType === CarryForwardType.PERMANENT ? ProcedureType.PERMANENT_MULTIRECORD : ProcedureType.MULTIRECORD;
    }

    default: {
      // eslint-disable-next-line no-console
      console.error(`Unrecognized display type: ${displayType}`);
      return ProcedureType.NORMAL;
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export { getProcedureTypeFromDisplayType };
