import firebase from 'firebase/compat/app';

const requestHeaders = (idToken: string) => ({
  Authorization: `Bearer ${idToken}`,
  'Content-Type': 'application/json',
});

/**
 * Provides some structure to making POST/GET endpoints, attaching a firebase id token to the header.
 * @param endpoint
 * @param method
 * @param body
 */
interface FetchRequestInterface {
  endpoint: string,
  method: 'POST' | 'PUT' | 'GET' | 'OPTIONS' | 'DELETE',
  body?: any,
  keepalive?: boolean
}
export async function fetchRequest({
  endpoint, method, body, keepalive = false,
}: FetchRequestInterface) {
  const idToken = await firebase
    .auth()
    .currentUser?.getIdToken(false);
  const requestOptions = {
    method,
    headers: requestHeaders(idToken || ''),
    body: JSON.stringify(body),
    keepalive,
  };
  return fetch(endpoint, requestOptions);
}

export default { fetchRequest };
